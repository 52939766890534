import React from 'react';
import PropTypes from 'prop-types';

import ValidationSteps from 'components/common/validation-steps';

import Quote from 'models/quote';

const propTypes = {
  selectedQuote: PropTypes.instanceOf(Quote).isRequired,
  onValidationClick: PropTypes.func.isRequired,
};

const defaultProps = {
};

const HowToPark = ({ selectedQuote, onValidationClick }) => {
  if (!selectedQuote.validation) return null;

  return (
    <div className="row" onClick={onValidationClick}>
      <div className="col-sm-8">
        <div className="padding-top-40 text-size-sm-24 text-size-xs-18 text-color-dark-slate text-weight-bold">
          How to Park
        </div>
        <div className="margin-top-20 text-size-18 text-color-dark-slate text-weight-book">
          <ValidationSteps
            validation={selectedQuote.validation}
            className="text-size-14 text-color-dark-slate text-align-xs-center text-align-sm-left"
          />
        </div>
      </div>
    </div>
  );
};

HowToPark.propTypes = propTypes;
HowToPark.defaultProps = defaultProps;

export default HowToPark;
