import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import ClickOutside from 'react-click-outside';
import { FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';
import cx from 'classnames';
import VisibilitySensor from 'react-visibility-sensor';

import { TIME_FORMAT, TIMEPICKER_START_IMPRESSION, TIMEPICKER_START_CLICK, TIMEPICKER_END_IMPRESSION, TIMEPICKER_END_CLICK } from 'lib/analytics/events';
import { PARKING_UNAVAILABLE_MESSAGE } from 'components/checkout/modal-notices';

// Components
import DatePicker from 'components/common/date-picker';
import TimeCollection from 'components/common/time-date-picker/time-collection';

const propTypes = {
  time: PropTypes.instanceOf(moment).isRequired,
  timezone: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  dateChange: PropTypes.func.isRequired,
  timeChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  labelId: PropTypes.string,
  displayTimes: PropTypes.bool,
  hideOnDateChange: PropTypes.bool,
  trackEvent: PropTypes.func.isRequired,
  variant: PropTypes.string,
  checkoutError: PropTypes.instanceOf(Map),
};

const defaultProps = {
  label: null,
  labelId: null,
  displayTimes: true,
  hideOnDateChange: false,
  variant: 'search',
  checkoutError: Map(),
};

const LIST_ID = 'time-selector-list';

class DateTimeSelector extends Component {
  static scrollList(distance) {
    document.getElementById(LIST_ID).scrollTop += distance;
  }

  constructor(props) {
    super(props);

    this.state = {
      showSelector: false,
      dateChanged: false,
      timeChanged: false,
      pickerViewed: false,
    };

    this.showSelector = this.showSelector.bind(this);
    this.hideSelector = this.hideSelector.bind(this);
    this.toggleSelector = this.toggleSelector.bind(this);
    this.selectedDateChanged = this.selectedDateChanged.bind(this);
    this.selectedTimeChanged = this.selectedTimeChanged.bind(this);
    this.shouldHideSelector = this.shouldHideSelector.bind(this);
    this.renderTimeCollection = this.renderTimeCollection.bind(this);
    this.onSelectorVisibilityChange = this.onSelectorVisibilityChange.bind(this);
    this.onDateTimeClicked = this.onDateTimeClicked.bind(this);
  }

  get clickEvent() {
    if (this.props.field === 'endTime') {
      return TIMEPICKER_END_CLICK;
    }
    return TIMEPICKER_START_CLICK;
  }

  get impressionEvent() {
    if (this.props.field === 'endTime') {
      return {
        ...TIMEPICKER_END_IMPRESSION,
        properties: {
          end_time: this.props.time.format(TIME_FORMAT),
        },
      };
    }
    return {
      ...TIMEPICKER_START_IMPRESSION,
      properties: {
        start_time: this.props.time.format(TIME_FORMAT),
      },
    };
  }

  showSelector() {
    this.setState({
      showSelector: true,
    });
  }

  hideSelector() {
    if (this.props.checkoutError.get('message') === PARKING_UNAVAILABLE_MESSAGE) { return; }

    this.setState({
      showSelector: false,
      dateChanged: false,
      timeChanged: false,
    });
  }

  toggleSelector() {
    this.setState({
      showSelector: !this.state.showSelector,
    });
  }

  selectedDateChanged(value) {
    if (this.props.dateChange instanceof Function) {
      this.props.dateChange(value);
      this.setState({ dateChanged: true }, () => {
        if (this.shouldHideSelector()) {
          this.hideSelector();
        }
      });
    }
  }

  selectedTimeChanged(value) {
    if (!(this.props.timeChange instanceof Function)) { return; }

    this.props.timeChange(value);
    this.setState({ timeChanged: true }, () => {
      if (this.shouldHideSelector()) { this.hideSelector(); }
    });
  }

  shouldHideSelector() {
    const dateTimeChanged = (this.state.dateChanged && this.state.timeChanged);
    const monthlyDateChanged = (this.props.hideOnDateChange && this.state.dateChanged);

    return dateTimeChanged || monthlyDateChanged;
  }

  onSelectorVisibilityChange(isVisible) {
    if (!this.state.pickerViewed) {
      this.setState({ pickerViewed: isVisible });
      if (isVisible) {
        this.props.trackEvent(this.impressionEvent);
      }
    }
  }

  onDateTimeClicked() {
    this.toggleSelector();
    this.props.trackEvent(this.clickEvent);
  }

  renderDateTime() {
    const { time, labelId, variant, displayTimes, field } = this.props;
    let { label } = this.props;
    if (labelId) {
      label = (<FormattedMessage
        id={labelId} 
        defaultMessage={label}
      />);
    }
    const containerClasses = cx({
      'clickable': true,
      'col-xs-6': variant === 'search',
      'col-lg-5': variant === 'search',
      'col-xs-12': variant === 'checkout',
      'col-md-5': variant === 'checkout',
      'padding-top-10': variant === 'search',
      'col-gutter-0': variant === 'search',
      'active': this.state.showSelector,
      'text-color-dark-slate': variant === 'checkout',
    });

    const labelClasses = cx({
      'text-size-12': true,
      'text-weight-light': variant === 'search',
      'text-weight-book': variant === 'checkout',
      'margin-top-5': true,
      'hidden-xs': true,
    });

    const valueClasses = cx({
      'display-inline-block': true,
      'margin-top-5': true,
      'text-size-14': true,
      'text-text-weight-bold': true,
      'uppercase': true,
      'active-underlined': this.state.showSelector,
      'inactive-underlined': !this.state.showSelector,
    });

    const timeValueClasses = cx({
      'hidden': !displayTimes,
      'display-inline-block': displayTimes,
      'text-size-28': variant === 'checkout',
      'text-weight-medium': variant === 'checkout',
    });

    const dateValueClasses = cx({
      'text-size-md-16': variant === 'checkout',
      'text-size-xs-14': variant === 'checkout',
      'text-weight-book': variant === 'checkout',
      'display-md-block': variant === 'checkout',
      'display-xs-inline-block': variant === 'checkout',
      'margin-right-xs-15': variant === 'checkout',
      'margin-right-md-0': variant === 'checkout',
      'display-inline-block': variant === 'search',
      'margin-top-xs-10': true,
      'margin-top-md-0': true,
      'vertical-align-top': true,
    });

    const dividerClasses = cx({
      'text-weight-light': true,
      'text-color-light-grey': true,
      'margin-horizontal-10': true,
      'hidden': variant === 'checkout',
    });

    const editClasses = cx({
      'iconified-font': true,
      'iconified-pencil': true,
      'margin-left-15': true,
      'text-color-blue': variant === 'checkout',
      'text-size-16': variant === 'checkout',
      'vertical-align-middle': variant === 'checkout',
    });

    const arrowClasses = cx({
      'col-xs-2': variant === 'checkout',
      'iconified-font': true,
      'iconified-thin-arrow-right': field === 'startTime',
      'iconified-thin-arrow-left': field === 'endTime',
      'text-size-18': true,
      'text-color-blue': true,
      'hidden': variant === 'search',
      'margin-top-xs-10': true,
      'margin-top-md-0': true,
    });

    const datetimeClasses = cx({
      'col-xs-10': variant === 'checkout',
      'col-md-8': variant === 'checkout',
      'border-side-right': variant === 'checkout' && field === 'startTime',
      'border-color-light-grey': variant === 'checkout' && field === 'startTime',
      'border-style-solid': variant === 'checkout' && field === 'startTime',
      'col-gutter-left-0': variant === 'checkout',
      'col-xs-12': variant === 'search',
    });

    const rowClasses = cx({
      'row': true,
      'background-color-xs-light-blue': true,
      'background-color-md-transparent': true,
      'padding-vertical-xs-10': variant === 'checkout',
      'padding-vertical-md-0': variant === 'checkout',
      'border-side-bottom': field === 'startTime',
      'border-grey': field === 'startTime',
      'border-style-solid': field === 'startTime',
      'border-style-md-none': field === 'startTime',
    });

    return (
      <div className={containerClasses} onClick={this.onDateTimeClicked}>
        <div className={rowClasses}>
          <div className={arrowClasses} />
          <div className={datetimeClasses}>
            <div className={labelClasses}>
              { label }
            </div>
            <div className={valueClasses}>
              <div className={dateValueClasses}>
                { time.format('ddd, MMM D') }
              </div>
              <div className={timeValueClasses}>
                <span className={dividerClasses}>|</span>
                { time.format('h:mm a') }
                <span className={editClasses} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderTimeCollection() {
    if (!this.props.displayTimes) { return null; }

    return (
      <div className="col-xs-4 text-align-center">
        <span className="iconified-font iconified-chevron-up text-color-blue text-size-10 clickable text-color-hover-blue" onClick={() => { DateTimeSelector.scrollList(-75); }} />
        <div className="horizontal-time-dropdown text-weight-light list-unstyled margin-top-10 margin-bottom-10">
          <TimeCollection
            value={this.props.time}
            timezone={this.props.timezone}
            onChange={this.selectedTimeChanged}
            lineItemClassName="clickable text-color-hover-blue"
            listId={LIST_ID}
          />
        </div>
        <span className="iconified-font iconified-chevron-down text-color-blue text-size-10 clickable text-color-hover-blue" onClick={() => { DateTimeSelector.scrollList(75); }} />
      </div>
    );
  }

  renderDateTimeSelector() {
    if (!this.state.showSelector) { return false; }

    const containerClasses = cx({
      'date-time-selector': true,
      'container': true,
      'row': true,
      'position-absolute': true,
      'text-color-dark-slate': true,
      'heavy-shadowed': true,
      'times-displayed': this.props.displayTimes,
      'date-only': !this.props.displayTimes,
    });

    return (
      <div className={containerClasses}>
        <div className="row background-color-white padding-top-20 padding-bottom-20 border-style-solid border-color-light-grey border-side-right border-side-left">
          <div className="col-xs-8 col-gutter-right-0">
            <div className="border-style-solid border-side-right border-color-light-grey">
              <DatePicker
                date={this.props.time.clone().startOf('day')}
                minDate={moment().startOf('day')}
                onDateChange={this.selectedDateChanged}
                locale={this.props.locale}
              />
            </div>
          </div>
          { this.renderTimeCollection() }
        </div>
        <div className="row">
          <div className="text-size-16 text-color-blue background-color-light-blue text-align-center padding-10 col-xs-12 clickable noselect" onClick={this.hideSelector}>
            <span className="iconified-font iconified-x margin-right-5 iconified-space-right" />
            <FormattedMessage
              id="checkout.close"
              defaultMessage="CLOSE"
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const classes = cx({
      'checkout-time-selector': this.props.variant === 'checkout',
    });

    return (
      <div className={classes}>
        <VisibilitySensor onChange={this.onSelectorVisibilityChange} partialVisibility>
          <ClickOutside onClickOutside={this.hideSelector}>
            { this.renderDateTime() }
            { this.renderDateTimeSelector() }
          </ClickOutside>
        </VisibilitySensor>
      </div>
    );
  }
}

DateTimeSelector.propTypes = propTypes;
DateTimeSelector.defaultProps = defaultProps;

export default DateTimeSelector;
