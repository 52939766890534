import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { Link } from 'react-router-dom';
import { parse } from 'query-string';
import { sanitizeQuery } from 'lib/common/url-helpers';

import LocationUnavailable from 'components/svgs/images/location-unavailable';

import Search, { HUB_TYPE } from 'models/search';

import { routerLocationShape } from 'lib/common/prop-types';

const propTypes = {
  nearbyMetros: PropTypes.instanceOf(List).isRequired,
  routerLocation: routerLocationShape.isRequired,
  currentSearch: PropTypes.instanceOf(Search).isRequired,
};

const NothingFound = ({ nearbyMetros, routerLocation, currentSearch }) => {
  if (nearbyMetros && nearbyMetros.size) {
    const query = parse(routerLocation.search);
    const { sanitizedQuery } = sanitizeQuery(query);
    const metroLinks = nearbyMetros.map(h => (
      <div key={`nm-${h.id}`} className="text-size-16 uppercase">
        <Link
          to={{
            pathname: h.url,
            search: sanitizedQuery,
            state: {
              app: { name: 'Search' },
              search: {
                currentSearch: currentSearch.merge({
                  destination: h,
                  destinationType: HUB_TYPE,
                  lat: h.lat,
                  lng: h.lng,
                  anchorLat: h.lat,
                  anchorLng: h.lng,
                }).toJSON(),
                hub: JSON.stringify(h.toJSON()),
              },
            },
          }}
        >
          {h.name}
        </Link>
      </div>
    ));

    return (
      <div className="location-list unavailable row">
        <div className="col-xs-10 col-xs-offset-1">
          <h2 className="h2-sg text-color-dark-slate text-weight-medium">SO CLOSE!</h2>
          <p className="text-size-20 text-color-medium-grey text-weight-light">
            We&#39;re not showing parking right there, but here&#39;s parking in nearby cities.
          </p>
          {metroLinks}
        </div>
      </div>
    );
  }

  return (
    <div className="location-list unavailable">
      <div className="unavailable-icon">
        <span className="isvg loaded">
          <LocationUnavailable />
        </span>
        <p>Where did all the parking go?</p>
      </div>
      <div className="instructions">
        <p>Aw Shucks, it seems there are no parking spaces nearby.</p>
        <p>We have over 800,000 spots across 50 U.S. cities.</p>
        <p><strong>Try a different search.</strong></p>
      </div>
    </div>
  );
};

NothingFound.propTypes = propTypes;

export default NothingFound;
