import React from 'react';
import PropTypes from 'prop-types';

import env from 'lib/env';

/* @typedef Coordinates
 * @property {number} lat
 *                    Latitude.
 * @property {number} lon
 *                    Longitude.
 */
const coordinatesPropType = PropTypes.shape({
  lat: PropTypes.number,
  lng: PropTypes.number,
});

const propTypes = {
  className: PropTypes.string,
  destination: coordinatesPropType,
  location: coordinatesPropType,
  mapSize: PropTypes.string,
};

const defaultProps = {
  className: null,
  destination: null,
  location: null,
  mapSize: '740x460',
};

export const LOCATION_PIN = encodeURIComponent('https://dbmgns9xjyk0b.cloudfront.net/map-icons/location-pin.png');
export const DESTINATION_PIN = encodeURIComponent('https://dbmgns9xjyk0b.cloudfront.net/map-icons/destination-pin.png');

const { MAPBOX_TOKEN, MAPBOX_STYLE } = env();

/**
 * @param {Coordinates} [location]
 *                      Location.
 * @param {Coordinates} [destination]
 *                      Destination.
 * @param {string} mapSize
 *                 String specifying map size. Example: '740x460'.
 *
 * @returns {String}
 *          URL to retrieve static image of a Mapbox Map.
 */
export function generateStaticMapUrl(location, destination, mapSize) {
  let markers = [];
  if (destination) {
    const destinationParam = `url-${DESTINATION_PIN}(${destination.lng},${destination.lat})`;
    markers = markers.concat(destinationParam);
  }

  if (location) {
    const locationParam = `url-${LOCATION_PIN}(${location.lng},${location.lat})`;
    markers = markers.concat(locationParam);
  }

  return `https://api.mapbox.com/styles/v1/${MAPBOX_STYLE}/static/${markers.join(',')}/auto/${mapSize}?access_token=${MAPBOX_TOKEN}`;
}

const MiniMap = ({ className, destination, location, mapSize }) => {
  const url = generateStaticMapUrl(location, destination, mapSize);

  return (
    <div className={className}>
      <img src={url} alt="Map" />
    </div>
  );
};

MiniMap.propTypes = propTypes;
MiniMap.defaultProps = defaultProps;
export default MiniMap;
