import { PAGEVIEW_EVENT } from 'lib/analytics/events';
import { urlProps } from 'lib/analytics/page-properties';

export default function trackLocationListPageView(props) {
  const {
    currentSearch,
    locations,
    trackEvent,
    venue,
  } = props;

  if (!currentSearch.selectedLocationId) {
    trackEvent({
      ...PAGEVIEW_EVENT,
      properties: {
        ...urlProps(),
        VenueID: venue && venue.id,
        MapListView: 'ListView',
        NumResults: locations.size,
        SearchTerm: currentSearch.searchTerm,
      },
    });
  }
};