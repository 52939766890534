import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import AirportLocatonInstructionsRow from 'components/search/airport-location/instructions/row';

import Quote from 'models/quote';

const propTypes = {
  quote: PropTypes.instanceOf(Quote).isRequired,
};

const AirportLocationInstructions = ({ quote }) => {
  return (
    <Fragment>
      <AirportLocatonInstructionsRow
        instructions={quote.dropoffInstructions}
        title={'Getting to the Terminal'}
      />
      <AirportLocatonInstructionsRow
        instructions={quote.pickupInstructions}
        title={'Returning to Your Car'}
      />
    </Fragment>
  );
};

AirportLocationInstructions.propTypes = propTypes;

export default AirportLocationInstructions;
