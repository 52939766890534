import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { List, OrderedMap, Set } from 'immutable';
import cx from 'classnames';
import VisibilitySensor from 'react-visibility-sensor';

import { Location as LocationModel } from 'models/locations';
import Venue from 'models/venue';
import Quote from 'models/quote';
import Brand from 'models/brand';

import { routerLocationShape } from 'lib/common/prop-types';

// Components
import NothingFound from 'components/search/list/nothing-found';
import DestinationHeader from 'components/search/list/destination-header';
import ParkingFilters from 'components/search/list/parking-filters';
import FeesNotice from 'components/search/list/fees-notice';
import AirportListing from 'components/search/list/airport-listing';
import VenueInfo from 'components/search/list/venue-info';
import ParkingTypeButton from 'containers/search/list/parking-type-button';
import PhoneNumberNotice from 'components/search/list/phone-number-notice';

import get from 'lodash/get';

import {
  SEARCH_FILTER_DROPDOWN_IMPRESSION,
  SEARCH_FILTER_DROPDOWN_CLICK,
  SEARCH_MAP_TOGGLE_IMPRESSION,
  SEARCH_MAP_TOGGLE_CLICK,
} from 'lib/analytics/events';
import trackLocationListPageView from 'lib/analytics/events/track-location-list-pageview';

const propTypes = {
  currentSearch: PropTypes.shape({}),
  previousSearch: PropTypes.shape({}),
  selectedLocation: PropTypes.instanceOf(LocationModel),
  toggleMap: PropTypes.func.isRequired,
  nearbyMetros: PropTypes.instanceOf(List).isRequired,
  venue: PropTypes.instanceOf(Venue),
  locations: PropTypes.instanceOf(OrderedMap).isRequired,
  highlightLocation: PropTypes.func.isRequired,
  scrollToTop: PropTypes.func.isRequired,
  changeLocation: PropTypes.func.isRequired,
  location: PropTypes.instanceOf(LocationModel),
  quote: PropTypes.instanceOf(Quote),
  brand: PropTypes.instanceOf(Brand).isRequired,
  trackEvent: PropTypes.func.isRequired,
  appContext: PropTypes.string.isRequired,
  displayMap: PropTypes.bool.isRequired,
  destination: PropTypes.instanceOf(Object).isRequired,
  onFilter: PropTypes.func.isRequired,
  currentFilters: PropTypes.instanceOf(Set).isRequired,
  routerLocation: routerLocationShape.isRequired,
  scrolledLocation: PropTypes.instanceOf(LocationModel),
  unsetScrolledLocation: PropTypes.func.isRequired,
  monthlyAvailable: PropTypes.bool,
  changeParkingType: PropTypes.func,
};

const defaultProps = {
  currentSearch: {},
  previousSearch: {},
  hub: {},
  venue: null,
  selectedLocation: null,
  selectedQuote: null,
  location: null,
  quote: null,
  scrolledLocation: null,
  monthlyAvailable: false,
  changeParkingType: () => {},
};

export class AirportList extends Component {
  constructor(props) {
    super(props);

    this.toggleInfo = this.toggleInfo.bind(this);
    this.showAll = this.showAll.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
    this.changeLocation = this.changeLocation.bind(this);
    this.toggleMap = this.toggleMap.bind(this);
    this.toggleFilters = this.toggleFilters.bind(this);
    this.onFilterVisibilityChange = this.onFilterVisibilityChange.bind(this);
    this.onMapToggleVisibilityChange = this.onMapToggleVisibilityChange.bind(this);
    this.state = {
      showInfo: false,
      showAll: false,
      showMobileFilters: false,
    };
  }

  get isEnhancedAirport() {
    return get(this.props, 'venue.enhancedAirport', false);
  }

  componentDidMount() {
    trackLocationListPageView(this.props);
  }

  componentDidUpdate(prevProps) {
    const { currentSearch } = this.props;
    const { currentSearch: prevSearch } = prevProps;

    if (currentSearch.destination.id !== prevSearch.destination.id) {
      trackLocationListPageView(this.props);
    }
  }

  componentWillMount() {
    this.setState({
      showInfo: false,
      showAll: false,
    });
  }

  scrollToTop() {
    if (this.list) {
      this.list.scrollTop = 0;
    }
    this.props.scrollToTop();
  }

  toggleInfo(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ showInfo: !this.state.showInfo });
  }

  toggleMap(event) {
    event.preventDefault();
    event.stopPropagation();

    const { toggleMap, trackEvent } = this.props;
    toggleMap();
    trackEvent({
      ...SEARCH_MAP_TOGGLE_CLICK,
      position: '0',
    });
  }

  renderInfo(infoEl) {
    const { venue } = this.props;
    const info = infoEl.innerHTML;
    return (
      <section className="list airport-list" ref={(list) => { this.list = list; }}>
        <div className="bg-warning pam">
          <a
            href="#search-list-related-info"
            title="See related information"
            id="search-list-info-link"
            onClick={this.toggleInfo}
            className="pull-right"
            data-category="Search"
            data-action="relatedInfoButton"
          >
            <span className="valet-glyph-info" />
          </a>
          <h3 className="man text-white">
            Parking Near {venue.name}
          </h3>
        </div>
        <div dangerouslySetInnerHTML={{ __html: info }} />
      </section>
    );
  }

  // Only render location details if a specific quote has been selected
  shouldRenderFacilityDetails() {
    const { currentSearch, selectedLocation } = this.props;
    let unavailable = false;
    if (selectedLocation) {
      unavailable = selectedLocation.isUnavailable;
    }
    return (Boolean(currentSearch.selectedQuoteId || unavailable));
  }

  showAll(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ showAll: true });
  }


  mouseenter() {
    const { location } = this.props;
    this.setState({ hover: true });
    this.props.highlightLocation({ locationId: location.id });
  }

  mouseleave() {
    this.setState({ hover: false });
    this.props.highlightLocation({ locationId: null });
  }

  touchstart(event) {
    event.stopPropagation();
    if (event.touches.length === 1) {
      this.startedTouch = event;
    }
  }

  booktouchstart(event) {
    event.stopPropagation();
    if (event.touches.length === 1) {
      this.startedBookTouch = event;
    }
  }

  touchmove(event) {
    event.stopPropagation();
    this.startedTouch = null;
    this.startedBookTouch = null;
  }

  touchend(event) {
    if (this.startedTouch || this.startedBookTouch) {
      this.click(event);
    }

    this.startedTouch = null;
    this.startedBookTouch = null;
  }

  touchcancel(event) {
    this.startedTouch = null;
    this.startedBookTouch = null;
    event.preventDefault();
    event.stopPropagation();
  }

  changeLocation({ locationId, disableSetRoute, quoteId }) {
    this.props.changeLocation({ locationId, disableSetRoute, quoteId });
  }

  click(event) {
    event.preventDefault();
    event.stopPropagation();

    this.scrollToTop();
    this.props.changeLocation({
      locationId: this.props.location.id,
      quoteId: this.props.quote.id,
      venue: this.props.destination,
    });
  }

  toggleFilters() {
    const { trackEvent } = this.props;
    this.setState({ showMobileFilters: !this.state.showMobileFilters });
    trackEvent({
      ...SEARCH_FILTER_DROPDOWN_CLICK,
      position: '0',
    });
  }

  onFilterVisibilityChange(isVisible) {
    const { trackEvent, appContext, displayMap } = this.props;

    if (isVisible && appContext !== 'desktop' && !displayMap) {
      trackEvent({
        ...SEARCH_FILTER_DROPDOWN_IMPRESSION,
        position: '0',
      });
    }
  }

  onMapToggleVisibilityChange(isVisible) {
    const { trackEvent, appContext, displayMap } = this.props;

    if (isVisible && appContext !== 'desktop' && !displayMap) {
      trackEvent({
        ...SEARCH_MAP_TOGGLE_IMPRESSION,
        position: '0',
      });
    }
  }

  loading() {
    /**
     * Show the loading symbol if either:
     * 1) The search is dirty
     * 2) Someone is requesting a location but that location isn't available currently
     */
    const { currentSearch, previousSearch, selectedLocation } = this.props;

    return !!(
      currentSearch.isDirty(previousSearch) ||
      (currentSearch.selectedLocationId && !selectedLocation) ||
      currentSearch.pendingGeolocationPermission
    );
  }

  viewInMapRow() {
    const { currentSearch } = this.props;
    return currentSearch.isMonthlySearch ? this.renderOtherViewInMapRow() : this.renderAirportViewInMapRow();
  }

  renderFilters() {
    const { monthlyAvailable } = this.props;
    const { isEnhancedAirport } = this;

    if (!isEnhancedAirport && monthlyAvailable) {
      return null;
    }

    const filterClasses = cx({
      'iconified-font': true,
      'iconified-space-left': true,
      'iconified-chevron-up': !this.state.showMobileFilters,
      'iconified-chevron-down': !!this.state.showMobileFilters,
    });

    return (
      <div className="col-xs-7 text-right">
        <a className="larger-hit-area visible-xs" onClick={this.toggleFilters}>
          <VisibilitySensor onChange={this.onFilterVisibilityChange} partialVisibility>
            <span className="text-size-10 text-weight-light btn-style-minimal-outline btn-color-link-blue">
              FILTERS <span className={filterClasses} />
            </span>
          </VisibilitySensor>
        </a>
      </div>
    );
  }

  renderAirportViewInMapRow() {
    return (
      <div className="row map-and-monthly-row visible-lte-sm padding-bottom-10">
        <div className="col-xs-5">
          <a className="larger-hit-area" onClick={this.toggleMap}>
            <VisibilitySensor onChange={this.onMapToggleVisibilityChange} partialVisibility>
              <span className="text-size-10 text-weight-light btn-style-minimal-outline btn-color-link-blue">
                VIEW IN MAP
              </span>
            </VisibilitySensor>
          </a>
        </div>
        { this.renderFilters() }
      </div>
    );
  }

  renderOtherViewInMapRow() {
    const { currentSearch } = this.props;

    const filterClasses = cx({
      'iconified-font': true,
      'iconified-space-left': true,
      'iconified-chevron-up': !this.state.showMobileFilters,
      'iconified-chevron-down': !!this.state.showMobileFilters,
    });

    return (
      <div className="padding-bottom-10">
        <div className="row map-and-monthly-row visible-lte-sm">
          <div className="col-xs-5">
            <a className="larger-hit-area" onClick={this.toggleMap}>
              <VisibilitySensor onChange={this.onMapToggleVisibilityChange} partialVisibility>
                <span className="text-size-10 text-weight-light btn-style-minimal-outline btn-color-link-blue">
                  VIEW IN MAP
                </span>
              </VisibilitySensor>
            </a>
          </div>
          <div className="col-xs-7 text-left padding-right-10">
            <VisibilitySensor onChange={this.onFilterVisibilityChange} partialVisibility>
              <ParkingTypeButton mobile />
            </VisibilitySensor>
          </div>
        </div>
        {!currentSearch.isMonthlySearch &&
          <div className="row map-and-monthly-row visible-lte-sm">
            <div className="col-xs-7 text-left">
              <a className="larger-hit-area visible-xs" onClick={this.toggleFilters}>
                <VisibilitySensor onChange={this.onFilterVisibilityChange} partialVisibility>
                  <span className="text-size-10 text-weight-light btn-style-minimal-outline btn-color-link-blue">
                    FILTERS <span className={filterClasses} />
                  </span>
                </VisibilitySensor>
              </a>
            </div>
          </div>
        }
      </div>
    );
  }

  get listings() {
    const nodes = [];
    const { currentSearch, selectedLocation, trackEvent, appContext, displayMap, brand, venue } = this.props;
    const locationLimit = currentSearch.mobileOnly ? 20 : 50;
    let { locations: locationsToRender } = this.props;

    if (selectedLocation && selectedLocation.hasMultipleQuotes) {
      locationsToRender = locationsToRender.filter(l => selectedLocation.id === l.id);
    }

    if (!this.state.showAll && locationsToRender.size > locationLimit) {
      locationsToRender = locationsToRender.slice(0, locationLimit);
    }

    let position = 0;
    locationsToRender.forEach((location) => {
      const key = `l${location.id}`;
      const quote = location.quotes ? location.quotes.get(0) : null;
      nodes.push(
        <AirportListing
          key={key}
          currentSearch={currentSearch}
          location={location}
          quote={quote}
          brand={brand}
          mouseenter={this.mouseenter}
          mouseleave={this.mouseleave}
          touchstart={this.touchstart}
          booktouchstart={this.booktouchstart}
          touchmove={this.touchmove}
          touchend={this.touchend}
          click={this.click}
          changeLocation={this.changeLocation}
          touchcancel={this.touchcancel}
          scrollToTop={this.scrollToTop}
          highlightLocation={this.props.highlightLocation}
          position={`${position}`}
          trackEvent={trackEvent}
          appContext={appContext}
          displayMap={displayMap}
          list={this.list}
          scrolledLocation={this.props.scrolledLocation}
          unsetScrolledLocation={this.props.unsetScrolledLocation}
          routerLocation={this.props.routerLocation}
          venue={venue}
          monthlyAvailable={this.props.monthlyAvailable}
        />);
      position++;
    });

    if (!this.state.showAll && locationsToRender.size > locationLimit) {
      nodes.push(
        <article className="location show-link" key="show-all">
          <div><a onClick={this.showAll}>{`Show ${locationsToRender.size - locationLimit} more locations`}</a></div>
        </article>);
    }

    return nodes;
  }

  render() {
    const {
      appContext,
      brand,
      currentSearch,
      displayMap,
      monthlyAvailable,
      venue,
      trackEvent,
    } = this.props;

    const { isEnhancedAirport } = this;

    const nodes = [];

    // show parking for the search criteria
    const { listings } = this;
    if (listings) {
      nodes.push(...listings);
      if (get(venue, 'id', 0) !== 0) {
        nodes.push(<VenueInfo
          venue={venue}
          trackEvent={trackEvent}
          currentSearch={currentSearch}
          appContext={appContext}
          displayMap={displayMap}
          key="venue-info"
        />);
      }
    }

    if (nodes.length === 0) {
      nodes.push(
        <NothingFound
          nearbyMetros={this.props.nearbyMetros}
          routerLocation={this.props.routerLocation}
          currentSearch={this.props.currentSearch}
          key="nothing-found"
        />);
    }

    const loading = this.loading();
    let listWrapperClass = 'list-wrapper';
    if (loading) { listWrapperClass += ' loading'; }

    const contactPhone = currentSearch.brandedMonthlyPhoneNumber(brand);
    return (
      <div className={listWrapperClass}>
        <section className="list airport-list" ref={(list) => { this.list = list; }}>
          { !this.shouldRenderFacilityDetails() ? this.viewInMapRow() : null }
          <div className={loading ? 'loading' : ''}>
            <DestinationHeader
              currentSearch={currentSearch}
              venue={this.props.venue}
              monthlyAvailable={this.props.monthlyAvailable}
              changeParkingType={this.props.changeParkingType}
              airport
              selectedLocation={this.props.selectedLocation}
            />
            {(!monthlyAvailable || isEnhancedAirport) &&
              <Fragment>
                <ParkingFilters
                  locations={this.props.locations}
                  currentFilters={this.props.currentFilters}
                  onFilter={this.props.onFilter}
                  showMobileFilters={this.state.showMobileFilters}
                  currentSearch={currentSearch}
                  trackEvent={trackEvent}
                  appContext={this.props.appContext}
                />
                <FeesNotice />
              </Fragment>
            }
            {(monthlyAvailable && !isEnhancedAirport) &&
              <PhoneNumberNotice contactPhone={contactPhone} />
            }
            {nodes}
          </div>
        </section>
      </div>
    );
  }
}

AirportList.propTypes = propTypes;
AirportList.defaultProps = defaultProps;
export default AirportList;
