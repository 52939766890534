import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cx from 'classnames';
import { OrderedMap, Set, Map as ImmutableMap } from 'immutable';
import moment from 'moment-timezone';
import get from 'lodash/get';

import SearchModel from 'models/search';
import TrackingPropertiesModel from 'models/tracking-properties';
import { Location as LocationModel } from 'models/locations';
import Coordinates from 'models/coordinates';
import Venue from 'models/venue';
import Event, { Events } from 'models/event';
import Brand from 'models/brand';

import { BOUNDS_SEARCH } from 'lib/analytics/events';
import { routerLocationShape } from 'lib/common/prop-types';

import { FILTERS } from 'components/search/list/parking-filters';
import TimePickerBar from 'components/search/time-date-picker/time-picker-bar';
import AirportLocation from 'components/search/airport-location';
import SelectTimeToolTip from 'components/common/select-time-tooltip';
import DestinationHeader from 'components/search/list/destination-header';

import LocationDetail from 'containers/search/list/location-detail';
import LocationNav from 'containers/search/list/location-nav';
import ParkingList from 'containers/search/list/index';
import SearchMap from 'containers/search/map';
import Messages from 'containers/messages/index';

import highlightLocation from 'action-creators/search/highlight-location';
import changeSelectedLocation from 'action-creators/search/change-selected-location';
import changeSearchDestinationCreator from 'action-creators/search/change-search-destination';
import getSelectedLocation from 'action-creators/search/get-selected-location';
import boundsChange from 'action-creators/search/bounds-change';
import changeTimes from 'action-creators/search/change-times';
import toggleMap from 'action-creators/search/toggle-map';
import setMapRefresh from 'action-creators/search/set-map-refresh';
import parkingNearMeSearch from 'action-creators/search/parking-near-me-search';
import dismissTimePickerPrompt from 'action-creators/search/dismiss-time-picker-prompt';
import dismissCurationCreator from 'action-creators/search/dismiss-curation';
import showCurationCreator from 'action-creators/search/show-curation';
import changeMonthlyStart from 'action-creators/checkout/change-monthly-start';
import setCurrentSearchAndChangeBounds from 'action-creators/search/set-current-search-and-change-bounds';
import selectQuote from 'action-creators/search/select-quote';
import trackEventCreator from 'action-creators/analytics/track-event';
import initializeSearch from 'action-creators/search/initialize-search';
import cleanupSearchState from 'action-creators/search/cleanup-search-state';
import setModalState from 'action-creators/modal/set-modal-state';
import scrollToLocation from 'action-creators/search/scroll-to-location';
import getLocationSellerCreator from 'action-creators/search/get-location-seller';
import changeParkingType from 'action-creators/search/change-parking-type';
import changeSort from 'action-creators/search/change-sort';
import modifyRequiredFieldList from 'action-creators/search/modify-required-field-list';

import { usesLargeFormatLocationDetails } from 'lib/common/search-helpers';

const propTypes = {
  currentSearch: PropTypes.instanceOf(SearchModel).isRequired,
  locations: PropTypes.instanceOf(OrderedMap).isRequired,
  app: PropTypes.string.isRequired,
  brand: PropTypes.instanceOf(Brand).isRequired,
  appContext: PropTypes.string.isRequired,
  previousSearch: PropTypes.instanceOf(SearchModel).isRequired,
  displayMap: PropTypes.bool.isRequired,
  geoIPLocation: PropTypes.instanceOf(Coordinates).isRequired,
  timezone: PropTypes.string.isRequired,
  monthlyStart: PropTypes.instanceOf(moment).isRequired,
  curationDismissed: PropTypes.bool.isRequired,
  timePickerPromptDismissed: PropTypes.bool,
  venue: PropTypes.instanceOf(Venue),
  event: PropTypes.instanceOf(Event),
  events: PropTypes.instanceOf(OrderedMap),
  selectedLocation: PropTypes.instanceOf(LocationModel),
  sellers: PropTypes.instanceOf(ImmutableMap),
  routerLocation: routerLocationShape.isRequired,
  searchInitialized: PropTypes.bool.isRequired,
  monthlyAvailable: PropTypes.bool.isRequired,

  boundsChange: PropTypes.func.isRequired,
  setMapRefresh: PropTypes.func.isRequired,
  initializeSearch: PropTypes.func.isRequired,
  changeSort: PropTypes.func.isRequired,
  changeParkingType: PropTypes.func.isRequired,
  cleanupSearchState: PropTypes.func.isRequired,
  highlightLocation: PropTypes.func.isRequired,
  toggleMap: PropTypes.func.isRequired,
  changeSelectedLocation: PropTypes.func.isRequired,
  changeTimes: PropTypes.func.isRequired,
  changeMonthlyStart: PropTypes.func.isRequired,
  changeSearchDestination: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired,
  dismissTimePickerPrompt: PropTypes.func.isRequired,
  selectQuote: PropTypes.func.isRequired,
  showCuration: PropTypes.func.isRequired,
  getLocationSeller: PropTypes.func.isRequired,
};

const defaultProps = {
  highlightedLocation: null,
  forceAppRefresh: false,
  events: Events(),
  timePickerPromptDismissed: false,
  venue: null,
  event: null,
  selectedLocation: null,
  selectedQuote: null,
  sellers: new ImmutableMap(),
  trackingProperties: new TrackingPropertiesModel(),
  couponCode: null,
  affiliateId: null,
  portalAffiliateId: null,
  requiredFields: Set(),
  requiredFieldErrors: ImmutableMap(),
};

class Search extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const { currentSearch, previousSearch, selectedLocation } = nextProps;
    const { selectedLocationId } = currentSearch;
    if (!selectedLocation && selectedLocationId && !currentSearch.isDirty(previousSearch)) {
      if (!prevState.requestingLocation) {
        nextProps.getSelectedLocation();
        return {
          requestingLocation: true,
          ...prevState,
        };
      }
    } else if (prevState.requestingLocation) {
      return {
        requestingLocation: false,
        ...prevState,
      };
    }

    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      requestingLocation: false,
      optOut: false,
      currentFilters: Set(),
    };

    this.highlightLocation = this.props.highlightLocation.bind(this);
    this.boundsChange = this.boundsChange.bind(this);
    this.changeLocation = this.changeLocation.bind(this);
    this.optOut = this.optOut.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
    this.setMapRefresh = this.props.setMapRefresh.bind(this);
    this.updateMonthlyStart = this.updateMonthlyStart.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onFilter = this.onFilter.bind(this);

    if (!this.props.searchInitialized) {
      this.props.initializeSearch();
    }
  }

  componentWillUnmount() {
    if (this.props.app !== 'Search') {
      this.props.cleanupSearchState();
    }
  }

  get selectedLocation() {
    const { locations, currentSearch } = this.props;
    const { selectedLocationId } = currentSearch;
    return selectedLocationId ? locations.get(selectedLocationId.toString()) : null;
  }

  get selectedQuote() {
    const { currentSearch, locations } = this.props;
    return currentSearch.getSelectedQuote(locations);
  }

  changeLocation(input) {
    this.props.changeSelectedLocation(input);
  }

  updateMonthlyStart(monthlyStart) {
    this.props.changeMonthlyStart({ monthlyStart });
  }

  boundsChange(searchParams) {
    const { trackEvent, currentSearch } = this.props;
    const { searchTerm, startTime, endTime } = currentSearch;

    this.props.boundsChange(searchParams);
    trackEvent({
      properties: {
        searchTerm,
        parkingType: currentSearch.insightsParkingType,
        autocomplete: false,
        inTime: startTime.format(),
        outTime: endTime.format(),
      },
      ...BOUNDS_SEARCH,
    });
  }

  scrollToTop() {
    window.scrollTo(0, 0);
    if (this.wrapper) {
      this.wrapper.scrollTop = 0;
    }
  }

  // Opt out of the monthly form
  optOut() {
    this.setState({ optOut: true });
  }

  onSearch({ place, event }) {
    const { changeSearchDestination } = this.props;
    changeSearchDestination({ place, event });
  }

  onFilter(filter) {
    let { currentFilters } = this.state;
    if (currentFilters.get(filter)) {
      currentFilters = currentFilters.delete(filter);
    } else {
      currentFilters = currentFilters.add(filter);
    }

    this.setState({ currentFilters });
  }

  renderLocationListOrDetails(locations, showCuration) {
    const { selectedLocation, selectedQuote } = this;

    const {
      appContext,
      brand,
      currentSearch,
      venue,
    } = this.props;
    const { destination } = currentSearch;
    const airport = get(destination, 'enhancedAirport', false);

    if (!selectedLocation) {
      return (
        <ParkingList
          airport={airport}
          currentFilters={this.state.currentFilters}
          locations={locations}
          scrollToTop={this.scrollToTop}
          onFilter={this.onFilter}
          canShowCuration={showCuration}
          showCuration={this.props.showCuration}
          routerLocation={this.props.routerLocation}
        />
      );
    }

    if (usesLargeFormatLocationDetails(this.props)) {
      return (
        <AirportLocation
          airport={airport}
          appContext={appContext}
          brand={brand}
          changeLocation={this.changeLocation}
          changeSelectedLocation={this.props.changeSelectedLocation}
          changeSort={this.props.changeSort}
          currentSearch={this.props.currentSearch}
          displayMap={this.props.displayMap}
          getLocationSeller={this.props.getLocationSeller}
          locations={locations}
          previousSearch={this.props.previousSearch}
          selectedLocation={selectedLocation}
          selectQuote={this.props.selectQuote}
          selectedQuote={selectedQuote}
          sellers={this.props.sellers}
          toggleMap={this.props.toggleMap}
          trackEvent={this.props.trackEvent}
          venue={venue}
        />
      );
    }

    return (
      <div className="list-wrapper">
        <section className="list">
          <DestinationHeader
            currentSearch={this.props.currentSearch}
            event={this.props.event}
            venue={this.props.venue}
            events={this.props.events}
            monthlyAvailable={this.props.monthlyAvailable}
            changeParkingType={this.props.changeParkingType}
            selectedLocation={selectedLocation}
          />
          <div className="sub-nav-border sub-nav noselect no-underline">
            <LocationNav />
          </div>
          <LocationDetail
            changeLocation={this.changeLocation}
            displayMap={this.props.displayMap}
            location={selectedLocation}
            renderNeedToKnows
            quote={selectedQuote}
            scrollToTop={this.scrollToTop}
            toggleMap={this.toggleMap}
          />
        </section>
      </div>
    );
  }

  render() {
    const {
      currentSearch,
      displayMap,
      curationDismissed,
    } = this.props;
    let { locations } = this.props;
    let { currentFilters } = this.state;
    const { destination } = currentSearch;
    const airport = get(destination, 'enhancedAirport', false);

    currentFilters = currentFilters.filter(f => (!currentFilters.get(FILTERS[f].inverse)));
    currentFilters.forEach((filter) => { locations = locations.filter(FILTERS[filter].onFilter); });

    const appClasses = cx({
      'app': true,
      'search-pages': true,
      'location-selected': this.selectedLocation,
      'mapview': this.props.displayMap,
    });

    const wrapperClasses = cx({
      wrapper: true,
    });

    return (
      <div className={appClasses}>
        <TimePickerBar
          geoIPLocation={this.props.geoIPLocation}
          timezone={this.props.timezone}
          currentSearch={this.props.currentSearch}
          timesChange={this.props.changeTimes}
          changeMonthlyStart={this.props.changeMonthlyStart}
          monthlyStart={this.props.monthlyStart}
          onSearch={this.onSearch}
          dismissTimePickerPrompt={this.props.dismissTimePickerPrompt}
          trackEvent={this.props.trackEvent}
          app={this.props.app}
          appContext={this.props.appContext}
          brand={this.props.brand}
        />
        <SelectTimeToolTip
          dismissTimePickerPrompt={this.props.dismissTimePickerPrompt}
          timePickerPromptDismissed={this.props.timePickerPromptDismissed}
          trackEvent={this.props.trackEvent}
          currentSearch={this.props.currentSearch}
        />
        <Messages />
        <div className={wrapperClasses} ref={(wrapper) => { this.wrapper = wrapper; }}>
          { this.renderLocationListOrDetails(locations) }
          <SearchMap airport={airport} />
        </div>
      </div>
    );
  }
}

Search.propTypes = propTypes;
Search.defaultProps = defaultProps;

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    changeSelectedLocation,
    changeSearchDestination: changeSearchDestinationCreator,
    highlightLocation,
    getSelectedLocation,
    boundsChange,
    changeTimes,
    dismissTimePickerPrompt,
    dismissCuration: dismissCurationCreator,
    changeModalState: setModalState,
    setMapRefresh,
    parkingNearMeSearch,
    toggleMap,
    changeMonthlyStart,
    setCurrentSearchAndChangeBounds,
    selectQuote,
    trackEvent: trackEventCreator,
    initializeSearch,
    cleanupSearchState,
    showCuration: showCurationCreator,
    scrollToLocation,
    getLocationSeller: getLocationSellerCreator,
    changeSort,
    changeParkingType,
    modifyRequiredFieldList,
  }, dispatch)
);

const mapStateToProps = (state, ownProps) => ({
  app: state.app.name,
  brand: state.brand.brand,
  appContext: state.app.appContext,
  locations: state.search.locations,
  currentSearch: state.search.currentSearch,
  previousSearch: state.search.previousSearch,
  highlightedLocation: state.search.highlightedLocation,
  displayMap: state.search.displayMap,
  forceMapRefresh: state.search.forceMapRefresh,
  geoIPLocation: state.search.geoIPLocation,
  mobileOnly: state.search.mobileOnly,
  internalReferrer: state.search.internalReferrer,
  trackingProperties: state.analytics.trackingProperties,
  couponCode: state.search.couponCode,
  portalAffiliateId: state.search.portalAffiliateId,
  affiliateId: state.search.affiliateId,
  requestingLocation: state.search.requestingLocation,
  timezone: state.search.timezone,
  venue: state.search.venue,
  event: state.search.event,
  events: state.search.events,
  curationDismissed: state.search.curationDismissed,
  timePickerPromptDismissed: state.search.timePickerPromptDismissed,
  monthlyStart: state.checkout.monthlyStart,
  selectedLocation: state.search.selectedLocation,
  selectedQuote: state.search.selectedQuote,
  searchInitialized: state.search.initialized,
  routerLocation: ownProps.routerLocation,
  sellers: state.search.sellers,
  streetParking: state.search.streetParking,
  showStreetParking: state.search.showStreetParking,
  requiredFields: state.search.requiredFields,
  requiredFieldErrors: state.search.requiredFieldErrors,
  monthlyAvailable: state.search.monthlyAvailable,
});

export default connect(mapStateToProps, mapDispatchToProps)(Search);
