import React from 'react';
import PropTypes from 'prop-types';
import SearchModel from 'models/search';
import * as RoutingStyle from 'lib/routing-style';

export const propTypes = {
  isMonthly: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  currentSearch: PropTypes.instanceOf(SearchModel).isRequired,
  routingStyle: PropTypes.string,
};

export const defaultProps = {
  isMonthly: false,
  routingStyle: RoutingStyle.PARKWHIZ,
};

function ParkingTypeToggle({ onClick, isMonthly, currentSearch, routingStyle }) {
  if (isMonthly) {
    return (
      <a
        className="text-size-10 text-weight-bold text-color-blue toggle-pricing-type"
        onClick={onClick}
        href={currentSearch.route(null, null, null, { forceMonthly: false, routingStyle })}
      >
        SHOW HOURLY PARKING
      </a>
    );
  }

  return (
    <a
      className="text-size-10 text-weight-bold text-color-blue toggle-pricing-type"
      onClick={onClick}
      href={currentSearch.route(null, null, null, { forceMonthly: true, routingStyle })}
    >
      SHOW MONTHLY PARKING
    </a>
  );
}

ParkingTypeToggle.propTypes = propTypes;
ParkingTypeToggle.defaultProps = defaultProps;

export default ParkingTypeToggle;
