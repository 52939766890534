import React, { Component } from 'react';
import PropTypes from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor';
import LazyLoad from 'react-lazy-load';

import FriendlyLocationDistance from 'components/location/friendly-distance';

import { Location } from 'models/locations';
import Quote from 'models/quote';

const propTypes = {
  location: PropTypes.instanceOf(Location).isRequired,
  quote: PropTypes.instanceOf(Quote).isRequired,
  thumb: PropTypes.string.isRequired,
  /* eslint-disable react/forbid-prop-types */
  formattedPrice: PropTypes.array.isRequired,
  /* eslint-enable */
  showUrl: PropTypes.bool.isRequired,
  isSoldOut: PropTypes.bool.isRequired,
  cls: PropTypes.string.isRequired,
  click: PropTypes.func.isRequired,
  mouseenter: PropTypes.func.isRequired,
  mouseleave: PropTypes.func.isRequired,
  touchstart: PropTypes.func.isRequired,
  touchmove: PropTypes.func.isRequired,
  touchend: PropTypes.func.isRequired,
  touchcancel: PropTypes.func.isRequired,
  onVisibilityChange: PropTypes.func.isRequired,
  renderGuarantee: PropTypes.func.isRequired,
  renderMonthlyName: PropTypes.func.isRequired,
  renderPackageName: PropTypes.func.isRequired,
  renderAddress: PropTypes.func.isRequired,
  renderName: PropTypes.func.isRequired,
  renderPrice: PropTypes.func.isRequired,
  renderPhone: PropTypes.func.isRequired,
  renderBookNowButton: PropTypes.func.isRequired,
  renderDetailLink: PropTypes.func.isRequired,
};

class BookableListing extends Component {
  render() {
    const {
      location,
      quote,
      thumb,
      formattedPrice,
      showUrl,
      cls,
      click,
      mouseenter,
      mouseleave,
      touchstart,
      touchmove,
      touchend,
      touchcancel,
      isSoldOut,
      onVisibilityChange,
      renderGuarantee,
      renderMonthlyName,
      renderPackageName,
      renderAddress,
      renderName,
      renderPrice,
      renderPhone,
      renderBookNowButton,
      renderDetailLink,
    } = this.props;
    if (!quote || !formattedPrice) return null;
    return (
      <div
        id={`p${location.id}`}
        className={cls}
        key={`pl${location.id}`}
        onMouseEnter={mouseenter}
        onMouseLeave={mouseleave}
        onTouchStart={touchstart}
        onTouchMove={touchmove}
        onTouchEnd={isSoldOut ? null : touchend}
        onClick={isSoldOut ? null : click}
        onTouchCancel={touchcancel}
        ref={(l) => { this.listing = l; }}
      >
        <VisibilitySensor onChange={onVisibilityChange} partialVisibility>
          <div className="listing-container row">
            <div className="listing-thumb col-xs-4">
              <LazyLoad>
                <img src={thumb} alt={`${location.name} entrance`} />
              </LazyLoad>
              { quote ? renderGuarantee(quote) : null }
            </div>
            <div className="listing-details col-xs-8">
              <div className="row">
                <div className="col-xs-8">
                  {renderMonthlyName()}
                  {renderPackageName()}
                  {renderAddress()}
                  {renderName()}
                  <div className="distance">
                    <FriendlyLocationDistance location={location} />
                  </div>
                </div>
                <div className="col-xs-4 col-gutter-left-0">
                  { renderPrice(formattedPrice) }
                </div>
              </div>
              <div className="listing-bottom row">
                <div className="col-xs-12">
                  { renderPhone() }
                </div>
                <div className="col-xs-8 col-sm-8 padding-sm-0">
                  { renderBookNowButton(showUrl, isSoldOut) }
                </div>
                { renderDetailLink(isSoldOut) }
              </div>
            </div>
          </div>
        </VisibilitySensor>
      </div>
    );
  }
}

BookableListing.propTypes = propTypes;
export default BookableListing;
