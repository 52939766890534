import React from 'react';
import PropTypes from 'prop-types';
import { Location } from 'models/locations';

const propTypes = {
  location: PropTypes.instanceOf(Location).isRequired,
};

const StartingAtLabel = ({ location }) => {
  if (!location || location.quotes.size <= 1) { return null; }
  return 'starting at';
};

StartingAtLabel.propTypes = propTypes;

export default StartingAtLabel;
