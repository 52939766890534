// External
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import List from 'react-virtualized/dist/commonjs/List';

const propTypes = {
  value: PropTypes.instanceOf(moment).isRequired,
  timezone: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  lineItemClassName: PropTypes.string,
  listId: PropTypes.string,
};

const defaultProps = {
  lineItemClassName: '',
  listId: '',
};

class TimeCollection extends Component {
  constructor(props) {
    super(props);

    // We pre-calculate all the possible times in constructor because
    // it seems annoying and a waste of resources to constantly caclulate
    // the array of times for each render.
    const times = [moment().tz(this.props.timezone).startOf('day').format()];
    let lastTime;
    let i = 0;
    while (i < 47) {
      i += 1;
      lastTime = moment.tz(times[times.length - 1], this.props.timezone);
      times[times.length] = lastTime.add(30, 'minutes').format();
    }

    this.state = { times };
    this.handleTimeClick = this.handleTimeClick.bind(this);
    this.rowRenderer = this.rowRenderer.bind(this);
  }

  handleTimeClick(event) {
    const { dataset } = event.target;

    let hour;
    let minute;
    if (dataset) {
      hour = dataset.hour;
      minute = dataset.minute;
    } else {
      hour = event.target.getAttribute('data-hour');
      minute = event.target.getAttribute('data-minute');
    }

    this.props.onChange({ hour, minute });
  }

  get selectedIndex() {
    const { value } = this.props;
    return Math.floor((value.hours() * 2) + (value.minutes() / 30));
  }

  rowRenderer({ key, index, style }) {
    const time = moment(this.state.times[index]).tz(this.props.timezone);

    return (
      <div
        key={key}
        style={style}
        data-hour={time.hour()}
        data-minute={time.minute()}
        data-selected={index === this.selectedIndex}
        onClick={this.handleTimeClick}
        className={this.props.lineItemClassName}
      >
        { time.format('h:mm a') }
      </div>
    );
  }

  render() {
    const { times } = this.state;

    return (
      <div>
        <List
          className="time-dropdown noselect"
          height={190}
          id={this.props.listId}
          rowCount={times.length}
          rowHeight={40}
          rowRenderer={this.rowRenderer}
          scrollToAlignment="center"
          scrollToIndex={this.selectedIndex}
          width={150}
        />
      </div>
    );
  }
}

TimeCollection.propTypes = propTypes;
TimeCollection.defaultProps = defaultProps;

export default TimeCollection;
