import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

const propTypes = {
  instructions: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

const AirportLocatonInstructionsRow = ({ instructions, title }) => (
  <Fragment>
    { instructions &&
      <div className="row">
        <div className="col-sm-8">
          <div className="padding-top-40 text-size-sm-24 text-size-xs-18 text-color-dark-slate text-weight-bold">
            { title }
          </div>
          <div className="margin-top-20 text-size-sm-16 text-size-xs-14 text-weight-book text-color-dark-slate">
            <ReactMarkdown
              source={instructions}
            />
          </div>
        </div>
      </div>
    }
  </Fragment>
);

AirportLocatonInstructionsRow.propTypes = propTypes;

export default AirportLocatonInstructionsRow;
