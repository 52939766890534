import React, { Component } from 'react';
import PropTypes from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor';

import { NOTICE_IMPRESSION, NOTICE_CLICK } from 'lib/analytics/events';

import Search from 'models/search';

const propTypes = {
  dismissTimePickerPrompt: PropTypes.func.isRequired,
  timePickerPromptDismissed: PropTypes.bool.isRequired,
  trackEvent: PropTypes.func.isRequired,
  currentSearch: PropTypes.instanceOf(Search).isRequired,
};
class SelectTimeToolTip extends Component {
  constructor(props) {
    super(props);

    this.onVisibilityChange = this.onVisibilityChange.bind(this);
    this.onClick = this.onClick.bind(this);
    this.trackNoticeEvent = this.trackNoticeEvent.bind(this);
  }

  trackNoticeEvent(event) {
    this.props.trackEvent({
      ...event,
      name: 'ChangeTimeNotice',
      position: '0',
    });
  }


  onVisibilityChange(isVisible) {
    if (isVisible) {
      this.trackNoticeEvent(NOTICE_IMPRESSION);
    }
  }

  onClick(e) {
    e.preventDefault();

    this.props.dismissTimePickerPrompt();
    this.trackNoticeEvent(NOTICE_CLICK);
  }

  render() {
    const { timePickerPromptDismissed, currentSearch } = this.props;
    const { isMonthlySearch, isEventSearch, isPackageSearch } = currentSearch;
    if (timePickerPromptDismissed || isMonthlySearch || isEventSearch || isPackageSearch) { return null; }

    return (
      <VisibilitySensor onChange={this.onVisibilityChange} partialVisibility>
        <div className="tooltip-prompt time-picker-prompt">
          <div className="padding-horizontal-10 padding-vertical-15 text-size-12 text-color-white">
            <span className="iconified-font iconified-clock text-size-16 iconified-space-right" />
            <span className="prompt-message">Select the start time and end time <br className="visible-xs" />for your booking here.</span>
              <span className="iconified-font iconified-x text-size-14 iconified-space-left clickable" onClick={this.onClick} />
          </div>
        </div>
      </VisibilitySensor>
    );
  }
}

SelectTimeToolTip.propTypes = propTypes;
export default SelectTimeToolTip;
