import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List, OrderedMap } from 'immutable';
import moment from 'moment-timezone';

import { Location } from 'models/locations';
import Venue from 'models/venue';
import Event from 'models/event';
import Hub from 'models/hub';
import Point from 'models/point';
import Quote from 'models/quote';

import { MAP_LOCATION_LISTING_CLICK } from 'lib/analytics/events';
import trackLocationListPageView from 'lib/analytics/events/track-location-list-pageview';
import { routerLocationShape } from 'lib/common/prop-types';

// Components
import NothingFound from 'components/search/list/nothing-found';
import DestinationHeader from 'components/search/list/destination-header';
import LocationSorter from 'components/search/list/location-sorter';
import ParkingListing from 'containers/search/list/listing';
import ParkingTypeButton from 'containers/search/list/parking-type-button';

const propTypes = {
  currentSearch: PropTypes.shape({}),
  previousSearch: PropTypes.shape({}),
  selectedLocation: PropTypes.instanceOf(Location),
  toggleMap: PropTypes.func.isRequired,
  changeSort: PropTypes.func.isRequired,
  nearbyMetros: PropTypes.instanceOf(List).isRequired,
  venue: PropTypes.instanceOf(Venue),
  event: PropTypes.instanceOf(Event),
  events: PropTypes.instanceOf(OrderedMap),
  locations: PropTypes.instanceOf(OrderedMap).isRequired,
  changeParkingType: PropTypes.func.isRequired,
  monthlyAvailable: PropTypes.bool.isRequired,
  startTime: PropTypes.instanceOf(moment).isRequired,
  endTime: PropTypes.instanceOf(moment).isRequired,
  trackEvent: PropTypes.func.isRequired,
  scrollToTop: PropTypes.func.isRequired,
  highlightLocation: PropTypes.func.isRequired,
  changeLocation: PropTypes.func.isRequired,
  selectedQuote: PropTypes.instanceOf(Quote),
  position: PropTypes.number,
  location: PropTypes.instanceOf(Location),
  quote: PropTypes.instanceOf(Quote),
  destination: PropTypes.oneOfType([
    PropTypes.instanceOf(Hub),
    PropTypes.instanceOf(Venue),
    PropTypes.instanceOf(Event),
    PropTypes.instanceOf(Point),
    PropTypes.string,
  ]).isRequired,
  displayMap: PropTypes.bool.isRequired,
  showCuration: PropTypes.func.isRequired,
  canShowCuration: PropTypes.bool,
  routerLocation: routerLocationShape.isRequired,
};

const defaultProps = {
  currentSearch: {},
  previousSearch: {},
  hub: {},
  venue: null,
  event: null,
  events: null,
  location: null,
  quote: null,
  selectedLocation: null,
  position: null,
  selectedQuote: null,
  canShowCuration: false,
};

class DefaultList extends Component {
  constructor(props) {
    super(props);

    this.toggleInfo = this.toggleInfo.bind(this);
    this.showAll = this.showAll.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
    this.changeLocation = this.changeLocation.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
    this.toggleMap = this.toggleMap.bind(this);
  }

  componentWillMount() {
    this.setState({
      showInfo: false,
      showAll: false,
    });
  }

  componentDidMount() {
    trackLocationListPageView(this.props);
  }

  componentDidUpdate(prevProps) {
    const { currentSearch } = this.props;
    const { currentSearch: prevSearch } = prevProps;

    if (currentSearch.destination.id !== prevSearch.destination.id) {
      trackLocationListPageView(this.props);
    }
  }

  scrollToTop() {
    if (this.list) {
      this.list.scrollTop = 0;
    }
    this.props.scrollToTop();
  }

  toggleInfo(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ showInfo: !this.state.showInfo });
  }

  toggleMap(event) {
    event.preventDefault();
    event.stopPropagation();
    this.props.toggleMap();
  }

  onSortChange(sort) {
    this.props.changeSort({ sort });
  }

  renderInfo(infoEl) {
    const { destination } = this.props;
    const info = infoEl.innerHTML;
    return (
      <section className="list" ref={(list) => { this.list = list; }}>
        <div className="bg-warning pam">
          <a
            href="#search-list-related-info"
            title="See related information"
            id="search-list-info-link"
            onClick={this.toggleInfo}
            className="pull-right"
            data-category="Search"
            data-action="relatedInfoButton"
          >
            <span className="valet-glyph-info" />
          </a>
          <h3 className="man text-white">
            Parking Near {destination}
          </h3>
        </div>
        <div dangerouslySetInnerHTML={{ __html: info }} />
      </section>
    );
  }

  // Only render location details if a specific quote has been selected
  shouldRenderFacilityDetails() {
    const { selectedLocation, selectedQuote } = this.props;
    let unavailable = false;
    if (selectedLocation) {
      unavailable = selectedLocation.isUnavailable;
    }
    return (Boolean(selectedQuote || unavailable));
  }

  showAll(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ showAll: true });
  }


  mouseenter() {
    this.setState({ hover: true });
    const { location } = this.props;
    this.props.highlightLocation({ locationId: location.id });
  }

  mouseleave() {
    this.setState({ hover: false });
    this.props.highlightLocation({ locationId: null });
  }

  touchstart(event) {
    event.stopPropagation();
    if (event.touches.length === 1) {
      this.startedTouch = event;
    }
  }

  booktouchstart(event) {
    event.stopPropagation();
    if (event.touches.length === 1) {
      this.startedBookTouch = event;
    }
  }

  touchmove(event) {
    event.stopPropagation();
    this.startedTouch = null;
    this.startedBookTouch = null;
  }

  touchend(event) {
    if (this.startedBookTouch) {
      this.onBookNow(event);
    } else if (this.startedTouch) {
      this.click(event);
    }

    this.startedTouch = null;
    this.startedBookTouch = null;
    event.preventDefault();
    event.stopPropagation();
  }

  touchcancel(event) {
    this.startedTouch = null;
    this.startedBookTouch = null;
    event.preventDefault();
    event.stopPropagation();
  }

  changeLocation(input) {
    this.props.changeLocation(input);
  }

  click(event) {
    event.preventDefault();
    event.stopPropagation();

    const { location, trackEvent, position, quote } = this.props;

    trackEvent({
      ...MAP_LOCATION_LISTING_CLICK,
      position,
      properties: {
        'Location ID': location.id,
      },
    });
    this.scrollToTop();
    this.props.changeLocation({
      locationId: location.id,
      quoteId: (quote && quote.id),
      eventPackageId: (quote && quote.get('eventPackageId')),
    });
  }

  loading() {
    /**
     * Show the loading symbol if either:
     * 1) The search is dirty
     * 2) Someone is requesting a location but that location isn't available currently
     */
    const { currentSearch, previousSearch, selectedLocation } = this.props;

    return !!(
      currentSearch.isDirty(previousSearch) ||
      (currentSearch.selectedLocationId && !selectedLocation) ||
      currentSearch.pendingGeolocationPermission
    );
  }

  render() {
    const { currentSearch, selectedLocation } = this.props;
    const locationLimit = currentSearch.mobileOnly ? 20 : 50;

    if (this.state.showInfo) {
      const infoEl = document.getElementById('search-list-related-info');
      if (infoEl) {
        return this.renderInfo(infoEl);
      }
    }

    const nodes = [];

    // show parking for the search criteria
    if (!this.shouldRenderFacilityDetails()) {
      let { locations: locationsToRender } = this.props;
      const { startTime, endTime, event, venue } = this.props;

      if (selectedLocation && selectedLocation.hasMultipleQuotes) {
        locationsToRender = locationsToRender.filter(l => selectedLocation.id === l.id);
      }

      if (!(this.state.showAll && locationsToRender.size > locationLimit)) {
        locationsToRender = locationsToRender.slice(0, locationLimit);
      }

      let position = 0;
      const quoteSorter = (a, b) => {
        if (a.bookable !== b.bookable) {
          if (a.bookable) { return -1; }
          return 1;
        }
        return a.basePrice - b.basePrice;
      };

      locationsToRender.forEach((location) => {
        if (location.hasMultipleBookableQuotes) {
          location.quotes.filter(q => q.bookable).sort(quoteSorter).forEach((quote) => {
            const key = `p${quote.id}`;
            nodes.push(
              <ParkingListing
                key={key}
                location={location}
                start={startTime}
                end={endTime}
                list={this.list}
                event={event}
                venue={venue}
                quote={quote}
                mouseenter={this.mouseenter}
                mouseleave={this.mouseleave}
                touchstart={this.touchstart}
                booktouchstart={this.booktouchstart}
                touchmove={this.touchmove}
                touchend={this.touchend}
                click={this.click}
                changeLocation={this.changeLocation}
                touchcancel={this.touchcancel}
                scrollToTop={this.scrollToTop}
                position={`${position}`}
                highlightLocation={this.props.highlightLocation}
              />);
          });
        } else {
          const key = `l${location.id}`;
          const quote = location.quotes ? location.getAvailableQuotes().sort(quoteSorter).first() : null;
          nodes.push(
            <ParkingListing
              key={key}
              location={location}
              start={startTime}
              end={endTime}
              list={this.list}
              event={event}
              quote={quote}
              mouseenter={this.mouseenter}
              mouseleave={this.mouseleave}
              touchstart={this.touchstart}
              booktouchstart={this.booktouchstart}
              touchmove={this.touchmove}
              touchend={this.touchend}
              click={this.click}
              changeLocation={this.changeLocation}
              touchcancel={this.touchcancel}
              scrollToTop={this.scrollToTop}
              position={`${position}`}
              highlightLocation={this.props.highlightLocation}
            />);
        }
        position++;
      });
      

      if (!this.state.showAll && locationsToRender.size > locationLimit) {
        nodes.push(
          <article className="location show-link" key="show-all">
            <div><a onClick={this.showAll}>{`Show ${locationsToRender.size - locationLimit} more locations`}</a></div>
          </article>);
      }
    }

    if (nodes.length === 0) {
      nodes.push(
        <NothingFound
          nearbyMetros={this.props.nearbyMetros}
          routerLocation={this.props.routerLocation}
          currentSearch={this.props.currentSearch}
          key="nothing-found"
        />);
    }

    const loading = this.loading();
    const viewInMapRow = (
      <div className="row map-and-monthly-row visible-lte-sm">
        <div className="col-xs-5 text-right">
          <a className="larger-hit-area" onClick={this.toggleMap}>
            <span className="text-size-10 text-weight-light btn-style-minimal-outline btn-color-link-blue">
              VIEW IN MAP
            </span>
          </a>
        </div>
        <div className="col-xs-7">
          { currentSearch.selectedLocationId ? null : <ParkingTypeButton mobile /> }
        </div>
      </div>
    );
    let listWrapperClass = 'list-wrapper';
    if (loading) { listWrapperClass += ' loading'; }

    this.nodes = nodes;
    return (
      <div className={listWrapperClass}>
        <section className="list" ref={(list) => { this.list = list; }}>
          { !this.shouldRenderFacilityDetails() ? viewInMapRow : null }
          <div className={loading ? 'loading' : ''}>
            <DestinationHeader
              currentSearch={currentSearch}
              event={this.props.event}
              venue={this.props.venue}
              events={this.props.events}
              monthlyAvailable={this.props.monthlyAvailable}
              changeParkingType={this.props.changeParkingType}
              selectedLocation={this.props.selectedLocation}
            />
            <div className="sub-nav-border sub-nav noselect no-underline">
              <LocationSorter
                currentSearch={currentSearch}
                sort={currentSearch.sort}
                locations={this.props.locations}
                onSortChange={this.onSortChange}
                trackEvent={this.props.trackEvent}
                showCuration={this.props.showCuration}
                canShowCuration={this.props.canShowCuration}
                selectedLocation={this.props.selectedLocation}
              />
            </div>
            {nodes}
          </div>
        </section>
      </div>
    );
  }
}

DefaultList.propTypes = propTypes;
DefaultList.defaultProps = defaultProps;

export default DefaultList;
