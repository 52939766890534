import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const propTypes = {
  contactPhone: PropTypes.string.isRequired,
};

const PhoneNumberNotice = ({ contactPhone }) => (
  <div className="text-color-white background-color-blue row text-size-12 text-size-sm-16 text-weight-book">
    <div className="col-xs-12 padding-vertical-xs-20 padding-vertical-sm-10 text-align-center">
      <FormattedMessage
        id="search.contact-phone"
        defaultMessage="Need a hand with monthly parking? Call us at {phone}"
        values={{
          phone: <a className="text-color-white" href={`tel:${contactPhone}`}>{contactPhone}</a>,
        }}
      />
    </div>
  </div>
);

PhoneNumberNotice.propTypes = propTypes;

export default PhoneNumberNotice;
