import React from 'react';

const FeesNotice = () => (
  <div className="text-color-white background-color-blue row text-size-12 text-size-sm-16 text-weight-book">
    <div className="col-xs-12 padding-vertical-xs-20 padding-vertical-sm-10 text-align-center">
      No-Surprise Pricing - All Fees Included
    </div>
  </div>
);

export default FeesNotice;
