import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Location } from 'models/locations';

const propTypes = {
  location: PropTypes.instanceOf(Location).isRequired,
};

class NonBookableDirections extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    // eslint-disable-next-line no-alert
    if (!confirm('Leaving BestParking for GPS directions. Are you sure?')) {
      e.preventDefault();
    }
  }

  render() {
    const { location } = this.props;

    return (
      <div className="col-xs-12 align-right padding-right-0 padding-bottom-5">
        <a
          href={location.googleMapURL()}
          target="_blank"
          rel="noopener noreferrer nofollow"
          className="text-color-dark-slate text-size-12 hover-text-color-blue"
          onClick={this.onClick}
        >
          <span className="iconified-font iconified-directions" /> GPS Directions
        </a>
      </div>
    );
  }
}

NonBookableDirections.propTypes = propTypes;
export default NonBookableDirections;
