import React from 'react';
import PropTypes from 'prop-types';
import { OrderedSet } from 'immutable';
import kebabCase from 'lodash/kebabCase';

import TierAmenity from 'components/search/list/tier-amenity';

const propTypes = {
  amenities: PropTypes.instanceOf(OrderedSet).isRequired,
  bsClassName: PropTypes.string,
  bsChildClassName: PropTypes.string,
};

const defaultProps = {
  bsClassName: null,
  bsChildClassName: null,
};

const TierAmenities = ({ amenities, bsClassName, bsChildClassName }) => (
  <div className="row">
    <div className={bsClassName || 'col-xs-10'}>
      <div className="row">
        {amenities.map(a => <TierAmenity amenity={a} key={`ta-${kebabCase(a)}`} bsClassName={bsChildClassName} />)}
      </div>
    </div>
  </div>
);

TierAmenities.propTypes = propTypes;
TierAmenities.defaultProps = defaultProps;
export default TierAmenities;
