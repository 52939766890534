import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import sanitizeHTML from 'sanitize-html';
import VisibilitySensor from 'react-visibility-sensor';

import Venue from 'models/venue';

import { AIRPORT_MAP_VENUE_INFO_DROPDOWN_IMPRESSION, AIRPORT_MAP_VENUE_INFO_DROPDOWN_CLICK, AIRPORT_MAP_VENUE_INFO_CONTENT_IMPRESSION } from 'lib/analytics/events';

const propTypes = {
  venue: PropTypes.instanceOf(Venue).isRequired,
  trackEvent: PropTypes.func.isRequired,
  appContext: PropTypes.string.isRequired,
  displayMap: PropTypes.bool.isRequired,
};

class VenueInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
    };

    this.toggleInfo = this.toggleInfo.bind(this);
    this.onVisibilityChange = this.onVisibilityChange.bind(this);
  }

  toggleInfo() {
    const { trackEvent } = this.props;

    this.setState({ expanded: !this.state.expanded });
    trackEvent(AIRPORT_MAP_VENUE_INFO_DROPDOWN_CLICK);

    if (!this.state.expanded) {
      trackEvent(AIRPORT_MAP_VENUE_INFO_CONTENT_IMPRESSION);
    }
  }

  onVisibilityChange(isVisible) {
    const { trackEvent, appContext, displayMap } = this.props;
    if (isVisible && (appContext === 'desktop' || !displayMap)) {
      trackEvent({
        ...AIRPORT_MAP_VENUE_INFO_DROPDOWN_IMPRESSION,
        position: '0',
      });
    }
  }

  render() {
    const { venue } = this.props;
    const { expanded } = this.state;

    const infoClasses = cx({
      'col-xs-12': true,
      'text-weight-book': true,
      'text-size-12': true,
      'col-gutter-30': true,
      'collapsible': true,
      'collapsed': !expanded,
    });

    const toggleClasses = cx({
      'text-size-12': true,
      'iconified-font': true,
      'iconified-space-left': true,
      'iconified-chevron-down': !expanded,
      'iconified-chevron-up': expanded,
    });

    return (
      <div className="listing-container row padding-bottom-20 text-color-slate" onClick={this.toggleInfo}>
        <VisibilitySensor onChange={this.onVisibilityChange} partialVisibility>
          <div className="col-xs-12 text-right col-gutter-right-30 padding-bottom-10 padding-top-20 text-size-14 text-weight-medium clickable">
            {`More about ${venue.name}`}<span className={toggleClasses} />
          </div>
        </VisibilitySensor>
        <div className={infoClasses} dangerouslySetInnerHTML={{ __html: sanitizeHTML(venue.description) }} />
      </div>
    );
  }
}

VenueInfo.propTypes = propTypes;
export default VenueInfo;
