import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import url from 'url';
import result from 'lodash/result';
import VisibilitySensor from 'react-visibility-sensor';
import FriendlyLocationDistance from 'components/location/friendly-distance';
import cx from 'classnames';

import { Location } from 'models/locations';
import { ImageSize } from 'models/image';

const propTypes = {
  location: PropTypes.instanceOf(Location).isRequired,
  image: PropTypes.instanceOf(ImageSize).isRequired,
  isSoldOut: PropTypes.bool.isRequired,
  soldOut: PropTypes.string.isRequired,
  showUrl: PropTypes.bool.isRequired,
  cls: PropTypes.string.isRequired,
  shuttleClasses: PropTypes.string.isRequired,
  buttonClasses: PropTypes.string.isRequired,
  mouseenter: PropTypes.func.isRequired,
  mouseleave: PropTypes.func.isRequired,
  touchstart: PropTypes.func.isRequired,
  touchend: PropTypes.func.isRequired,
  touchmove: PropTypes.func.isRequired,
  touchcancel: PropTypes.func.isRequired,
  click: PropTypes.func.isRequired,
  onVisibilityChange: PropTypes.func.isRequired,
  renderPrice: PropTypes.func.isRequired,
  renderParkHereButton: PropTypes.func.isRequired,
  monthlyAvailable: PropTypes.bool.isRequired,
};

// Components
import ShuttleFrequencyLabel from 'components/search/list/shuttle-frequency-label';
import TierAmenities from 'components/search/list/tier-amenities';
const BookableAirportListing = ({
  location,
  image,
  isSoldOut,
  soldOut,
  showUrl,
  cls,
  shuttleClasses,
  buttonClasses,
  mouseenter,
  mouseleave,
  touchstart,
  touchend,
  touchmove,
  touchcancel,
  click,
  onVisibilityChange,
  renderPrice,
  renderParkHereButton,
  currentSearch,
  routerLocation,
  displayMap,
  appContext,
  routingStyle,
  venue,
  monthlyAvailable,
}) => {
  let to;
  if (isSoldOut) {
    to = routerLocation;
  } else {
    const locationSearch = currentSearch.set('selectedLocationId', location.id);
    const { pathname, search } = url.parse(locationSearch.route(location, displayMap, appContext, { routingStyle }));
    to = {
      pathname,
      search,
      state: {
        app: { name: 'Search' },
        search: {
          currentSearch: result(locationSearch, 'toJSON', {}),
          venue: JSON.stringify(result(venue, 'toJSON', {})),
          locationId: location.id,
          // quoteId: quote.id,
          selectedLocation: JSON.stringify(location.toJSON()),
          // selectedQuote: quote.toJSON(),
          displayMap,
        },
      },
    };
  }
  const addressClasses = cx({
    'text-weight-light text-size-12 text-color-medium-grey margin-top-10': true,
    'margin-bottom-10': monthlyAvailable,
    'margin-bottom-15': !monthlyAvailable,
  });
  return (
    <Link
      id={`p${location.id}`}
      className={cls}
      key={`pl${location.id}`}
      onMouseEnter={mouseenter}
      onMouseLeave={mouseleave}
      to={to}
    >
      <VisibilitySensor onChange={onVisibilityChange} partialVisibility>
        <div className="listing-container row">
          <div className="airport-listing-thumb col-xs-4 col-gutter-0 hidden-xs">
            <img src={image && image.url} alt="" />
          </div>
          <div className="col-sm-8 col-xs-12">
            <div className="row">
              <div className="listing-details col-xs-8 col-gutter-right-sm-0">
                <div
                  className="text-size-md-18 text-size-xs-16 text-color-dark-slate text-weight-bold wrap-ellipses"
                  dangerouslySetInnerHTML={{ __html: location.name }}
                />
                <div className={addressClasses}>{location.address}</div>
                {monthlyAvailable &&
                  <div className="text-size-12 text-color-dark-slate text-weight-book margin-bottom-10"><FriendlyLocationDistance location={location} /></div>
                }
                <TierAmenities amenities={location.tierAmenities} />
              </div>
              {renderPrice()}
            </div>
            <div className="row padding-bottom-15">
              <div className={shuttleClasses}>
                <ShuttleFrequencyLabel shuttleFrequencies={location.shuttleFrequencyBounds} />
              </div>
              <div className={buttonClasses}>
                {renderParkHereButton(showUrl, soldOut)}
              </div>
            </div>
          </div>
        </div>
      </VisibilitySensor>
    </Link>
  );
};

BookableAirportListing.propTypes = propTypes;
export default BookableAirportListing;
