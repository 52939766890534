import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List, OrderedMap } from 'immutable';
import moment from 'moment-timezone';

import { Location } from 'models/locations';
import Venue from 'models/venue';
import Event from 'models/event';
import Hub from 'models/hub';
import Point from 'models/point';
import Quote from 'models/quote';
import Brand from 'models/brand';

import changeSort from 'action-creators/search/change-sort';
import changeParkingType from 'action-creators/search/change-parking-type';
import toggleMap from 'action-creators/search/toggle-map';
import trackEventCreator from 'action-creators/analytics/track-event';
import unsetScrolledLocationCreator from 'action-creators/search/unset-scrolled-location';
import highlightLocation from 'action-creators/search/highlight-location';
import changeSelectedLocation from 'action-creators/search/change-selected-location';
import selectQuote from 'action-creators/search/select-quote';

import { usesLargeFormatLocationDetails } from 'lib/common/search-helpers';

// Components
import DefaultList from 'components/search/list/default-list';
import AirportList from 'components/search/list/airport-list';

const propTypes = {
  currentSearch: PropTypes.shape({
    parkingType: PropTypes.string,
  }),
  previousSearch: PropTypes.shape({}),
  selectedLocation: PropTypes.instanceOf(Location),
  toggleMap: PropTypes.func.isRequired,
  changeSort: PropTypes.func.isRequired,
  nearbyMetros: PropTypes.instanceOf(List).isRequired,
  venue: PropTypes.instanceOf(Venue),
  event: PropTypes.instanceOf(Event),
  events: PropTypes.instanceOf(OrderedMap),
  locations: PropTypes.instanceOf(OrderedMap).isRequired,
  changeParkingType: PropTypes.func.isRequired,
  monthlyAvailable: PropTypes.bool,
  startTime: PropTypes.instanceOf(moment).isRequired,
  endTime: PropTypes.instanceOf(moment).isRequired,
  trackEvent: PropTypes.func.isRequired,
  scrollToTop: PropTypes.func.isRequired,
  highlightLocation: PropTypes.func.isRequired,
  changeLocation: PropTypes.func.isRequired,
  selectedQuote: PropTypes.instanceOf(Quote),
  position: PropTypes.number,
  location: PropTypes.instanceOf(Location),
  quote: PropTypes.instanceOf(Quote),
  brand: PropTypes.instanceOf(Brand).isRequired,
  destination: PropTypes.oneOfType([
    PropTypes.instanceOf(Hub),
    PropTypes.instanceOf(Venue),
    PropTypes.instanceOf(Event),
    PropTypes.instanceOf(Point),
    PropTypes.string,
  ]).isRequired,
  airport: PropTypes.bool,
  showCuration: PropTypes.func.isRequired,
  canShowCuration: PropTypes.bool,
  scrolledLocation: PropTypes.instanceOf(Location),
  unsetScrolledLocation: PropTypes.func.isRequired,
};

const defaultProps = {
  currentSearch: {},
  previousSearch: {},
  hub: {},
  venue: null,
  event: null,
  events: null,
  location: null,
  quote: null,
  selectedLocation: null,
  airport: false,
  position: null,
  selectedQuote: null,
  canShowCuration: false,
  scrolledLocation: null,
  monthlyAvailable: false,
};

const ParkingList = (props) => {
  if (usesLargeFormatLocationDetails(props)) {
    return <AirportList {...props} />;
  }

  return <DefaultList {...props} />;
};

ParkingList.propTypes = propTypes;
ParkingList.defaultProps = defaultProps;

const mapStateToProps = (state, ownProps) => {
  const {
    currentSearch,
    previousSearch,
    transientListings,
    selectedLocation,
    selectedQuote,
    venue,
    events,
    event,
    hub,
    eventListings,
    packages,
    venueId,
    startTime,
    endTime,
    nearestMetro,
    nearbyMetros,
    displayMap,
    monthlyAvailable,
    scrolledLocation,
  } = state.search;

  const {
    appContext,
  } = state.app;

  const {
    brand,
  } = state.brand;

  const {
    displayModal,
  } = state.modal;

  let { location: routerLocation } = state.router;
  if (!routerLocation) { ({ routerLocation } = ownProps); }

  const { destination } = currentSearch;

  const { locations } = ownProps;

  return {
    brand,
    appContext,
    currentSearch,
    previousSearch,
    locations,
    transientListings,
    selectedLocation,
    selectedQuote,
    venue,
    events,
    event,
    hub,
    eventListings,
    packages,
    venueId,
    startTime,
    endTime,
    destination,
    nearestMetro,
    nearbyMetros,
    displayMap,
    monthlyAvailable,
    displayModal,
    routerLocation,
    scrolledLocation,
  };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    toggleMap,
    changeSort,
    changeParkingType,
    trackEvent: trackEventCreator,
    unsetScrolledLocation: unsetScrolledLocationCreator,
    highlightLocation,
    changeLocation: changeSelectedLocation,
    changeSelectedLocation,
    selectQuote,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(ParkingList);
