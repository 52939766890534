import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import VisibilitySensor from 'react-visibility-sensor';

import { Location } from 'models/locations';
import Search from 'models/search';
import Quote from 'models/quote';

import CurrencySymbol from 'components/common/currency-symbol';

import { PRICING_TIER_OPTION_IMPRESSION, PRICING_TIER_OPTION_CLICK } from 'lib/analytics/events/index';
import { pageProps } from 'lib/analytics/page-properties';

const propTypes = {
  location: PropTypes.instanceOf(Location).isRequired,
  selectedQuote: PropTypes.instanceOf(Quote),
  currentSearch: PropTypes.instanceOf(Search).isRequired,
  selectQuote: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired,
};

const defaultProps = {
  selectedQuote: null,
};

class AirportTierSelection extends Component {
  constructor(props) {
    super(props);

    this.selectQuote = this.selectQuote.bind(this);
    this.onTierVisibilityChange = this.onTierVisibilityChange.bind(this);
  }

  onTierVisibilityChange(quote, position) {
    const { trackEvent, location, currentSearch } = this.props;
    let { name } = quote;
    if (name === '') { name = quote.tierAmenities.join(' '); }

    trackEvent({
      ...PRICING_TIER_OPTION_IMPRESSION,
      ...pageProps({ app: 'Location Details', currentSearch }),
      name,
      position,
      properties: {
        locationID: location.id,
        tierAmenities: quote.tierAmenities.toJS(),
      },
    });
  }

  selectQuote(quote, position) {
    const { selectQuote, trackEvent, location, currentSearch } = this.props;
    let { name } = quote;
    if (name === '') { name = quote.tierAmenities.join(' '); }

    trackEvent({
      ...PRICING_TIER_OPTION_CLICK,
      ...pageProps({ app: 'Location Details', currentSearch }),
      name,
      position,
      properties: {
        locationID: location.id,
        tierAmenities: quote.tierAmenities.toJS(),
      },
    });

    selectQuote({ quote });
  }

  renderTiers() {
    const { location, selectedQuote } = this.props;
    const { quotes } = location;

    let position = 0;
    const tiers = quotes.map((quote) => {
      const p = position.toString();
      const selectQuote = () => { this.selectQuote(quote, p); };
      const onVisibilityChange = (visible) => {
        if (visible) {
          this.onTierVisibilityChange(quote, p);
        }
      };
      let priceLabel;
      if (quote.dailyPrice > 0) {
        priceLabel = (
          <span>
            (approx. <CurrencySymbol location={location} /><span className="text-weight-bold">{quote.dailyPrice}</span> per day)
          </span>
        );
      } else {
        priceLabel = (
          <span>
            (<CurrencySymbol location={location} /><span className="text-weight-bold">{quote.price}</span> total)
          </span>
        );
      }
      const radioClasses = cx({
        'radio-btn': true,
        'margin-right-15': true,
        'selected': quote === selectedQuote,
      });
      position++;

      return (
        <div
          className="text-size-sm-16 text-size-xs-14 text-weight-book clickable margin-top-20 row"
          onClick={selectQuote}
          key={`tier-${quote.id}`}
        >
          <VisibilitySensor onChange={onVisibilityChange} partialVisibility>
            <div>
              <div className="col-xs-1">
                <span className={radioClasses} />
              </div>
              <div className="col-xs-11">
                <span>{quote.name || quote.tierAmenities.join(' ')}&nbsp;</span>
                <br className="visible-xs" />
                {priceLabel}
              </div>
            </div>
          </VisibilitySensor>
        </div>
      );
    });

    return tiers;
  }

  render() {
    const { location } = this.props;
    if (!location || location.quotes.size <= 1) { return null; }

    return (
      <div>
        <div className="text-size-sm-24 text-size-xs-18 text-weight-bold margin-bottom-20 no-wrap">
          Select Parking Type
          <span className="text-size-12 text-weight-book margin-left-10">*Required</span>
        </div>
        { this.renderTiers() }
      </div>
    );
  }
}

AirportTierSelection.propTypes = propTypes;
AirportTierSelection.defaultProps = defaultProps;
export default AirportTierSelection;
