import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { OrderedSet, OrderedMap, Set } from 'immutable';
import kebabCase from 'lodash/kebabCase';

import Search from 'models/search';

import { SEARCH_FILTER_IMPRESSION, SEARCH_FILTER_CLICK } from 'lib/analytics/events';

export const FILTERS = {
  'Covered': {
    onFilter: location => (
      !!location.tierAmenities.get('Covered')
    ),
    inverse: 'Uncovered',
  },
  'Uncovered': {
    onFilter: location => (
      !!location.tierAmenities.get('Uncovered')
    ),
    inverse: 'Covered',
  },
  'Self Park': {
    onFilter: location => (
      !!location.tierAmenities.get('Self Park')
    ),
    inverse: 'Valet',
  },
  'Valet': {
    onFilter: location => (
      !!location.tierAmenities.get('Valet')
    ),
    inverse: 'Self Park',
  },
};

const propTypes = {
  locations: PropTypes.instanceOf(OrderedMap),
  currentFilters: PropTypes.instanceOf(Set),
  currentSearch: PropTypes.instanceOf(Search).isRequired,
  appContext: PropTypes.string.isRequired,
  showMobileFilters: PropTypes.bool,
  onFilter: PropTypes.func,
  trackEvent: PropTypes.func.isRequired,
};

const defaultProps = {
  locations: OrderedMap(),
  currentFilters: Set(),
  showMobileFilters: true,
  onFilter: () => {},
};

class ParkingFilters extends Component {
  constructor(props) {
    super(props);

    this.renderParkingFilter = this.renderParkingFilter.bind(this);
    this.onFilter = this.onFilter.bind(this);
  }

  componentDidMount() {
    const { trackEvent, currentFilters, showMobileFilters, appContext } = this.props;
    if (appContext === 'desktop' || showMobileFilters) {
      Object.keys(FILTERS).forEach((filter, i) => {
        trackEvent({
          ...SEARCH_FILTER_IMPRESSION,
          position: `${i}`,
          properties: {
            selected: !!currentFilters.get(filter),
          },
        });
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { trackEvent, currentFilters, showMobileFilters } = this.props;
    if (!prevProps.showMobileFilters && showMobileFilters) {
      Object.keys(FILTERS).forEach((filter, i) => {
        trackEvent({
          ...SEARCH_FILTER_IMPRESSION,
          position: `${i}`,
          properties: {
            selected: !!currentFilters.get(filter),
          },
        });
      });
    }
  }

  get availableFilters() {
    const { locations } = this.props;
    return locations.reduce((tierAmenities, location) => (
      tierAmenities.merge(location.tierAmenities)
    ), OrderedSet());
  }

  onFilter(filterName) {
    const { onFilter, trackEvent, currentFilters } = this.props;
    onFilter(filterName);

    const position = `${Object.keys(FILTERS).indexOf(filterName)}`;
    trackEvent({
      ...SEARCH_FILTER_CLICK,
      name: filterName,
      position,
      properties: {
        selected: !currentFilters.get(filterName),
      },
    });
  }

  renderParkingFilter(filterName) {
    const { currentFilters } = this.props;
    const { availableFilters } = this;
    const available = !!availableFilters.get(filterName);
    let onFilter = () => {};
    if (available) {
      onFilter = () => { this.onFilter(filterName); };
    }

    const filterClasses = cx({
      'col-xs-6': true,
      'col-sm-3': true,
      'noselect': true,
      'clickable': available,
      'opacity-70': !available,
      'margin-vertical-10': true,
    });

    const checkboxClasses = cx({
      'filter-checkbox': true,
      'checkbox': true,
      'selected': currentFilters.get(filterName),
      'nonclickable': !available,
    });

    const amenityIcon = `iconified-amenity-${kebabCase(filterName)}`;

    return (
      <div
        className={filterClasses}
        onClick={onFilter}
        key={`parking-filter-${kebabCase(filterName)}`}
      >
        <span className={checkboxClasses} />
        <span className={`iconified-font ${amenityIcon} iconified-inline-block text-size-18 margin-horizontal-10 vertical-align-top`} />
        <span className="vertical-align-middle">{filterName}</span>
      </div>
    );
  }

  render() {
    const filterClasses = cx({
      'padding-horizontal-xs-15': true,
      'text-size-12': true,
      'text-weight-book': true,
      'text-color-dark-slate': true,
      'border-style-xs-solid': true,
      'border-style-sm-none': true,
      'border-side-top': true,
      'border-color-light-grey': true,
      'hidden-xs': !this.props.showMobileFilters,
    });
    return (
      <div className={filterClasses}>
        <div className="row padding-vertical-sm-20 padding-vertical-10">
          {Object.keys(FILTERS).map(filter => (this.renderParkingFilter(filter)))}
        </div>
      </div>
    );
  }
}

ParkingFilters.propTypes = propTypes;
ParkingFilters.defaultProps = defaultProps;
export default ParkingFilters;
