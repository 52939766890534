import React from 'react';
import PropTypes from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor';
import LazyLoad from 'react-lazy-load';

import FriendlyLocationDistance from 'components/location/friendly-distance';
import NonBookableNotice from 'components/search/list/non-bookable-notice';
import NonBookableDirections from 'components/search/list/non-bookable-directions';

import { Location } from 'models/locations';
import Quote from 'models/quote';

const propTypes = {
  location: PropTypes.instanceOf(Location).isRequired,
  quote: PropTypes.instanceOf(Quote).isRequired,
  thumb: PropTypes.string.isRequired,
  /* eslint-disable react/forbid-prop-types */
  formattedPrice: PropTypes.array.isRequired,
  /* eslint-enable */
  cls: PropTypes.string.isRequired,
  mouseenter: PropTypes.func.isRequired,
  mouseleave: PropTypes.func.isRequired,
  onVisibilityChange: PropTypes.func.isRequired,
  renderGuarantee: PropTypes.func.isRequired,
  renderMonthlyName: PropTypes.func.isRequired,
  renderAddress: PropTypes.func.isRequired,
  renderName: PropTypes.func.isRequired,
  renderPrice: PropTypes.func.isRequired,
  renderPhone: PropTypes.func.isRequired,
};

const NonBookableListing = ({
  location,
  quote,
  thumb,
  formattedPrice,
  cls,
  mouseenter,
  mouseleave,
  onVisibilityChange,
  renderGuarantee,
  renderMonthlyName,
  renderAddress,
  renderName,
  renderPrice,
  renderPhone,
}) => (
  <div
    id={`p${location.id}`}
    className={`${cls} non-bookable`}
    key={`pl${location.id}`}
    onMouseEnter={mouseenter}
    onMouseLeave={mouseleave}
  >
    <VisibilitySensor onChange={onVisibilityChange} partialVisibility>
      <div className="listing-container row">
        <div className="listing-thumb col-xs-4">
          <LazyLoad>
            <img src={thumb} alt={`${location.name} entrance`} />
          </LazyLoad>
          { quote ? renderGuarantee(quote) : null }
        </div>
        <div className="listing-details col-xs-8">
          <div className="row">
            <div className="col-xs-8">
              {renderMonthlyName()}
              {renderAddress()}
              {renderName()}
              <div className="distance">
                <FriendlyLocationDistance location={location} />
              </div>
            </div>
            <div className="col-xs-4">
              { renderPrice(formattedPrice) }
            </div>
          </div>
          <div className="listing-bottom row padding-horizontal-15">
            <NonBookableDirections location={location} />
            <div className="col-xs-12 col-gutter-0">
              { renderPhone() }
            </div>
            <div className="col-xs-12 col-gutter-0">
              <NonBookableNotice />
            </div>
          </div>
        </div>
      </div>
    </VisibilitySensor>
  </div>
);

NonBookableListing.propTypes = propTypes;
export default NonBookableListing;
