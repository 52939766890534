import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { OrderedMap } from 'immutable';
import cx from 'classnames';

import Brand from 'models/brand';
import { Location } from 'models/locations';
import Search from 'models/search';

import toggleStreetParking from 'action-creators/search/toggle-street-parking';

const propTypes = {
  brand: PropTypes.instanceOf(Brand).isRequired,
  currentSearch: PropTypes.instanceOf(Search),
  locations: PropTypes.instanceOf(OrderedMap).isRequired,
  showStreetParking: PropTypes.bool,
  selectedLocation: PropTypes.instanceOf(Location),
  toggleStreetParking: PropTypes.func.isRequired,
};

const defaultProps = {
  currentSearch: new Search(),
  showStreetParking: false,
  selectedLocation: null,
};

export class StreetParkingControls extends Component {
  renderToggle() {
    const { showStreetParking } = this.props;
    const toggleClases = cx({
      'clickable': true,
      'col-xs-12': true,
      'col-sm-5': showStreetParking,
    });
    const pillContainerClasses = cx({
      'btn-pill': true,
      'btn-pill-small': true,
      'clickable': true,
      'noselect': true,
      'text-weight-light': true,
      'text-size-12': true,
      'active': showStreetParking,
    });
    const hideClasses = cx({
      'btn-pill-choice': true,
      'selected': !showStreetParking,
    });
    const showClasses = cx({
      'btn-pill-choice': true,
      'selected': showStreetParking,
    });
    return (
      <div className={toggleClases}>
        <div className="padding-right-5 display-inline-block">
          ON-STREET
        </div>
        <div className={pillContainerClasses}>
          <a
            className={hideClasses}
            onClick={this.props.toggleStreetParking}
          >
            Hide
          </a>
          <a
            className={showClasses}
            onClick={this.props.toggleStreetParking}
          >
            Show
          </a>
        </div>
      </div>
    );
  }

  renderLegend() {
    const { showStreetParking } = this.props;
    if (!showStreetParking) { return null; }
    const legendItemClasses = 'display-inline-block padding-horizontal-10 border-radius-4 margin-right-sm-10 margin-right-xs-5 text-size-xs-12 text-size-sm-14 text-color-white text-center';
    return (
      <div className="col-sm-7 col-xs-12">
        <div className="row text-weight-book padding-top-sm-3 padding-top-xs-5">
          <div className="hidden-xs col-sm-3 text-color-dark-slate">LEGEND</div>
          <div className="col-xs-12 col-sm-9">
            <div className={`background-color-street-free ${legendItemClasses}`}>Free</div>
            <div className={`background-color-street-metered ${legendItemClasses}`}>Metered</div>
            <div className={`background-color-street-prohibited ${legendItemClasses}`}>Prohibited</div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { brand, locations, showStreetParking, selectedLocation, currentSearch } = this.props;
    const { isMonthlySearch } = currentSearch;
    if (!brand.streetParkingEligible(locations) || selectedLocation || isMonthlySearch) { return null; }
    const containerClasses = cx({
      'container-fluid': true,
      'noselect': true,
      'background-white': true,
      'border-radius-4': true,
      'padding-horizontal-xs-10': true,
      'padding-horizontal-sm-20': true,
      'padding-vertical-10': true,
      'position-absolute': true,
      'shadowed': true,
      'street-parking-controls': true,
      'open': showStreetParking,
    });

    return (
      <div className={containerClasses}>
        <div className="row">
          {this.renderToggle()}
          {this.renderLegend()}
        </div>
      </div>
    );
  }
}

StreetParkingControls.propTypes = propTypes;
StreetParkingControls.defaultProps = defaultProps;

const mapStateToProps = (state) => {
  const {
    brand,
  } = state.brand;

  const {
    locations,
    showStreetParking,
    selectedLocation,
    currentSearch,
  } = state.search;

  return {
    brand,
    locations,
    showStreetParking,
    selectedLocation,
    currentSearch,
  };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    toggleStreetParking,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(StreetParkingControls);
