import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import SoldoutCar from 'components/svgs/icons/soldout-car';
import BookableAirportListing from 'components/search/list/airport-listing/bookable';
import NonBookableAirportListing from 'components/search/list/airport-listing/non-bookable';
import CurrencySymbol from 'components/common/currency-symbol';
import StartingAtLabel from 'components/common/starting-at-label';

import { Location } from 'models/locations';
import Quote from 'models/quote';
import Search from 'models/search';
import Brand from 'models/brand';
import Venue from 'models/venue';

import { BOOK_NOW_CLICK, BOOK_NOW_IMPRESSION, MAP_LOCATION_LISTING_IMPRESSION } from 'lib/analytics/events';
import * as AppContext from 'lib/app-context';

const propTypes = {
  location: PropTypes.instanceOf(Location).isRequired,
  highlightedLocation: PropTypes.instanceOf(Location),
  quote: PropTypes.instanceOf(Quote),
  brand: PropTypes.instanceOf(Brand).isRequired,
  mouseenter: PropTypes.func,
  mouseleave: PropTypes.func,
  touchstart: PropTypes.func,
  touchmove: PropTypes.func,
  touchend: PropTypes.func,
  touchcancel: PropTypes.func,
  booktouchstart: PropTypes.func,
  click: PropTypes.func,
  changeLocation: PropTypes.func,
  scrollToTop: PropTypes.func,
  currentSearch: PropTypes.instanceOf(Search).isRequired,
  trackEvent: PropTypes.func.isRequired,
  appContext: PropTypes.string.isRequired,
  displayMap: PropTypes.bool.isRequired,
  position: PropTypes.string,
  scrolledLocation: PropTypes.instanceOf(Location),
  unsetScrolledLocation: PropTypes.func.isRequired,
  list: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
  routerLocation: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  venue: PropTypes.instanceOf(Venue),
  monthlyAvailable: PropTypes.bool,
};

const defaultProps = {
  highlightedLocation: null,
  mouseenter: () => {},
  mouseleave: () => {},
  touchstart: () => {},
  touchmove: () => {},
  touchend: () => {},
  touchcancel: () => {},
  booktouchstart: () => {},
  click: () => {},
  changeLocation: () => {},
  scrollToTop: () => {},
  position: '0',
  quote: null,
  scrolledLocation: null,
  list: null,
  venue: null,
  monthlyAvailable: false,
};

class AirportListing extends Component {
  constructor(props, context) {
    super(props);
    this.mouseenter = this.props.mouseenter.bind(this);
    this.mouseleave = this.props.mouseleave.bind(this);
    this.touchstart = this.props.touchstart.bind(this);
    this.booktouchstart = this.props.booktouchstart.bind(this);
    this.touchmove = this.props.touchmove.bind(this);
    this.touchend = this.props.touchend.bind(this);
    this.click = this.props.click.bind(this);
    this.touchcancel = this.props.touchcancel.bind(this);
    this.changeLocation = this.changeLocation.bind(this);
    this.scrollToTop = this.props.scrollToTop;
    this.onBookNow = this.onBookNow.bind(this);
    this.onVisibilityChange = this.onVisibilityChange.bind(this);
    this.renderParkHereButton = this.renderParkHereButton.bind(this);
    this.renderPrice = this.renderPrice.bind(this);

    this.context = context;
  }

  componentWillMount() {
    this.setState({ hover: false });
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.highlightedLocation && !nextProps.highlightedLocation.equals(this.props.location)) {
      return false;
    }
    return true;
  }

  componentDidUpdate() {
    const { scrolledLocation, location, list, unsetScrolledLocation, appContext } = this.props;
    const { listing } = this;
    if (location.equals(scrolledLocation)) {
      if (appContext === AppContext.DESKTOP) {
        list.scrollTo({ top: listing.offsetTop, behavior: 'smooth' });
      } else {
        window.scrollTo({ top: listing.offsetTop });
      }
      unsetScrolledLocation();
    }
  }

  changeLocation(e) {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    const { location, quote } = this.props;
    this.props.changeLocation({ locationId: location.id, quoteId: (quote ? quote.id : null) });
  }

  onBookNow(e) {
    e.stopPropagation();
    e.preventDefault();
    const { location, quote, position, trackEvent } = this.props;
    const price = quote ? quote.price : null;

    trackEvent({
      ...BOOK_NOW_CLICK,
      position,
      properties: {
        LocationID: location.id,
        price,
      },
    });

    // this.props.changeLocation({
    //   locationId: this.props.location.id,
    //   quoteId: this.props.quote.id,
    //   venue: currentSearch.destination,
    // });
  }

  onVisibilityChange(isVisible) {
    const { trackEvent, position, appContext, displayMap, quote, location } = this.props;
    const price = quote ? quote.price : null;

    if (isVisible && (appContext === 'desktop' || !displayMap)) {
      trackEvent({
        ...MAP_LOCATION_LISTING_IMPRESSION,
        name: 'AirportCard',
        position,
        properties: {
          LocationID: location.id,
          price,
        },
      });
      trackEvent({
        ...BOOK_NOW_IMPRESSION,
        position,
        properties: {
          LocationID: location.id,
          price,
        },
      });
    }
  }

  renderParkHereButton(showUrl = true, soldOut = false) {
    const buttonText = this.props.monthlyAvailable ? 'BOOK HERE' : 'PARK HERE';
    if (showUrl && !soldOut) {
      return (
        <div
          onClick={this.onBookNow}
          onTouchEnd={this.touchend}
          onTouchStart={this.booktouchstart}
          onTouchMove={this.touchmove}
        >
          <div
            // ref={(book) => { this.bookListing = book; }}
            // onClick={this.onBookNow}
            // onTouchEnd={this.touchend}
            // onTouchStart={this.booktouchstart}
            // onTouchMove={this.touchmove}
            className={`pw-btn ${this.props.brand.isBestParking ? 'pw-btn-bp-blue' : 'pw-btn-green'} text-weight-book text-size-sm-14 text-size-xs-12 padding-horizontal-sm-20 padding-horizontal-xs-15 padding-vertical-10 text-align-center`}
          >
            {buttonText}
          </div>
        </div>
      );
    } else if (soldOut) {
      return (
        <div className="booking-button-wrapper">
          <span className="btn btn-primary btn-disabled sold-out">
            <span className="isvg loaded">
              <SoldoutCar />
            </span>
            <span className="btn-text">Sorry Sold Out</span>
          </span>
        </div>
      );
    }
    return null;
  }

  renderPrice() {
    const { location, quote, monthlyAvailable } = this.props;
    if (!(quote && quote.price)) { return null; }

    const formattedPrice = quote.price.toString().split('.');
    return (
      <div className="col-xs-4 text-align-right col-gutter-0">
        <div className="text-color-dark-slate text-size-xs-28 text-weight-black">
          {monthlyAvailable &&
            <div className="text-size-12 text-weight-medium">
              <StartingAtLabel location={location} />
            </div>
          }
          <CurrencySymbol location={location} />
          { `${formattedPrice[0]}` }
          { formattedPrice.length === 2 ? <sup>{formattedPrice[1]}</sup> : null }
        </div>
      </div>
    );
  }

  get image() {
    const { location } = this.props;
    return location.getImage() && location.getImage().imageSizes.get('resTicket');
  }

  render() {
    const { location, quote } = this.props;
    if (!quote || quote.isSoldOut) { return null; }

    const showUrl = !!(quote && quote.price > 0);
    const isHighlighted = (location === this.props.highlightedLocation);
    const isSoldOut = (quote == null);
    const hover = (this.state.hover || isHighlighted) ? ' hover' : '';
    const soldOut = isSoldOut ? ' sold-out' : '';
    const cls = `listing ${hover} ${soldOut} display-block`;
    const shuttleClasses = cx({
      'col-xs-8': !!quote,
      'col-xs-6': !quote,
      'padding-top-20': true,
      'col-gutter-left-xs-0': true,
      'col-gutter-left-sm-15': true,
    });
    const buttonClasses = cx({
      'col-xs-4': !!quote,
      'col-xs-6': !quote,
      'col-gutter-0': true,
      'text-align-right': true,
    });

    if (quote.bookable) {
      return (<div ref={(l) => { this.listing = l; }}>
        <BookableAirportListing
          location={location}
          image={this.image}
          isSoldOut={isSoldOut}
          soldOut={soldOut}
          showUrl={showUrl}
          cls={cls}
          shuttleClasses={shuttleClasses}
          buttonClasses={buttonClasses}
          mouseenter={this.mouseenter}
          mouseleave={this.mouseleave}
          touchstart={this.touchstart}
          touchmove={this.touchmove}
          touchend={this.touchend}
          touchcancel={this.touchcancel}
          click={this.click}
          onVisibilityChange={this.onVisibilityChange}
          renderPrice={this.renderPrice}
          renderParkHereButton={this.renderParkHereButton}
          currentSearch={this.props.currentSearch}
          routerLocation={this.props.routerLocation}
          routingStyle={this.props.brand.routingStyle}
          displayMap={this.props.displayMap}
          appContext={this.props.appContext}
          venue={this.props.venue}
          monthlyAvailable={this.props.monthlyAvailable}
        />
      </div>);
    }


    return (<div ref={(l) => { this.listing = l; }}>
      <NonBookableAirportListing
        location={location}
        image={this.image}
        cls={cls}
        shuttleClasses={shuttleClasses}
        mouseenter={this.mouseenter}
        mouseleave={this.mouseleave}
        onVisibilityChange={this.onVisibilityChange}
        renderPrice={this.renderPrice}
      />
    </div>);
  }
}

AirportListing.propTypes = propTypes;
AirportListing.defaultProps = defaultProps;

export default AirportListing;
