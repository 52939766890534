import React from 'react';

export default class LocationUnavailable extends React.Component {
  render() {
    return(
      <svg width="389px" height="194px" viewBox="0 0 389 194" version="1.1">
          <defs>
              <filter x="-50%" y="-50%" width="200%" height="200%" filterUnits="objectBoundingBox" id="filter-1">
                  <feOffset dx="0" dy="4" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                  <feGaussianBlur stdDeviation="3" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                  <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
                  <feMerge>
                      <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                      <feMergeNode in="SourceGraphic"></feMergeNode>
                  </feMerge>
              </filter>
          </defs>
          <g  stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.4">
              <g id="Time_soldout" transform="translate(-56.000000, -504.000000)">
                  <g id="soldout" filter="url(#filter-1)" transform="translate(0.000000, 406.000000)">
                      <g id="Imported-Layers-Copy-4" transform="translate(62.248996, 97.000000)">
                          <path d="M23.0632427,142.653058 L23.0632427,86.4279924 C23.0632427,83.3651235 24.9123628,80.499199 28.0040812,78.7689018 L56.4142234,62.8662819 C63.954624,58.6447661 74.2012418,63.0555531 74.2012418,70.5240936 L74.2012418,141.106916" id="Fill-12" fill="#E6E6E6"></path>
                          <path d="M23.0632427,146.489637 C20.9339919,146.489637 19.2082322,144.772128 19.2082322,142.653058 L19.2082322,86.4267135 C19.2082322,81.9583778 21.7898043,77.8455651 26.113841,75.4259627 L54.5239833,59.520785 C59.6768473,56.6395142 66.3048953,56.695784 71.4063592,59.6652961 C75.6327357,62.1258221 78.0562523,66.0838928 78.0562523,70.5253724 L78.0562523,141.106916 C78.0562523,143.225987 76.3304926,144.943495 74.2012418,144.943495 C72.071991,144.943495 70.3462313,143.225987 70.3462313,141.106916 L70.3462313,70.5253724 C70.3462313,68.2745794 68.5716415,66.9061996 67.5140836,66.2897892 C65.2332024,64.9597751 61.4976973,64.4200964 58.3031786,66.2104999 L29.8943213,82.111841 C28.0310662,83.1566693 26.9182532,84.7680325 26.9182532,86.4267135 L26.9182532,142.653058 C26.9182532,144.772128 25.1924935,146.489637 23.0632427,146.489637" id="Fill-13" fill="#CCCCCC"></path>
                          <polyline id="Fill-14" fill="#F2F2F2" points="52.6851433 93.2583819 37.2651014 93.2583819 37.2651014 126.508733 53.9701468 126.508733"></polyline>
                          <path d="M53.9701468,130.345312 L37.2651014,130.345312 C35.1358506,130.345312 33.4100909,128.627804 33.4100909,126.508733 L33.4100909,93.2583819 C33.4100909,91.1393114 35.1358506,89.4218029 37.2651014,89.4218029 L52.6851433,89.4218029 C54.8143941,89.4218029 56.5401538,91.1393114 56.5401538,93.2583819 C56.5401538,95.3774523 54.8143941,97.0949609 52.6851433,97.0949609 L41.1201118,97.0949609 L41.1201118,122.672154 L53.9701468,122.672154 C56.0993976,122.672154 57.8251573,124.389663 57.8251573,126.508733 C57.8251573,128.627804 56.0993976,130.345312 53.9701468,130.345312" id="Fill-15" fill="#CCCCCC"></path>
                          <path d="M299.206639,139.74621 L299.206639,103.393345 C299.206639,100.180849 301.824191,97.5770909 305.050835,97.5770909 L356.188834,97.5770909 C359.416763,97.5770909 362.03303,100.180849 362.03303,103.393345 L362.03303,141.198994" id="Fill-16" fill="#F2F2F2"></path>
                          <path d="M362.03303,145.035573 C359.903779,145.035573 358.178019,143.318065 358.178019,141.198994 L358.178019,103.393345 C358.178019,102.301199 357.286227,101.41367 356.188834,101.41367 L305.05212,101.41367 C303.953442,101.41367 303.061649,102.301199 303.061649,103.393345 L303.061649,139.74621 C303.061649,141.86528 301.33589,143.582789 299.206639,143.582789 C297.076103,143.582789 295.351628,141.86528 295.351628,139.74621 L295.351628,103.393345 C295.351628,98.0707308 299.70265,93.7405119 305.05212,93.7405119 L356.188834,93.7405119 C361.538303,93.7405119 365.88804,98.0707308 365.88804,103.393345 L365.88804,141.198994 C365.88804,143.318065 364.163566,145.035573 362.03303,145.035573" id="Fill-17" fill="#CCCCCC"></path>
                          <polyline id="Fill-18" fill="#F2F2F2" points="156.770426 139.29733 156.770426 114.998996 219.735598 114.998996 219.735598 140.57619"></polyline>
                          <path d="M219.735598,144.412769 C217.606347,144.412769 215.880587,142.69526 215.880587,140.57619 L215.880587,118.835575 L160.625437,118.835575 L160.625437,139.29733 C160.625437,141.4164 158.899677,143.133909 156.770426,143.133909 C154.641176,143.133909 152.915416,141.4164 152.915416,139.29733 L152.915416,114.998996 C152.915416,112.879926 154.641176,111.162417 156.770426,111.162417 L219.735598,111.162417 C221.864848,111.162417 223.590608,112.879926 223.590608,114.998996 L223.590608,140.57619 C223.590608,142.69526 221.864848,144.412769 219.735598,144.412769" id="Fill-19" fill="#CCCCCC"></path>
                          <path d="M55.2076052,140.472602 L55.2076052,17.0869424 C55.2076052,11.3717185 59.1063058,6.69620759 63.8723837,6.69620759 L109.367932,6.69620759 C114.13401,6.69620759 118.033996,11.3717185 118.033996,17.0869424 L118.033996,140.472602" id="Fill-20" fill="#F2F2F2"></path>
                          <path d="M119.038012,144.309181 C116.907476,144.309181 115.183002,142.591672 115.183002,140.472602 L115.183002,17.0856635 C115.183002,13.5329914 112.979221,10.5315077 110.370664,10.5315077 L64.8763998,10.5315077 C62.2691277,10.5315077 60.0666317,13.5329914 60.0666317,17.0856635 L60.0666317,140.472602 C60.0666317,142.591672 58.340872,144.309181 56.2116212,144.309181 C54.0823704,144.309181 52.3566107,142.591672 52.3566107,140.472602 L52.3566107,17.0856635 C52.3566107,9.24113833 57.973361,2.85834973 64.8763998,2.85834973 L110.370664,2.85834973 C117.276272,2.85834973 122.893023,9.24113833 122.893023,17.0856635 L122.893023,140.472602 C122.893023,142.591672 121.167263,144.309181 119.038012,144.309181" id="Fill-21" fill="#CCCCCC"></path>
                          <path d="M208.620317,141.198994 L208.620317,84.49052 C208.620317,81.2780245 211.236585,78.6729874 214.464513,78.6729874 L256.836219,78.6729874 C260.062862,78.6729874 262.680414,76.0692291 262.680414,72.8567336 L262.680414,35.0510841 C262.680414,31.8385886 265.296682,29.2348304 268.52461,29.2348304 L309.433982,29.2348304 C312.66191,29.2348304 315.278178,31.8385886 315.278178,35.0510841 L315.278178,141.198994" id="Fill-22" fill="#E6E6E6"></path>
                          <path d="M315.279463,145.035573 C313.148927,145.035573 311.424452,143.318065 311.424452,141.198994 L311.424452,35.0498053 C311.424452,33.958938 310.531375,33.0714094 309.435267,33.0714094 L268.52461,33.0714094 C267.427217,33.0714094 266.535425,33.958938 266.535425,35.0498053 L266.535425,72.8580124 C266.535425,78.1806264 262.183118,82.5108452 256.834934,82.5108452 L214.464513,82.5108452 C213.36712,82.5108452 212.475328,83.3983738 212.475328,84.49052 L212.475328,141.198994 C212.475328,143.318065 210.749568,145.035573 208.620317,145.035573 C206.489782,145.035573 204.765307,143.318065 204.765307,141.198994 L204.765307,84.49052 C204.765307,79.1666272 209.115044,74.8376872 214.464513,74.8376872 L256.834934,74.8376872 C257.933612,74.8376872 258.825404,73.9488797 258.825404,72.8580124 L258.825404,35.0498053 C258.825404,29.7271913 263.176426,25.3982514 268.52461,25.3982514 L309.435267,25.3982514 C314.783451,25.3982514 319.134473,29.7271913 319.134473,35.0498053 L319.134473,141.198994 C319.134473,143.318065 317.408713,145.035573 315.279463,145.035573" id="Fill-23" fill="#CCCCCC"></path>
                          <path d="M93.9260455,142.653058 L93.9260455,69.9486067 C93.9260455,63.5517507 98.8681689,58.3160992 104.90897,58.3160992 L162.570932,58.3160992 C168.611734,58.3160992 173.553857,63.5517507 173.553857,69.9486067 L173.553857,142.653058" id="Fill-24" fill="#E6E6E6"></path>
                          <path d="M173.555142,146.489637 C171.424606,146.489637 169.700132,144.772128 169.700132,142.653058 L169.700132,69.9486067 C169.700132,65.6503594 166.501758,62.1526782 162.570932,62.1526782 L104.90897,62.1526782 C100.978145,62.1526782 97.781056,65.6503594 97.781056,69.9486067 L97.781056,142.653058 C97.781056,144.772128 96.0552963,146.489637 93.9260455,146.489637 C91.7967947,146.489637 90.071035,144.772128 90.071035,142.653058 L90.071035,69.9486067 C90.071035,61.4198916 96.7273531,54.4795202 104.90897,54.4795202 L162.570932,54.4795202 C170.752549,54.4795202 177.410153,61.4198916 177.410153,69.9486067 L177.410153,142.653058 C177.410153,144.772128 175.684393,146.489637 173.555142,146.489637" id="Fill-25" fill="#CCCCCC"></path>
                          <path d="M3.85501049,141.855049 L372.651014,141.855049" id="Fill-26" fill="#F2F2F2"></path>
                          <path d="M372.651014,145.691628 L3.85501049,145.691628 C1.72575969,145.691628 0,143.97412 0,141.855049 C0,139.735979 1.72575969,138.01847 3.85501049,138.01847 L372.651014,138.01847 C374.780264,138.01847 376.506024,139.735979 376.506024,141.855049 C376.506024,143.97412 374.780264,145.691628 372.651014,145.691628" id="Fill-27" fill="#CCCCCC"></path>
                          <path d="M348.235947,113.720137 L326.390888,113.720137" id="Fill-28" fill="#F2F2F2"></path>
                          <path d="M348.235947,117.556716 L326.390888,117.556716 C324.261637,117.556716 322.535877,115.839207 322.535877,113.720137 C322.535877,111.601066 324.261637,109.883558 326.390888,109.883558 L348.235947,109.883558 C350.365198,109.883558 352.090958,111.601066 352.090958,113.720137 C352.090958,115.839207 350.365198,117.556716 348.235947,117.556716" id="Fill-29" fill="#CCCCCC"></path>
                          <path d="M348.235947,127.787593 L326.390888,127.787593" id="Fill-30" fill="#F2F2F2"></path>
                          <path d="M348.235947,131.624172 L326.390888,131.624172 C324.261637,131.624172 322.535877,129.906663 322.535877,127.787593 C322.535877,125.668522 324.261637,123.951014 326.390888,123.951014 L348.235947,123.951014 C350.365198,123.951014 352.090958,125.668522 352.090958,127.787593 C352.090958,129.906663 350.365198,131.624172 348.235947,131.624172" id="Fill-31" fill="#CCCCCC"></path>
                          <polygon id="Fill-32" fill="#F2F2F2" points="222.305605 103.489259 299.405814 103.489259 299.405814 121.393295 222.305605 121.393295"></polygon>
                          <path d="M299.405814,125.229874 L222.305605,125.229874 C220.176354,125.229874 218.450594,123.512365 218.450594,121.393295 L218.450594,103.489259 C218.450594,101.370189 220.176354,99.6526802 222.305605,99.6526802 L299.405814,99.6526802 C301.535065,99.6526802 303.260825,101.370189 303.260825,103.489259 L303.260825,121.393295 C303.260825,123.512365 301.535065,125.229874 299.405814,125.229874 L299.405814,125.229874 Z M226.160615,117.556716 L295.550804,117.556716 L295.550804,107.325838 L226.160615,107.325838 L226.160615,117.556716 Z" id="Fill-33" fill="#CCCCCC"></path>
                          <path d="M299.405814,65.1234692 L274.990748,65.1234692" id="Fill-34" fill="#E6E6E6"></path>
                          <path d="M299.405814,68.9600482 L274.990748,68.9600482 C272.861497,68.9600482 271.135737,67.2425397 271.135737,65.1234692 C271.135737,63.0043987 272.861497,61.2868902 274.990748,61.2868902 L299.405814,61.2868902 C301.535065,61.2868902 303.260825,63.0043987 303.260825,65.1234692 C303.260825,67.2425397 301.535065,68.9600482 299.405814,68.9600482" id="Fill-35" fill="#CCCCCC"></path>
                          <path d="M299.405814,45.9405742 L274.990748,45.9405742" id="Fill-36" fill="#E6E6E6"></path>
                          <path d="M299.405814,49.7771532 L274.990748,49.7771532 C272.861497,49.7771532 271.135737,48.0596446 271.135737,45.9405742 C271.135737,43.8215037 272.861497,42.1039952 274.990748,42.1039952 L299.405814,42.1039952 C301.535065,42.1039952 303.260825,43.8215037 303.260825,45.9405742 C303.260825,48.0596446 301.535065,49.7771532 299.405814,49.7771532" id="Fill-37" fill="#CCCCCC"></path>
                          <path d="M69.3901887,24.1999598 L102.80028,24.1999598" id="Fill-38" fill="#E6E6E6"></path>
                          <path d="M102.80028,28.0365388 L69.3901887,28.0365388 C67.2609379,28.0365388 65.5351783,26.3190303 65.5351783,24.1999598 C65.5351783,22.0808894 67.2609379,20.3633808 69.3901887,20.3633808 L102.80028,20.3633808 C104.92953,20.3633808 106.65529,22.0808894 106.65529,24.1999598 C106.65529,26.3190303 104.92953,28.0365388 102.80028,28.0365388" id="Fill-39" fill="#CCCCCC"></path>
                          <path d="M69.3901887,42.1039952 L102.80028,42.1039952" id="Fill-40" fill="#E6E6E6"></path>
                          <path d="M102.80028,45.9405742 L69.3901887,45.9405742 C67.2609379,45.9405742 65.5351783,44.2230656 65.5351783,42.1039952 C65.5351783,39.9849247 67.2609379,38.2674162 69.3901887,38.2674162 L102.80028,38.2674162 C104.92953,38.2674162 106.65529,39.9849247 106.65529,42.1039952 C106.65529,44.2230656 104.92953,45.9405742 102.80028,45.9405742" id="Fill-41" fill="#CCCCCC"></path>
                          <polygon id="Fill-42" fill="#F2F2F2" points="109.266417 73.5844047 157.482318 73.5844047 157.482318 103.393345 109.266417 103.393345"></polygon>
                          <path d="M157.482318,107.229924 L109.266417,107.229924 C107.137166,107.229924 105.411407,105.512415 105.411407,103.393345 L105.411407,73.5844047 C105.411407,71.4653343 107.137166,69.7478257 109.266417,69.7478257 L157.482318,69.7478257 C159.611569,69.7478257 161.337329,71.4653343 161.337329,73.5844047 L161.337329,103.393345 C161.337329,105.512415 159.611569,107.229924 157.482318,107.229924 L157.482318,107.229924 Z M113.121428,99.5567657 L153.627308,99.5567657 L153.627308,77.4209837 L113.121428,77.4209837 L113.121428,99.5567657 Z" id="Fill-43" fill="#CCCCCC"></path>
                          <path d="M190.180517,114.998996 L190.180517,140.57619" id="Fill-44" fill="#F2F2F2"></path>
                          <path d="M190.180517,144.412769 C188.051266,144.412769 186.325507,142.69526 186.325507,140.57619 L186.325507,114.998996 C186.325507,112.879926 188.051266,111.162417 190.180517,111.162417 C192.309768,111.162417 194.035528,112.879926 194.035528,114.998996 L194.035528,140.57619 C194.035528,142.69526 192.309768,144.412769 190.180517,144.412769" id="Fill-45" fill="#CCCCCC"></path>
                          <g id="zag" transform="translate(53.212851, 0.500000)">
                              <g transform="translate(55.245330, 0.018218)">
                                  <path d="M32.2382711,90.1252252 C34.6772022,84.8647498 38.4613734,78.4715839 43.8423336,73.8210187 C47.9983598,73.189326 53.7074273,72.6883283 60.4445645,72.328917 C67.137954,71.9586143 74.8156654,71.7625718 82.9417903,71.7625718 C99.1831031,71.7625718 113.772942,72.5685245 122.084995,73.8210187 C132.868789,83.1003667 137.177932,99.4699207 137.177932,99.4699207 L28.7493965,99.4699207 C28.7493965,99.4699207 29.8102769,95.3748094 32.2382711,90.1252252 M156.504795,99.2668404 C150.998448,94.9912694 147.153709,88.7711591 147.153709,88.7711591 C147.153709,88.7711591 135.825675,65.5695439 128.75475,64.4704564 C119.646235,63.0452661 103.163581,62.0790354 84.70398,62.0790354 C66.2807642,62.0790354 49.7859825,63.0452661 40.701724,64.4704564 C33.6065422,65.5695439 22.2906368,88.7711591 22.2906368,88.7711591 C22.2906368,88.7711591 18.4580257,94.9912694 12.9516795,99.2668404 C0.944448649,106.453181 -0.608001395,122.589234 0.45930801,136.3701 C0.240994723,139.05139 0.156095111,142.046706 0.156095111,145.343968 C0.156095111,160.851971 2.27858541,173.437126 4.87408783,173.437126 L24.2797134,173.437126 C25.541079,173.437126 26.669031,170.514278 27.5301557,165.76767 C39.3312017,166.613122 57.1358632,167.011692 84.70398,167.011692 C98.5183597,167.011692 109.882779,166.890913 119.270251,166.697667 C128.609208,166.492343 136.019731,166.190396 141.902061,165.76767 C142.763186,170.514278 143.903266,173.437126 145.164632,173.437126 L164.570258,173.437126 C167.177889,173.437126 169.300379,160.851971 169.300379,145.343968 C169.300379,142.046706 169.191222,139.05139 168.997166,136.3701 C170.064475,122.589234 168.512025,106.453181 156.504795,99.2668404 Z" id="Fill-8" fill="#CCCCCC"></path>
                                  <g id="Group-2" transform="translate(100.415642, 96.140350) rotate(45.000000) translate(-100.415642, -96.140350) translate(43.186726, 17.640350)">
                                      <path d="M15.5664144,153.507688 C15.5664144,155.053077 16.8239836,156.305616 18.3755785,156.305616 C19.9271735,156.305616 21.1847427,155.053077 21.1847427,153.507688 L21.1847427,149.777118 L24.9302948,149.777118 C26.4818898,149.777118 27.7394589,148.52458 27.7394589,146.979191 C27.7394589,145.433802 26.4818898,144.181264 24.9302948,144.181264 L21.1847427,144.181264 L21.1847427,140.450694 C21.1847427,138.905305 19.9271735,137.652766 18.3755785,137.652766 C16.8239836,137.652766 15.5664144,138.905305 15.5664144,140.450694 L15.5664144,144.181264 L11.8208623,144.181264 C10.2692673,144.181264 9.01169815,145.433802 9.01169815,146.979191 C9.01169815,148.52458 10.2692673,149.777118 11.8208623,149.777118 L15.5664144,149.777118 L15.5664144,153.507688 Z" id="Combined-Shape-Copy" fill="#FFFFFF"></path>
                                      <path d="M6.55471627,15.8549223 C6.55471627,17.4003109 7.81228541,18.6528497 9.36388039,18.6528497 C10.9154754,18.6528497 12.1730445,17.4003109 12.1730445,15.8549223 L12.1730445,12.1243523 L15.9185967,12.1243523 C17.4701916,12.1243523 18.7277608,10.8718135 18.7277608,9.32642487 C18.7277608,7.78103627 17.4701916,6.52849741 15.9185967,6.52849741 L12.1730445,6.52849741 L12.1730445,2.79792746 C12.1730445,1.25253886 10.9154754,2.27373675e-13 9.36388039,2.27373675e-13 C7.81228541,2.27373675e-13 6.55471627,1.25253886 6.55471627,2.79792746 L6.55471627,6.52849741 L2.80916412,6.52849741 C1.25756914,6.52849741 4.26325641e-14,7.78103627 4.26325641e-14,9.32642487 C4.26325641e-14,10.8718135 1.25756914,12.1243523 2.80916412,12.1243523 L6.55471627,12.1243523 L6.55471627,15.8549223 Z" id="Combined-Shape-Copy-3" fillOpacity="0.5" fill="#CCCCCC"></path>
                                      <path d="M102.179896,63.5100911 L102.179896,67.2406611 C102.179896,68.7860497 103.437465,70.0385885 104.98906,70.0385885 C106.540655,70.0385885 107.798224,68.7860497 107.798224,67.2406611 L107.798224,63.5100911 L111.543776,63.5100911 C113.095371,63.5100911 114.35294,62.2575523 114.35294,60.7121637 C114.35294,59.1667751 113.095371,57.9142362 111.543776,57.9142362 L107.798224,57.9142362 L107.798224,54.1836663 C107.798224,52.6382777 106.540655,51.3857388 104.98906,51.3857388 C103.437465,51.3857388 102.179896,52.6382777 102.179896,54.1836663 L102.179896,57.9142362 L98.4343435,57.9142362 C96.8827485,57.9142362 95.6251794,59.1667751 95.6251794,60.7121637 C95.6251794,62.2575523 96.8827485,63.5100911 98.4343435,63.5100911 L102.179896,63.5100911 L102.179896,63.5100911 Z" id="Combined-Shape-Copy-2" fill="#FFFFFF"></path>
                                  </g>
                                  <path d="M111.670805,156.99039 L58.3765149,156.99039 C58.3765149,156.99039 42.2700588,158.131846 42.2700588,142.645704 L129.041671,142.645704 C129.041671,156.6962 111.670805,156.99039 111.670805,156.99039" id="Path" fill="#FFFFFF" transform="translate(85.655865, 149.827468) scale(1, -1) translate(-85.655865, -149.827468) "></path>
                              </g>
                              <path d="M23.4855714,77.4076105 C23.4855714,78.9529991 24.7431405,80.205538 26.2947355,80.205538 C27.8463305,80.205538 29.1038996,78.9529991 29.1038996,77.4076105 L29.1038996,73.6770405 L32.8494518,73.6770405 C34.4010467,73.6770405 35.6586159,72.4245017 35.6586159,70.8791131 C35.6586159,69.3337245 34.4010467,68.0811856 32.8494518,68.0811856 L29.1038996,68.0811856 L29.1038996,64.3506157 C29.1038996,62.8052271 27.8463305,61.5526882 26.2947355,61.5526882 C24.7431405,61.5526882 23.4855714,62.8052271 23.4855714,64.3506157 L23.4855714,68.0811856 L19.7400192,68.0811856 C18.1884242,68.0811856 16.9308551,69.3337245 16.9308551,70.8791131 C16.9308551,72.4245017 18.1884242,73.6770405 19.7400192,73.6770405 L23.4855714,73.6770405 L23.4855714,77.4076105 Z" id="Combined-Shape-Copy" fill="#CCCCCC" transform="translate(26.294735, 70.879113) rotate(45.000000) translate(-26.294735, -70.879113) "></path>
                              <path d="M251.397218,78.7362723 C251.397218,80.2816609 252.654787,81.5341998 254.206382,81.5341998 C255.757977,81.5341998 257.015546,80.2816609 257.015546,78.7362723 L257.015546,75.0057024 L260.761098,75.0057024 C262.312693,75.0057024 263.570262,73.7531635 263.570262,72.2077749 C263.570262,70.6623863 262.312693,69.4098474 260.761098,69.4098474 L257.015546,69.4098474 L257.015546,65.6792775 C257.015546,64.1338889 255.757977,62.88135 254.206382,62.88135 C252.654787,62.88135 251.397218,64.1338889 251.397218,65.6792775 L251.397218,69.4098474 L247.651666,69.4098474 C246.100071,69.4098474 244.842502,70.6623863 244.842502,72.2077749 C244.842502,73.7531635 246.100071,75.0057024 247.651666,75.0057024 L251.397218,75.0057024 L251.397218,78.7362723 Z" id="Combined-Shape-Copy-5" fillOpacity="0.4" fill="#CCCCCC" transform="translate(254.206382, 72.207775) rotate(45.000000) translate(-254.206382, -72.207775) "></path>
                              <path d="M10.4333625,185.357159 C10.4333625,186.902548 11.6909317,188.155087 13.2425266,188.155087 C14.7941216,188.155087 16.0516908,186.902548 16.0516908,185.357159 L16.0516908,181.626589 L19.7972429,181.626589 C21.3488379,181.626589 22.606407,180.37405 22.606407,178.828662 C22.606407,177.283273 21.3488379,176.030734 19.7972429,176.030734 L16.0516908,176.030734 L16.0516908,172.300164 C16.0516908,170.754776 14.7941216,169.502237 13.2425266,169.502237 C11.6909317,169.502237 10.4333625,170.754776 10.4333625,172.300164 L10.4333625,176.030734 L6.68781037,176.030734 C5.13621539,176.030734 3.87864625,177.283273 3.87864625,178.828662 C3.87864625,180.37405 5.13621539,181.626589 6.68781037,181.626589 L10.4333625,181.626589 L10.4333625,185.357159 Z" id="Combined-Shape-Copy-6" fillOpacity="0.2" fill="#CCCCCC" transform="translate(13.242527, 178.828662) rotate(45.000000) translate(-13.242527, -178.828662) "></path>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </svg>
    );
  }
}