import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { OrderedMap } from 'immutable';

import { Location as LocationModel } from 'models/locations';
import SearchModel from 'models/search';

const propTypes = {
  toggleMap: PropTypes.func.isRequired,
  changeSelectedLocation: PropTypes.func.isRequired,
  selectedLocation: PropTypes.instanceOf(LocationModel),
  currentSearch: PropTypes.instanceOf(SearchModel).isRequired,
  events: PropTypes.instanceOf(OrderedMap),
};

const defaultProps = {
  selectedLocation: null,
  events: OrderedMap(),
};

class ToggleBar extends Component {
  constructor(props) {
    super(props);

    this.exitLocation = this.exitLocation.bind(this);
    this.toggleMap = this.toggleMap.bind(this);
    this.allEvents = this.allEvents.bind(this);
    this.showEvents = this.showEvents.bind(this);
  }

  showEvents() {
    const { currentSearch, selectedLocation, events } = this.props;
    const { isVenueSearch } = currentSearch;

    return (events && events.size > 0 && isVenueSearch && !selectedLocation);
  }

  toggleMap(e) {
    e.preventDefault();
    e.stopPropagation();

    window.scrollTo(0, 0);
    this.props.toggleMap();
  }

  exitLocation(e) {
    e.preventDefault();

    window.scrollTo(0, 0);
    this.props.changeSelectedLocation({ displayMap: false });
  }

  allEvents(e) {
    e.preventDefault();

    window.scrollTo(0, 0);
    window.location = this.props.currentSearch.destination.url;
  }

  renderListViewButton() {
    if (!this.props.selectedLocation) { return null; }
    return (
      <div className="col-xs-6 border-style-solid border-color-light-grey border-side-left clickable" onClick={this.exitLocation}>
        <a className="larger-hit-area">
          <span className="iconified-font iconified-list iconified-space-right" />
          LIST VIEW
        </a>
      </div>
    );
  }

  renderAllEventsButton() {
    if (!this.showEvents()) { return null; }

    return (
      <div className="col-xs-6 border-style-solid border-color-light-grey border-side-left clickable" onClick={this.allEvents}>
        <a className="larger-hit-area">
          <span className="iconified-font iconified-calendar iconified-space-right" />
          ALL EVENTS
        </a>
      </div>
    );
  }

  render() {
    const mapToggleColumnClasses = cx({
      'col-xs-12': !this.showEvents(),
      'col-xs-6': this.showEvents() || this.props.selectedLocation,
      'clickable': true,
      'text-center': true,
    });
    return (
      <div className="visible-xs visible-sm row text-center text-size-10 shadowed">
        <div className={mapToggleColumnClasses} onClick={this.toggleMap}>
          <a className="larger-hit-area">
            <span className="iconified-font iconified-list iconified-space-right" />
            { this.props.selectedLocation ? 'DETAILS' : 'LIST VIEW' }
          </a>
        </div>
        { this.renderListViewButton() }
        { this.renderAllEventsButton() }
      </div>
    );
  }
}

ToggleBar.propTypes = propTypes;
ToggleBar.defaultProps = defaultProps;

export default ToggleBar;
