import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import get from 'lodash/get';

import Coordinates from 'models/coordinates';
import SearchModel from 'models/search';
import Brand from 'models/brand';

import MapboxAutocomplete from 'containers/mapbox/autocomplete';
import DateTimeSelector from 'components/search/time-date-picker/date-time-selector';

const propTypes = {
  geoIPLocation: PropTypes.instanceOf(Coordinates).isRequired,
  timezone: PropTypes.string.isRequired,
  currentSearch: PropTypes.instanceOf(SearchModel).isRequired,
  timesChange: PropTypes.func.isRequired,
  changeMonthlyStart: PropTypes.func.isRequired,
  monthlyStart: PropTypes.instanceOf(moment).isRequired,
  onSearch: PropTypes.func.isRequired,
  dismissTimePickerPrompt: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired,
  app: PropTypes.string.isRequired,
  appContext: PropTypes.string.isRequired,
  brand: PropTypes.instanceOf(Brand).isRequired,
};

class TimePickerBar extends React.Component {
  constructor(props) {
    super(props);


    this.startDateChange = this.startDateChange.bind(this);
    this.startTimeChange = this.startTimeChange.bind(this);
    this.endDateChange = this.endDateChange.bind(this);
    this.endTimeChange = this.endTimeChange.bind(this);
    this.updateMonthlyStartTime = this.updateMonthlyStartTime.bind(this);
    this.renderArrow = this.renderArrow.bind(this);
    this.renderEndTimeSelector = this.renderEndTimeSelector.bind(this);

    this.state = {
      monthly: this.props.currentSearch.parkingType === 'Monthly',
      startTime: this.props.currentSearch.startTime,
      endTime: this.props.currentSearch.endTime,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      startTime: nextProps.currentSearch.startTime,
      endTime: nextProps.currentSearch.endTime,
      monthly: nextProps.currentSearch.parkingType === 'Monthly',
    });
  }


  startDateChange(value) {
    const date = moment(this.state.startTime);
    value.hour(date.hours());
    value.minute(date.minutes());
    this.props.dismissTimePickerPrompt();
    this.props.timesChange({ startTime: value });
  }

  updateMonthlyStartTime(monthlyStart) {
    this.props.changeMonthlyStart({ monthlyStart });
  }

  startTimeChange(input) {
    const date = moment(this.state.startTime);
    date.hour(input.hour);
    date.minute(input.minute);
    this.props.dismissTimePickerPrompt();
    this.props.timesChange({ startTime: date });
  }

  endDateChange(value) {
    const date = moment(this.state.endTime);
    value.hour(date.hours());
    value.minute(date.minutes());
    this.props.dismissTimePickerPrompt();
    this.props.timesChange({ endTime: value });
  }

  endTimeChange(value) {
    const date = moment(this.state.endTime);
    date.hour(value.hour);
    date.minute(value.minute);
    this.props.dismissTimePickerPrompt();
    this.props.timesChange({ endTime: date });
  }

  renderArrow() {
    const { currentSearch } = this.props;

    if (currentSearch.isMonthlySearch) { return null; }

    return (
      <div className="col-md-2 text-size-24 padding-top-20 col-gutter-left-0 text-align-center hidden-md">
        <span className="iconified-font iconified-thin-arrow-right text-size-16" />
      </div>
    );
  }

  renderEndTimeSelector() {
    if (this.props.currentSearch.isMonthlySearch) { return null; }

    return (
      <DateTimeSelector
        timezone={this.props.timezone}
        dateChange={this.endDateChange}
        timeChange={this.endTimeChange}
        field={'endTime'}
        label={'EXIT BEFORE'}
        time={this.state.endTime}
        trackEvent={this.props.trackEvent}
        currentSearch={this.props.currentSearch}
        app={this.props.app}
      />
    );
  }

  render() {
    const { geoIPLocation, timezone, currentSearch, brand } = this.props;
    if (!currentSearch) { return null; }
    const { lat, lng } = get(currentSearch, 'destination', {});

    let additionalQueryParams = {};
    if (this.state.monthly) {
      additionalQueryParams = {
        monthly: 1,
      };
    }

    return (
      <div className={`horizontal-time-picker hidden-xs hidden-sm ${brand.isBestParking ? 'background-color-bp-dark-blue' : 'background-color-dark-slate'} text-color-white heavy-shadowed`}>
        <div className="container margin-horizontal-0">
          <div className="row">
            <div className="col-xs-4">
              <form role="form" className="navbar-form navbar-left navbar-search" action="/search/" method="get">
                <fieldset>
                  <div className="form-group">
                    <div className="input-group input-group-sm">
                      <MapboxAutocomplete
                        buttonClassName="search-button background-color-green"
                        buttonText="Find Parking"
                        dropdownClassName="location-suggestion-bar-navbar"
                        inputClassName="search-input form-control gplaces-input-dropdown"
                        geoIPLocation={geoIPLocation}
                        mapPin
                        pinClassName="text-color-watermelon"
                        placeholder="Address, Place or Event"
                        onSearch={this.props.onSearch}
                        currentDestination={{ lat, lng }}
                        currentSearch={currentSearch}
                        app="Search"
                        trackEvent={this.props.trackEvent}
                        id="search-autocomplete"
                        daily
                      />
                    </div>
                  </div>
                </fieldset>
              </form>
            </div>
            <div className="col-xs-6">
              <DateTimeSelector
                timezone={timezone}
                dateChange={currentSearch.isMonthlySearch ? this.updateMonthlyStartTime : this.startDateChange}
                timeChange={this.startTimeChange}
                field={'startTime'}
                label={currentSearch.isMonthlySearch ? 'START DATE' : 'ARRIVE AFTER'}
                time={currentSearch.isMonthlySearch ? this.props.monthlyStart : this.state.startTime}
                displayTimes={!currentSearch.isMonthlySearch}
                hideOnDateChange={currentSearch.isMonthlySearch}
                trackEvent={this.props.trackEvent}
                currentSearch={this.props.currentSearch}
                app={this.props.app}
              />
              { this.renderArrow() }
              { this.renderEndTimeSelector() }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TimePickerBar.propTypes = propTypes;
export default TimePickerBar;
