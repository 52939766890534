import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import ShuttleTime from 'models/shuttle-times';

import ActiveTimeRange from 'components/common/shuttle-time-information/active-time-range';
import HumanizedTimeRange from 'components/common/shuttle-time-information/humanized-time-range';

const propTypes = {
  shuttleTimesToVenue: PropTypes.arrayOf(PropTypes.instanceOf(ShuttleTime)),
  shuttleTimesFromVenue: PropTypes.arrayOf(PropTypes.instanceOf(ShuttleTime)),
  wrapperClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  subheaderClassName: PropTypes.string,
  rowClassName: PropTypes.string,
};

const defaultProps = {
  shuttleTimesToVenue: [],
  shuttleTimesFromVenue: [],
  wrapperClassName: '',
  headerClassName: '',
  subheaderClassName: '',
  rowClassName: '',
};

class ShuttleTimeInformation extends Component {
  printableRows(shuttleTimes) {
    const rows = [];
    shuttleTimes.forEach((shuttleTime) => {
      rows.push(this.printableRow(shuttleTime));
    });
    return rows;
  }

  printableRow(shuttleTime) {
    return (
      <div key={`${shuttleTime.startTime.format()}-${shuttleTime.endTime.format()}`} className={this.props.rowClassName}>
        <div className="col-md-2">
          <ActiveTimeRange shuttleTime={shuttleTime} formatStyle="day" />
        </div>
        <div className="col-md-3">
          <ActiveTimeRange shuttleTime={shuttleTime} formatStyle="time" />
        </div>
        { this.frequencyText(shuttleTime) &&
          <div className="col-md-3">
            { this.frequencyText(shuttleTime) }
          </div>
        }
        <div className="col-md-4">
          <FormattedMessage
            id="common.shuttle-duration"
            defaultMessage="{duration} shuttle ride"
            values={{
              duration: <HumanizedTimeRange start={shuttleTime.minTravelTime} end={shuttleTime.maxTravelTime} />,
            }}
          />
        </div>
      </div>
    );
  }

  frequencyText(shuttleTime) {
    if (!shuttleTime.onDemand && !shuttleTime.minFrequency) { return null; }

    if (shuttleTime.onDemand && shuttleTime.minFrequency) {
      return (<FormattedMessage
        id="common.shuttle-frequency-and-demand"
        defaultMessage="Every {frequency}, and on demand"
        values={{
          frequency: <HumanizedTimeRange start={shuttleTime.minFrequency} end={shuttleTime.maxFrequency} pluralize />,
        }}
      />);
    } else if (shuttleTime.onDemand) {
      return (<FormattedMessage
        id="common.on-demand"
        defaultMessage="On demand"
      />);
    }

    return (<FormattedMessage
      id="common.shuttle-frequency"
      defaultMessage="Every {frequency}"
      values={{
        frequency: <HumanizedTimeRange start={shuttleTime.minFrequency} end={shuttleTime.maxFrequency} pluralize />,
      }}
    />);
  }

  onDemandText(shuttleTimes) {
    let onDemandShuttle;

    for (let i = 0; i < shuttleTimes.length; i++) {
      if (shuttleTimes[i].onDemand) {
        onDemandShuttle = shuttleTimes[i];
        break;
      }
    }

    const onDemandPhone = onDemandShuttle && onDemandShuttle.onDemandPhone;
    if (onDemandPhone && onDemandPhone !== '') {
      return (
        <div className={this.props.rowClassName}>
          <div className="col-md-12">
            <FormattedMessage
              id="common.on-demand-phone"
              defaultMessage="For on demand service call: {phone}"
              values={{ phone: onDemandShuttle.onDemandPhone }}
            />
          </div>
        </div>
      );
    }

    return null;
  }

  render() {
    if (this.props.shuttleTimesToVenue.length === 0 && this.props.shuttleTimesFromVenue.length === 0) { return null; }

    return (
      <div className="row">
        <div className={this.props.wrapperClassName}>
          <div className={this.props.headerClassName}>
            <FormattedMessage id="common.shuttle-details" defaultMessage="Shuttle Details" />
          </div>
          { this.props.shuttleTimesToVenue.length > 0 &&
            <div>
              <div className={this.props.subheaderClassName}>
                <FormattedMessage id="common.to-airport" defaultMessage="To Airport" />
              </div>
              { this.printableRows(this.props.shuttleTimesToVenue) }
              { this.onDemandText(this.props.shuttleTimesToVenue) }
            </div>
          }

          { this.props.shuttleTimesFromVenue.length > 0 &&
            <div>
              <div className={this.props.subheaderClassName}>
                <FormattedMessage id="common.from-airport" defaultMessage="From Airport" />
              </div>
              { this.printableRows(this.props.shuttleTimesFromVenue) }
              { this.onDemandText(this.props.shuttleTimesFromVenue) }
            </div>
          }
        </div>
      </div>
    );
  }
}

ShuttleTimeInformation.propTypes = propTypes;
ShuttleTimeInformation.defaultProps = defaultProps;

export default ShuttleTimeInformation;

