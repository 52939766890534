import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { OrderedMap } from 'immutable';
import get from 'lodash/get';

import changeSelectedLocation from 'action-creators/search/change-selected-location';
import trackEventCreator from 'action-creators/analytics/track-event';

import Search from 'models/search';
import { Location } from 'models/locations';

import { MOBILE } from 'lib/app-context';

import {
  LOCATION_DETAILS_ALL_LOCATIONS_IMPRESSION,
  LOCATION_DETAILS_NEXT_LOCATION_IMPRESSION,
  LOCATION_DETAILS_PREV_LOCATION_IMPRESSION,
  LOCATION_DETAILS_ALL_LOCATIONS_CLICK,
  LOCATION_DETAILS_NEXT_LOCATION_CLICK,
  LOCATION_DETAILS_PREV_LOCATION_CLICK,
} from 'lib/analytics/events';

const propTypes = {
  locations: PropTypes.instanceOf(OrderedMap).isRequired,
  currentSearch: PropTypes.instanceOf(Search).isRequired,
  appContext: PropTypes.string.isRequired,
  changeSelectedLocation: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired,
  selectedLocation: PropTypes.instanceOf(Location),
};

const defaultProps = {
  selectedLocation: null,
};

class LocationNav extends Component {
  static getDerivedStateFromProps(nextProps) {
    return {
      bookableLocations: nextProps.locations.filter(l => l.isBookable),
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      bookableLocations: props.locations.filter(l => l.isBookable),
    };

    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.showPrevLocation = this.showPrevLocation.bind(this);
    this.showNextLocation = this.showNextLocation.bind(this);
    this.exitLocation = this.exitLocation.bind(this);
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyPress);
    const { trackEvent, currentSearch, appContext } = this.props;
    if (!get(currentSearch, ['destination', 'enhancedAirport'], false) || appContext === MOBILE) {
      trackEvent({
        ...LOCATION_DETAILS_ALL_LOCATIONS_IMPRESSION,
      });
      if (this.shouldRenderNavigation) {
        trackEvent({
          ...LOCATION_DETAILS_NEXT_LOCATION_IMPRESSION,
          properties: {
            LocationID: this.prevLocationId(),
          },
        });
        trackEvent({
          ...LOCATION_DETAILS_PREV_LOCATION_IMPRESSION,
          properties: {
            LocationID: this.nextLocationId(),
          },
        });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyPress);
  }

  get shouldRenderNavigation() {
    const { currentSearch } = this.props;
    return !currentSearch.isPackageSearch;
  }

  handleKeyPress(event) {
    window.test = this;
    if (event.keyCode === 37) {
      this.showPrevLocation(event);
    } else if (event.keyCode === 39) {
      this.showNextLocation(event);
    }
  }

  exitLocation(e) {
    e.preventDefault();

    this.props.trackEvent(LOCATION_DETAILS_ALL_LOCATIONS_CLICK);
    this.props.changeSelectedLocation();
  }

  showPrevLocation(e) {
    e.preventDefault();
    if (this.isFirstLocation()) { return; }

    const { trackEvent, currentSearch } = this.props;
    trackEvent({
      ...LOCATION_DETAILS_PREV_LOCATION_CLICK,
      properties: {
        LocationID: this.prevLocationId(),
      },
    });
    this.props.changeSelectedLocation({
      locationId: this.prevLocationId(),
    });
  }

  showNextLocation(e) {
    e.preventDefault();
    if (this.isLastLocation()) { return; }

    const { trackEvent, currentSearch } = this.props;
    trackEvent({
      ...LOCATION_DETAILS_NEXT_LOCATION_CLICK,
      properties: {
        LocationID: this.nextLocationId(),
      },
    });
    this.props.changeSelectedLocation({
      locationId: this.nextLocationId(),
    });
  }

  nextLocationId() {
    if (this.isLastLocation()) { return null; }
    return this.locationIds()[this.locationIndex() + 1];
  }

  prevLocationId() {
    if (this.isFirstLocation()) { return null; }
    return this.locationIds()[this.locationIndex() - 1];
  }

  isLastLocation() {
    const { locations, selectedLocation } = this.props;

    if (!(selectedLocation && selectedLocation.id)) { return true; }
    if (locations.size === 0 || !locations.last().id) { return true; }

    return locations.last().id.toString() === selectedLocation.id.toString();
  }

  isFirstLocation() {
    const { locations, selectedLocation } = this.props;

    if (!(selectedLocation && selectedLocation.id)) { return true; }
    if (locations.size === 0 || !locations.first().id) { return true; }

    return locations.first().id.toString() === selectedLocation.id.toString();
  }

  locationIndex() {
    const keys = this.locationIds();
    return (keys.indexOf(this.props.selectedLocation.id.toString()));
  }

  locationIds() {
    return (Array.from(this.state.bookableLocations.keys()));
  }

  renderPreviousButton() {
    if (!this.shouldRenderNavigation) { return null; }

    return (
      <a className={this.isFirstLocation() ? 'inactive' : null} onClick={this.showPrevLocation}>
        <span className="iconified-font iconified-space-right iconified-left-arrow" />
        PREV
      </a>
    );
  }

  renderNextButton() {
    if (!this.shouldRenderNavigation) { return null; }

    return (
      <a className={this.isLastLocation() ? 'inactive' : null} onClick={this.showNextLocation}>
        NEXT
        <span className="iconified-font iconified-space-left iconified-right-arrow" />
      </a>
    );
  }

  render() {
    return (
      <div className="location-nav row">
        <div className="col-xs-6 border-style-solid border-color-light-grey border-side-right text-center">
          <a href="#" onClick={this.exitLocation} >
            <span className="iconified-font iconified-space-right iconified-list" />
            VIEW ALL
          </a>
        </div>
        <div className="col-xs-3 padding-0 text-center">
          {this.renderPreviousButton()}
        </div>
        <div className="col-xs-3 padding-0 text-left">
          {this.renderNextButton()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    locations,
    currentSearch,
    selectedLocation,
  } = state.search;

  const { appContext } = state.app;

  return {
    locations,
    currentSearch,
    appContext,
    selectedLocation,
  };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    changeSelectedLocation,
    trackEvent: trackEventCreator,
  }, dispatch)
);

LocationNav.propTypes = propTypes;
LocationNav.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(LocationNav);
