import React from 'react';
import PropTypes from 'prop-types';
import { preciseHumanizeRange } from 'lib/date-lib';

const propTypes = {
  shuttleFrequencies: PropTypes.array,
};

const defaultProps = {
  shuttleFrequencies: [],
};

const ShuttleFrequencyLabel = ({ shuttleFrequencies }) => {
  if (shuttleFrequencies.length === 0) { return null; }

  return (
    <span className="text-weight-book text-size-sm-14 text-size-xs-10">
      Shuttle every <span className="text-weight-medium">{ preciseHumanizeRange(shuttleFrequencies[0], shuttleFrequencies[shuttleFrequencies.length - 1], true, 'hr', 'min') }</span>
    </span>
  );
};

ShuttleFrequencyLabel.propTypes = propTypes;
ShuttleFrequencyLabel.defaultProps = defaultProps;

export default ShuttleFrequencyLabel;
