import React from 'react';
import PropTypes from 'prop-types';

import { imageCdnAsset } from 'lib/common/url-helpers';

const propTypes = {
  visible: PropTypes.bool,
  className: PropTypes.string,
};

const defaultProps = {
  visible: true,
  className: '',
};

const LoadingPulse = ({ visible, className }) => {
  if (!visible) {
    return null;
  }

  return (
    <div className={`loading-pulse ${className}`}>
      <img src={imageCdnAsset('/images/search/white-parkwhiz-car.png')} className="loading-car" alt="" />
    </div>
  );
};

LoadingPulse.propTypes = propTypes;
LoadingPulse.defaultProps = defaultProps;

export default LoadingPulse;
