import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import result from 'lodash/result';
import cx from 'classnames';

import { imageCdnAsset } from 'lib/common/url-helpers';

import { Location } from 'models/locations';

const propTypes = {
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  location: PropTypes.instanceOf(Location).isRequired,
  selectedLocation: PropTypes.instanceOf(Location),

  highlightLocation: PropTypes.func.isRequired,
  changeSelectedLocation: PropTypes.func.isRequired,
  scrollToLocation: PropTypes.func.isRequired,
  toggleMap: PropTypes.func.isRequired,
};

const defaultProps = {
  selectedLocation: null,
};

class LocationMarker extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const { location, highlightedLocation, selectedLocation } = nextProps;
    const hover = LocationMarker.isHovered({ location, highlightedLocation });
    const selected = LocationMarker.isSelected({ location, selectedLocation });

    return {
      ...prevState,
      hover,
      selected,
    };
  }

  // Marker Stacking Rules:
  // Active/Hovered pin shows above everything else
  // Bookable locations render above non-bookable locations
  // Sold out locations render below all available locations
  // Otherwise, layer pins from the bottom of the map up
  // Use a modifier of 1000 to define the above bins, assumption
  // being that any given map will not have more than 1000 locations
  static zIndex({
    latRank,
    locationCount,
    location,
    highlightedLocation,
    selectedLocation,
  }) {
    const hover = LocationMarker.isHovered({ location, highlightedLocation });
    const selected = LocationMarker.isSelected({ location, selectedLocation });
    const { isAvailable, isBookable } = location;


    if (hover || selected) {
      return locationCount + 2001;
    }

    if (!isAvailable) {
      return locationCount - latRank;
    }

    if (!isBookable) {
      return 1000 + locationCount - latRank;
    }
    return 2000 + locationCount - latRank;
  }

  static isSelected({ location, selectedLocation }) {
    return get(location, 'id', -1) === get(selectedLocation, 'id', -2);
  }

  static isHovered({ location, highlightedLocation }) {
    return get(location, 'id', -1) === get(highlightedLocation, 'id', -2);
  }


  constructor(props) {
    super(props);

    this.state = {
      hover: false,
      selected: false,
    };

    this.onClick = this.onClick.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
  }

  onClick() {
    const { toggleMap, changeSelectedLocation, scrollToLocation, location } = this.props;
    const { isBookable, isAvailable } = location;
    if (!isAvailable) { return; }

    if (isBookable) {
      changeSelectedLocation({ locationId: location.id, displayMap: false });
    } else {
      toggleMap();
      scrollToLocation({ locationId: location.id });
    }
  }

  onMouseEnter() {
    this.setState({ hover: true });
    const { location } = this.props;
    this.props.highlightLocation({ locationId: location.id });
  }

  onMouseLeave() {
    this.setState({ hover: false });
    this.props.highlightLocation({ locationId: null });
  }

  get quote() {
    const { location } = this.props;
    return result(location, 'getQuote', {}) || {};
  }

  get iconURL() {
    const { hover, selected } = this.state;
    if (hover || selected) {
      return imageCdnAsset('/images/markers/bookable-active.png');
    }
    return imageCdnAsset('/images/markers/bookable-normal.png');
  }

  get displayPrice() {
    return Math.round(this.props.price);
  }

  get markerImageClass() {
    const { location } = this.props;
    const { isBookable, isAvailable, hasMultipleBookableQuotes } = location;
    const { hover, selected } = this.state;
    const active = hover || selected;
    const { quote } = this;
    const { isEventPackage } = quote;

    let markerImageClass = '';
    if (!isAvailable) {
      markerImageClass = 'soldout';
    } else if (!isBookable) {
      markerImageClass = 'nonbookable';
    } else if (isEventPackage && hasMultipleBookableQuotes) {
      markerImageClass = 'package';
    } else {
      markerImageClass = 'bookable';
    }

    if (active) {
      return `${markerImageClass}-active`;
    }
    return `${markerImageClass}-normal`;
  }

  render() {
    const { selectedLocation, location } = this.props;
    const { selected } = this.state;
    const { displayPrice, quote } = this;
    const digits = displayPrice.toString().length;
    const { isAvailable, hasMultipleBookableQuotes } = location;

    const { isEventPackage } = quote;

    let price;

    if (isAvailable && !(hasMultipleBookableQuotes && isEventPackage)) {
      price = <div><sup>$</sup>{displayPrice}</div>;
    }

    const markerClasses = cx({
      'location-marker': true,
      'clickable': true,
      'text-center': true,
      'text-weight-light': true,
      'text-size-16': digits === 1,
      'text-size-14': digits === 2,
      'text-size-12': digits === 3,
      'text-size-10': digits >= 4,
      'hidden': (!!selectedLocation && !selected),
    });


    return (
      <div
        src={this.iconURL}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onClick={this.onClick}
        className={`${markerClasses} ${this.markerImageClass}`}
      >
        {price}
      </div>
    );
  }
}

LocationMarker.propTypes = propTypes;
LocationMarker.defaultProps = defaultProps;
export default LocationMarker;
