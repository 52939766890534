import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ParkingTypeToggleComponent, { propTypes, defaultProps } from 'components/common/parking-type-toggle';

const ParkingTypeToggle = props => <ParkingTypeToggleComponent {...props} />;

const mapStateToProps = (state, ownProps) => {
  const { brand } = state.brand;

  return {
    routingStyle: brand.routingStyle,
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({}, dispatch)
);

ParkingTypeToggle.propTypes = propTypes;
ParkingTypeToggle.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(ParkingTypeToggle);
