import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cx from 'classnames';

import Search from 'models/search';

import changeParkingType from 'action-creators/search/change-parking-type';

const propTypes = {
  currentSearch: PropTypes.shape({
    isDailySearch: PropTypes.bool,
    isMonthlySearch: PropTypes.bool,
  }).isRequired,
  mobile: PropTypes.bool,
  monthlyAvailable: PropTypes.bool,
  changeParkingType: PropTypes.func.isRequired,
  routingStyle: PropTypes.string,
};

const defaultProps = {
  mobile: false,
  monthlyAvailable: false,
  routingStyle: 'parkwhiz',
};

class ParkingTypeButton extends Component {
  constructor(props) {
    super(props);

    this.click = this.click.bind(this);
  }

  click(e) {
    e.preventDefault();
    e.stopPropagation();
    const { currentSearch } = this.props;
    const parkingType = (currentSearch.isDailySearch ? Search.MONTHLY_PARKING_TYPE : Search.DAILY_PARKING_TYPE);
    this.props.changeParkingType({ parkingType });
  }

  render() {
    const { currentSearch, mobile, routingStyle } = this.props;
    const otherType = (currentSearch.isDailySearch ? Search.MONTHLY_PARKING_TYPE : Search.DAILY_PARKING_TYPE);
    const isMonthly = currentSearch.isMonthlySearch;
    const isDailyWithMonthlyAvailable = currentSearch.isDailySearch && this.props.monthlyAvailable;
    const searchForUrl = currentSearch.isDailySearch ? currentSearch.monthly : currentSearch.daily;
    const url = searchForUrl.route(null, null, null, { routingStyle });

    if ((isMonthly || isDailyWithMonthlyAvailable) && !currentSearch.selectedLocationId) {
      if (mobile) {
        const pillContainerClasses = cx({
          'btn-pill': true,
          'btn-pill-small': true,
          'clickable': true,
          'noselect': true,
          'text-weight-light': true,
          'text-size-12': true,
          'active': isMonthly,
        });
        const hourlyClasses = cx({
          'btn-pill-choice': true,
          'selected': isDailyWithMonthlyAvailable,
        });
        const monthlyClasses = cx({
          'btn-pill-choice': true,
          'selected': isMonthly,
        });

        return (
          <div className={pillContainerClasses}>
            <a
              className={hourlyClasses}
              onClick={this.click}
              href={currentSearch.route(null, { forceMonthly: true, routingStyle })}
            >
              Hourly
            </a>
            <a
              className={monthlyClasses}
              onClick={this.click}
              href={currentSearch.route(null, { forceMonthly: false, routingStyle })}
            >
              Monthly
            </a>
          </div>
        );
      }
      return (
        <a onClick={this.click} href={url}>View {otherType} Parking</a>
      );
    }

    return null;
  }
}

ParkingTypeButton.propTypes = propTypes;
ParkingTypeButton.defaultProps = defaultProps;

const mapStateToProps = (state, ownProps) => {
  const {
    currentSearch,
    monthlyAvailable,
  } = state.search;

  const { mobile } = ownProps;

  return {
    currentSearch,
    monthlyAvailable,
    mobile,
    routingStyle: state.brand.brand.routingStyle,
  };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({ changeParkingType }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(ParkingTypeButton);
