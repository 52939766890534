import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import ShuttleTime from 'models/shuttle-times';

const propTypes = {
  shuttleTime: PropTypes.instanceOf(ShuttleTime).isRequired,
  formatStyle: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
};

const ActiveTimeRange = ({ shuttleTime, formatStyle, intl }) => {
  let {
    activeStartTime: start,
    activeEndTime: end,
  } = shuttleTime;

  const format = formatStyle === 'day' ? 'dddd' : 'h:mma';

  start = start.locale(intl.locale).format(format);
  end = end.locale(intl.locale).format(format);

  if (start === end) { return start; }

  const messageId = formatStyle === 'day' ? 'common.shuttle-range-to' : 'common.shuttle-range-dash';
  const defaultMessage = formatStyle === 'day' ? '{start} to {end}' : '{start} - {end}';

  return (
    <FormattedMessage
      id={messageId}
      defaultMessage={defaultMessage}
      values={{ start, end }}
    />
  );
};

ActiveTimeRange.propTypes = propTypes;
export default injectIntl(ActiveTimeRange);
