import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';

const propTypes = {
  start: PropTypes.instanceOf(Object.getPrototypeOf(moment.duration())),
  end: PropTypes.instanceOf(Object.getPrototypeOf(moment.duration())),
  pluralize: PropTypes.bool,
};

const defaultProps = {
  start: null,
  end: null,
  pluralize: false,
};

import HumanizedDuration from 'components/common/shuttle-time-information/humanized-duration';

const HumanizedTimeRange = ({ start, end, pluralize }) => {
  // const { minFrequency: start, maxFrequency: end } = shuttleTime;

  if (!start) { return null; }

  const range = [<HumanizedDuration duration={start} key="start-duration" pluralize={pluralize} />];

  if (end && start.asMinutes() !== end.asMinutes()) {
    range.push(<HumanizedDuration duration={end} key="end-duration" pluralize={pluralize} />);
  }

  return range.reduce((a, v, i) => ([
    ...a,
    v,
    <FormattedMessage id="common.to-join" defaultMessage=" to " key={`to-label-${i}`} />,
  ]), []).slice(0, -1);
};

HumanizedTimeRange.propTypes = propTypes;
HumanizedTimeRange.defaultProps = defaultProps;
export default HumanizedTimeRange;
