import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { OrderedMap } from 'immutable';

import { SORT_BUTTON_IMPRESSION, SORT_BUTTON_CLICK } from 'lib/analytics/events/index';

const propTypes = {
  sort: PropTypes.string.isRequired,
  locations: PropTypes.instanceOf(OrderedMap),
  trackEvent: PropTypes.func.isRequired,
  onSortChange: PropTypes.func.isRequired,
};

const defaultProps = {
  locations: OrderedMap(),
  showCuration: () => {},
};

class LocationSorter extends Component {
  constructor(props) {
    super(props);

    this.onSort = this.onSort.bind(this);
    this.trackImpression = this.trackImpression.bind(this);
    this.trackClick = this.trackClick.bind(this);
  }

  componentDidMount() {
    const { locations } = this.props;
    if (locations.size) {
      this.trackImpression('0');
      this.trackImpression('1');
    }
  }

  trackImpression(position) {
    const { trackEvent } = this.props;
    trackEvent({
      ...SORT_BUTTON_IMPRESSION,
      position,
    });
  }

  trackClick(position) {
    const { trackEvent } = this.props;
    trackEvent({
      ...SORT_BUTTON_CLICK,
      position,
    });
  }

  onSort(sortName) {
    if (sortName !== '') {
      this.props.onSortChange(sortName);
      const position = sortName === 'cheapest' ? '1' : '0';
      this.trackClick(position);
    }
  }

  render() {
    const { sort } = this.props;
    const sortLinks = ['closest', 'cheapest', ''].map((sortName) => {
      const classes = cx({
        active: this.props.sort === sortName,
        disable: !this.props.locations.size,
        cheapest: sortName === 'cheapest',
        closest: sortName === 'closest',
        popular: sortName !== 'cheapest' && sortName !== 'closest',
      });

      if (this.props.locations.size) {
        return (
          <a
            className={classes}
            onClick={() => this.onSort(sortName)}
            key={`sort-${sortName}`}
          >
            <span>{sortName}</span>
          </a>
        );
      }
      return (
        <a
          className={classes}
          onClick={() => { event.preventDefault(); event.stopPropagation(); }}
          key={`sort-disabled-${sortName}`}
        >
          <span>{sortName}</span>
        </a>);
    });

    const sortClasses = cx({
      'sort-links': true,
      'padding-0': true,
      'uppercase': true,
      'col-sm-7': true,
      'col-xs-10': true,
      'left': sort === 'closest',
      'middle': sort === 'cheapest',
      'text-size-12': true,
      'text-weight-medium': true,
    });

    return (
      <div className="sort-nav">
        <div className="sort-label col-sm-4 col-xs-2 text-color-grey text-size-sm-12 text-size-xs-10 text-weight-medium">Sort by</div>
        <div className={sortClasses}>{sortLinks}</div>
      </div>
    );
  }
}

LocationSorter.propTypes = propTypes;
LocationSorter.defaultProps = defaultProps;

export default LocationSorter;
