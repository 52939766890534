import React from 'react';
import { imageCdnAsset } from 'lib/common/url-helpers';

const DestinationMarker = () => (
  <img
    src={imageCdnAsset('/images/search/destination-pin.svg')}
    className="destination-marker"
  />
);

export default DestinationMarker;
