import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';

const propTypes = {
  duration: PropTypes.instanceOf(Object.getPrototypeOf(moment.duration())),
  pluralize: PropTypes.bool,
};

const defaultProps = {
  duration: null,
  pluralize: false,
};

const HumanizedDuration = ({ duration, pluralize }) => {
  if (!duration) { return null; }

  const breakdown = [];

  const hours = duration.hours();
  if (hours > 0) {
    const messageId = !pluralize || hours === 1 ? 'common.hour-label' : 'common.hours-label';
    const defaultMessage = !pluralize || hours === 1 ? '{hours} hour' : '{hours} hours';
    breakdown.push(<FormattedMessage
      id={messageId}
      defaultMessage={defaultMessage}
      values={{ hours }}
    />);
  }

  const minutes = duration.minutes();
  if (minutes > 0) {
    const messageId = !pluralize || minutes === 1 ? 'common.minute-label' : 'common.minutes-label';
    const defaultMessage = !pluralize || minutes === 1 ? '{minutes} minute' : '{minutes} minutes';
    breakdown.push(<FormattedMessage
      id={messageId}
      defaultMessage={defaultMessage}
      values={{ minutes }}
    />);
  }

  return breakdown.reduce((a, v, i) => ([
    ...a,
    v,
    <FormattedMessage id="common.and-join" defaultMessage=" and " key={`and-label-${i}`} />,
  ]), []).slice(0, -1);
};

HumanizedDuration.propTypes = propTypes;
HumanizedDuration.defaultProps = defaultProps;
export default HumanizedDuration;
