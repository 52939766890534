import React from 'react';
import PropTypes from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor';

import { Location } from 'models/locations';
import { ImageSize } from 'models/image';

// Components
import ShuttleFrequencyLabel from 'components/search/list/shuttle-frequency-label';
import TierAmenities from 'components/search/list/tier-amenities';
import NonBookableNotice from 'components/search/list/non-bookable-notice';
import NonBookableDirections from 'components/search/list/non-bookable-directions';

const propTypes = {
  location: PropTypes.instanceOf(Location).isRequired,
  image: PropTypes.instanceOf(ImageSize).isRequired,
  cls: PropTypes.string.isRequired,
  shuttleClasses: PropTypes.string.isRequired,
  mouseenter: PropTypes.func.isRequired,
  mouseleave: PropTypes.func.isRequired,
  onVisibilityChange: PropTypes.func.isRequired,
  renderPrice: PropTypes.func.isRequired,
};

const NonBookableAirportListing = ({
  location,
  image,
  cls,
  shuttleClasses,
  mouseenter,
  mouseleave,
  onVisibilityChange,
  renderPrice,
}) => (
  <div
    id={`p${location.id}`}
    className={`${cls} non-bookable`}
    key={`pl${location.id}`}
    onMouseEnter={mouseenter}
    onMouseLeave={mouseleave}
  >
    <VisibilitySensor onChange={onVisibilityChange} partialVisibility>
      <div className="listing-container row">
        <div className="airport-listing-thumb col-xs-4 col-gutter-0 hidden-xs">
          <img src={image && image.url} alt="" />
        </div>
        <div className="col-sm-8 col-xs-12">
          <div className="row">
            <div className="listing-details col-xs-8 col-gutter-right-sm-0">
              <div
                className="text-size-md-18 text-size-xs-16 text-color-dark-slate text-weight-bold wrap-ellipses"
                dangerouslySetInnerHTML={{ __html: location.name }}
              />
              <div className="location-name margin-top-5 text-color-grey">{location.address}</div>
              <TierAmenities amenities={location.tierAmenities} />
            </div>
            {renderPrice()}
          </div>
          <div className="row padding-bottom-15">
            <NonBookableDirections location={location} />
            <div className="col-xs-12 col-gutter-0">
              <NonBookableNotice />
            </div>
          </div>
        </div>
      </div>
    </VisibilitySensor>
  </div>
);

NonBookableAirportListing.propTypes = propTypes;
export default NonBookableAirportListing;
