import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { canUseDOM } from 'exenv';
import { Map } from 'immutable';

let MapboxMap;
if (canUseDOM) {
  // eslint-disable-next-line global-require
  const mapboxgl = require('mapbox-gl');
  ({
    Map: MapboxMap,
  } = mapboxgl);
}


const propTypes = {
  segments: PropTypes.instanceOf(Map).isRequired,
  zoomLevel: PropTypes.number.isRequired,
  map: PropTypes.instanceOf(MapboxMap),
};

const defaultProps = {
  map: null,
};

class StreetParkingSegmentLayer extends Component {
  static getDerivedStateFromProps(nextProps) {
    return ({
      lineWidth: StreetParkingSegmentLayer.lineWidth(nextProps.zoomLevel),
      segmentIDs: nextProps.segments.map(s => s.id).toList(),
    });
  }

  static lineWidth(zoomLevel) {
    if (zoomLevel > 16) { return 4; }
    if (zoomLevel < 16) { return 1; }
    return 2;
  }

  constructor(props) {
    super(props);


    this.state = {
      lineWidth: StreetParkingSegmentLayer.lineWidth(props.zoomLevel),
      segmentIDs: props.segments.map(s => s.id).toList(),
    };
  }

  componentDidMount() {
    this.drawSegment();
  }

  componentWillUnmount() {
    this.removeSegment();
  }

  shouldComponentUpdate(_, nextState) {
    const { lineWidth, segmentIDs } = this.state;

    return lineWidth !== nextState.lineWidth || !segmentIDs.equals(nextState.segmentIDs);
  }

  componentDidUpdate() {
    this.removeSegment();
    this.drawSegment();
  }

  drawSegment() {
    const { map } = this.props;
    map.addLayer(this.segment);
  }

  removeSegment() {
    const { map } = this.props;
    map.removeLayer(this.segmentID);
    map.removeSource(this.segmentID);
  }

  get shouldRender() {
    const { map } = this.props;
    const { segment } = this;

    return Boolean(map && segment && !map.getLayer(this.segmentID));
  }

  get segmentID() {
    const { segments } = this.props;
    return `street-${segments.first().availability}`;
  }

  get color() {
    const { segments } = this.props;
    return segments.first().color;
  }

  get features() {
    const { segments } = this.props;

    return segments.map(s => ({
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: s.coordinates,
      },
    })).toArray();
  }

  get segment() {
    const { features, segmentID, color } = this;

    return ({
      id: segmentID,
      type: 'line',
      source: {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features,
        },
      },
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': color,
        'line-width': this.state.lineWidth,

      },
    });
  }

  render() {
    return <div id={this.segmentID} />;
  }
}

StreetParkingSegmentLayer.propTypes = propTypes;
StreetParkingSegmentLayer.defaultProps = defaultProps;
export default StreetParkingSegmentLayer;
