import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  amenity: PropTypes.string.isRequired,
  bsClassName: PropTypes.string,
};

const defaultProps = {
  bsClassName: null,
};

const TierAmenity = ({ amenity, bsClassName }) => (
  <div className={`${bsClassName || 'col-xs-6'} padding-bottom-10 col-gutter-right-0`}>
    <div className="padding-horizontal-10 padding-vertical-5 tier-amenity text-size-10 text-color-dark-slate text-weight-book text-center">
      {amenity}
    </div>
  </div>
);

TierAmenity.propTypes = propTypes;
TierAmenity.defaultProps = defaultProps;
export default TierAmenity;
