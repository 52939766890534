import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import cx from 'classnames';
import { OrderedMap } from 'immutable';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import ParkingTypeToggle from 'containers/common/parking-type-toggle';

import Search from 'models/search';
import Venue from 'models/venue';
import Event from 'models/event';
import { Location } from 'models/locations';

const propTypes = {
  currentSearch: PropTypes.instanceOf(Search).isRequired,
  venue: PropTypes.instanceOf(Venue),
  event: PropTypes.instanceOf(Event),
  events: PropTypes.instanceOf(OrderedMap),
  monthlyAvailable: PropTypes.bool,
  changeParkingType: PropTypes.func,
  airport: PropTypes.bool,
  selectedLocation: PropTypes.instanceOf(Location),
};

const defaultProps = {
  venue: null,
  event: null,
  events: OrderedMap(),
  airport: false,
  monthlyAvailable: false,
  changeParkingType: () => {},
};

class DestinationHeader extends Component {
  constructor(props) {
    super(props);

    this.linkToVenue = this.linkToVenue.bind(this);
    this.parkingTypeToggle = this.parkingTypeToggle.bind(this);
  }

  eventHeader() {
    const { currentSearch, venue } = this.props;
    const { destination, timezone } = currentSearch;

    const eventStart = moment(destination.startTime || destination.get('startTime')).tz(timezone);

    const destinationClasses = cx({
      'margin-left-xs-10': true,
      'margin-left-sm-0': true,
      'margin-right-sm-10': true,
      'margin-bottom-sm-5': true,
      'margin-top-sm-0': true,
      'line-height-extra': true,
      'wrap-ellipses': true,
      'text-color-dark-slate': true,
      'text-size-xs-14': true,
      'text-weight-bold': true,
      'capitalize': true,
    });

    return (
      <div className="search-header clickable text-color-dark-slate text-size-14 text-weight-bold" onClick={this.linkToVenue} >
        <div>
          <span className="label text-color-dark-slate">Event Parking For</span>
          <span className="details">{get(venue, 'name', '')}</span>
        </div>
        <div className="details text-color-dark-slate">{eventStart.format('MMMM D, h:mm A zz')}</div>
        <div className="destination-row">
          <h1 className={destinationClasses} dangerouslySetInnerHTML={{ __html: destination.get('name') }} />
        </div>
        <div>
          {this.renderEventsLink()}
        </div>
      </div>
    );
  }

  transientHeader() {
    return this.destinationHeader('Parking Near');
  }

  packageHeader() {
    return this.destinationHeader('Parking Packages For');
  }

  monthlyHeader() {
    return this.destinationHeader('Monthly Parking Near');
  }

  destinationHeader(message = 'Parking Near') {
    const { currentSearch, venue, events, airport, selectedLocation } = this.props;
    const { destination } = currentSearch;
    let destinationName = typeof (destination) !== 'string' ? get(destination, 'name', '') : destination;
    if (currentSearch.isParkingNearMeSearch && destinationName.length === 0) { destinationName = 'Current Location'; }
    if (selectedLocation) { destinationName = selectedLocation.get('address'); }

    const eventsPresent = (venue && events && events.size);
    const handleClick = eventsPresent ? this.linkToVenue : null;
    const pickerClasses = cx({
      'text-color-dark-slate': true,
      'text-weight-bold': !airport,
      'text-weight-book': airport,
      'text-size-14': true,
      'search-header': true,
      'clickable': eventsPresent,
    });

    const destinationClasses = cx({
      'margin-left-xs-10': true,
      'margin-left-sm-0': true,
      'margin-right-sm-10': true,
      'margin-bottom-sm-5': true,
      'margin-top-sm-0': !airport,
      'line-height-extra': true,
      'wrap-ellipses': true,
      'text-color-dark-slate': true,
      'text-size-xs-14': !airport,
      'text-size-md-18': !airport,
      'text-weight-light': !airport,
      'capitalize': !airport,
      'text-size-md-24': airport,
      'text-weight-bold': airport,
      'uppercase': airport,
      'margin-top-5': airport,
    });

    return (
      <div className={pickerClasses} onClick={handleClick}>
        <div className="row">
          <div className="col-xs-8">
            <span className="label text-color-dark-slate">{message}</span>
          </div>
          {this.renderParkingTypeToggle()}
        </div>
        <div className="destination-row">
          <h1 className={destinationClasses} dangerouslySetInnerHTML={{ __html: destinationName }} />
        </div>
        <div>
          {this.renderEventsLink()}
        </div>
      </div>
    );
  }

  linkToVenue(e) {
    e.stopPropagation();
    // e.preventDefault();

    // const { venue } = this.props;

    // this.props.changeApp({
    //   app: 'Venue',
    //   pathname: venue.url,
    //   attributes: {
    //     selectedLocationId: null,
    //     selectedQuoteId: null,
    //   },
    // });
  }

  renderEventsLink() {
    const { event, events, venue } = this.props;
    if (!venue || (!event && !(events && events.size))) { return null; }

    return (<Link
      to={{
        pathname: venue.url,
        state: {
          app: { name: 'Venue' },
          search: {
            venue: venue.toJSON(),
          },
        },
      }}
    >View Events</Link>);
  }

  parkingTypeToggle(e) {
    e.stopPropagation();
    e.preventDefault();

    const { currentSearch } = this.props;
    const parkingType = currentSearch.isMonthlySearch ? Search.DAILY_PARKING_TYPE : Search.MONTHLY_PARKING_TYPE;
    this.props.changeParkingType({ parkingType });
  }

  renderParkingTypeToggle() {
    const { currentSearch, monthlyAvailable, airport } = this.props;
    if (airport && !currentSearch.isMonthlySearch) { return null; }
    if (!monthlyAvailable) { return null; }

    const toggleClasses = cx({
      'col-xs-4 padding-left-0': true,
      'text-align-right': currentSearch.isMonthlySearch,
    });
    return (
      <div className={toggleClasses}>
        <ParkingTypeToggle
          currentSearch={currentSearch}
          isMonthly={currentSearch.isMonthlySearch}
          onClick={this.parkingTypeToggle}
          className="margin-top-20"
        />
      </div>
    );
  }

  render() {
    const { currentSearch, airport } = this.props;
    const { parkingType } = currentSearch || {};

    let header = '';
    switch (parkingType) {
      case Search.MONTHLY_PARKING_TYPE:
        header = this.monthlyHeader();
        break;
      case Search.EVENT_PARKING_TYPE:
        header = this.eventHeader();
        break;
      case Search.PACKAGE_PARKING_TYPE:
        header = this.packageHeader();
        break;
      default:
        header = this.transientHeader();
        break;
    }

    const destinationClasses = cx({
      'background-white': true,
      'padding-top-10': !airport,
      'padding-bottom-5': !airport,
      'padding-horizontal-15': !airport,
      'padding-30': airport,
      'padding-bottom-20': airport && parkingType !== Search.MONTHLY_PARKING_TYPE,
      'padding-left-15': airport,
      'padding-top-0 padding-bottom-0': parkingType === Search.MONTHLY_PARKING_TYPE,
    });
    
    return (
      <div id="destination-header" className="hidden-lte-sm sub-nav-border">
        <div className={destinationClasses}>
          {header}
        </div>
      </div>
    );
  }
}

DestinationHeader.propTypes = propTypes;
DestinationHeader.defaultProps = defaultProps;

export default DestinationHeader;
