import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

import Quote from 'models/quote';

const propTypes = {
  selectedQuote: PropTypes.instanceOf(Quote).isRequired,
};

const defaultProps = {
};

const MonthlyFeeDetails = ({ selectedQuote }) => {
  if (!selectedQuote.isMonthly) return null;

  return (
    <div className="row">
      <div className="col-sm-8">
        <div className="padding-top-40 text-size-sm-24 text-size-xs-18 text-color-dark-slate text-weight-bold">
          Monthly Fee Details
        </div>
        <div className="margin-top-20 text-size-18 text-color-dark-slate text-weight-book">
          <ReactMarkdown source={selectedQuote.description} />
        </div>
      </div>
    </div>
  );
};

MonthlyFeeDetails.propTypes = propTypes;
MonthlyFeeDetails.defaultProps = defaultProps;

export default MonthlyFeeDetails;
