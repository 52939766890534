import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { OrderedMap, Map } from 'immutable';
import sanitizeHTML from 'sanitize-html';
import VisibilitySensor from 'react-visibility-sensor';
import result from 'lodash/result';
import get from 'lodash/get';
import cx from 'classnames';

import { Location as LocationModel } from 'models/locations';
import Quote from 'models/quote';
import Venue from 'models/venue';
import Brand from 'models/brand';
import Search from 'models/search';

import LocationNav from 'containers/search/list/location-nav';
import TierAmenities from 'components/search/list/tier-amenities';
import TierSelection from 'components/search/airport-location/tier-selection';
import ShuttleFrequencyLabel from 'components/search/list/shuttle-frequency-label';
import LocationImageGallery from 'components/location/image/gallery';
import AmenityList from 'components/common/amenity-list';
import MiniMap from 'components/common/mini-map';
import ShuttleTimeInformation from 'components/common/shuttle-time-information';
import CurrencySymbol from 'components/common/currency-symbol';
import MonthlyFeeDetails from 'components/search/airport-location/monthly-fee-detail';
import HowToPark from 'components/search/airport-location/how-to-park';
import AirportLocationInstructions from 'components/search/airport-location/instructions';

import RatingsCar from 'components/svgs/icons/ratings-car';

import {
  LOCATION_GALLERY_CONTENT_IMPRESSION,
  LOCATION_AMENITIES_CONTENT_IMPRESSION,
  LOCATION_MAP_CONTENT_IMPRESSION,
  LOCATION_NEED_TO_KNOW_CONTENT_IMPRESSION,
  LOCATION_HOW_TO_PARK_CONTENT_IMPRESSION,
  LOCATION_SHUTTLE_CONTENT_IMPRESSION,
  LOCATION_GALLERY_CONTENT_CLICK,
  LOCATION_SHUTTLE_CONTENT_CLICK,
  LOCATION_AMENITIES_CONTENT_CLICK,
  LOCATION_MAP_CONTENT_CLICK,
  LOCATION_NEED_TO_KNOW_CONTENT_CLICK,
  LOCATION_HOW_TO_PARK_CONTENT_CLICK,
  BOOK_NOW_IMPRESSION,
  BOOK_NOW_CLICK,
  LOCATION_DETAILS_CONTENT_IMPRESSION,
  LOCATION_DETAILS_CONTENT_CLICK,
  SEARCH_MAP_TOGGLE_IMPRESSION,
  SEARCH_MAP_TOGGLE_CLICK,
  LOCATION_DETAILS_ALL_LOCATIONS_IMPRESSION,
  LOCATION_DETAILS_ALL_LOCATIONS_CLICK,
  PAGEVIEW_EVENT,
} from 'lib/analytics/events/index';
import { urlProps } from 'lib/analytics/page-properties';
import { MOBILE } from 'lib/app-context';

const propTypes = {
  brand: PropTypes.instanceOf(Brand).isRequired,
  currentSearch: PropTypes.instanceOf(Search),
  selectedLocation: PropTypes.instanceOf(LocationModel).isRequired,
  selectedQuote: PropTypes.instanceOf(Quote),
  venue: PropTypes.instanceOf(Venue),
  locations: PropTypes.instanceOf(OrderedMap).isRequired,
  displayMap: PropTypes.bool.isRequired,
  appContext: PropTypes.string.isRequired,
  changeSort: PropTypes.func,
  toggleMap: PropTypes.func.isRequired,
  selectQuote: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired,
  changeSelectedLocation: PropTypes.func.isRequired,
  sellers: PropTypes.instanceOf(Map),
  getLocationSeller: PropTypes.func.isRequired,
};

const defaultProps = {
  currentSearch: {},
  locations: null,
  selectedQuote: null,
  venue: null,
  changeSort: () => {},
  sellers: new Map(),
};

class AirportLocation extends React.Component {
  constructor(props) {
    super(props);

    this.backToSearch = this.backToSearch.bind(this);
    this.onBookNow = this.onBookNow.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
    this.toggleMap = this.toggleMap.bind(this);
    this.changeSelectedLocation = this.props.changeSelectedLocation.bind(this);
    this.trackEvent = this.trackEvent.bind(this);
    this.onDetailsVisibilityChange = this.onDetailsVisibilityChange.bind(this);
    this.onGalleryVisibilityChange = this.onGalleryVisibilityChange.bind(this);
    this.onShuttleVisibilityChange = this.onShuttleVisibilityChange.bind(this);
    this.onAmenitiesVisibilityChange = this.onAmenitiesVisibilityChange.bind(this);
    this.onMapVisibilityChange = this.onMapVisibilityChange.bind(this);
    this.onInstructionsVisibilityChange = this.onInstructionsVisibilityChange.bind(this);
    this.onValidationVisibilityChange = this.onValidationVisibilityChange.bind(this);
    this.onBookNowVisibilityChange = this.onBookNowVisibilityChange.bind(this);
    this.onMapViewVisibilityChange = this.onMapViewVisibilityChange.bind(this);
    this.onSeeAllVisibilityChange = this.onSeeAllVisibilityChange.bind(this);

    this.onDetailsClick = this.onDetailsClick.bind(this);
    this.onGalleryClick = this.onGalleryClick.bind(this);
    this.onShuttleClick = this.onShuttleClick.bind(this);
    this.onAmenitiesClick = this.onAmenitiesClick.bind(this);
    this.onMapClick = this.onMapClick.bind(this);
    this.onInstructionsClick = this.onInstructionsClick.bind(this);
    this.onValidationClick = this.onValidationClick.bind(this);

    this.renderRating = this.renderRating.bind(this);
  }


  get shouldRenderTransientText() {
    return !get(this.props.selectedQuote, 'isMonthly');
  }

  /**
   * Tracks a page view for a select location detail's view
   * Only fires when a user has a valid selected location
   * @return {Null} null
   */
  trackLocationDetailsPageView() {
    const { trackEvent, currentSearch, displayMap } = this.props;
    if (!currentSearch.selectedLocationId) { return; }
    trackEvent({
      ...PAGEVIEW_EVENT,
      properties: {
        ...urlProps(),
        LocationID: currentSearch.selectedLocationId,
        VenueID: currentSearch.isVenueSearch ? currentSearch.getIn(['destination', 'id']) : null,
        MapListView: displayMap ? 'MapView' : 'ListView',
      },
    });
  }

  componentDidMount() {
    this.fetchLocationSeller(this.props.selectedLocation);
    this.trackLocationDetailsPageView();
  }

  fetchLocationSeller(location) {
    if (location && location.sellerId) {
      this.props.getLocationSeller({ locationId: location.id });
    }
  }

  componentWillUpdate(nextProps) {
    const { currentSearch } = nextProps;
    if (currentSearch.selectedLocationId !== this.props.currentSearch.selectedLocationId) {
      this.trackLocationDetailsPageView();
    }
  }

  backToSearch() {
    this.trackEvent(LOCATION_DETAILS_ALL_LOCATIONS_CLICK);
  }

  renderDailyPrice(price) {
    if (price && price[0] > 0) {
      const { selectedLocation } = this.props;

      return (
        <div>
          <div className="hidden-xs text-color-dark-slate text-size-18 text-weight-book hidden-xs">
            Starting at: <CurrencySymbol location={selectedLocation} />{ price[0] } per day
          </div>
          <div className="visible-xs text-align-right line-height-full">
            <div className="text-size-14 text-weight-book text-color-grey no-wrap">
              Starting at
            </div>
            <div className="margin-top-5 text-weight-black text-color-dark-slate text-size-28">
              <sup className="text-size-14 dollar-sign">
                <CurrencySymbol location={selectedLocation} />
              </sup>
              { price[0] }
            </div>
            <div className="margin-top-5 text-size-14 text-weight-book text-color-dark-slate no-wrap">
              per day
            </div>
          </div>
        </div>
      );
    }

    return null;
  }

  renderTotalPrice(price) {
    if (!price) { return null; }

    const { selectedLocation } = this.props;

    return (
      <div className="text-color-dark-slate text-size-sm-42 text-size-xs-36 text-weight-black line-height-full">
        <sup className="text-size-sm-16 text-size-xs-14 dollar-sign">
          <CurrencySymbol location={selectedLocation} />
        </sup>
        { price[0] }
        { price.length === 2 ? <sup className="text-size-sm-16 text-size-xs-14 dollar-sign">{price[1]}</sup> : null }
      </div>
    );
  }

  renderBookNowButton(soldOut = false) {
    if (!soldOut) {
      const { currentSearch, selectedLocation, selectedQuote, displayMap, venue, brand } = this.props;
      const quoteId = get(selectedQuote, 'id', null);

      return (
        <div className="margin-vertical-10">
          <VisibilitySensor onChange={this.onBookNowVisibilityChange} partialVisibility>
            <Link
              to={{
                pathname: '/reserve/',
                search: `?quote_id=${quoteId}`,
                state: {
                  app: { name: 'Checkout' },
                  search: {
                    currentSearch: result(currentSearch, 'toJSON', {}),
                    locationId: selectedLocation.id,
                    quoteId,
                    displayMap,
                    venue: result(venue, 'toJSON', {}),
                  },
                },
              }}
              onClick={this.onBookNow}
              id="airport-location"
              className={`pw-btn ${brand.isBestParking ? 'pw-btn-bp-blue' : 'pw-btn-green'} pw-btn-green text-weight-book text-size-xs-16 padding-horizontal-sm-20 padding-horizontal-xs-15 padding-vertical-10 btn-block`}
            >
              BOOK NOW
            </Link>
          </VisibilitySensor>
        </div>
      );
    } else if (soldOut) {
      return (
        <div className="margin-vertical-10">
          <span className="btn btn-primary btn-disabled sold-out btn-block text-color-medium-grey">
            <span className="iconified-font iconified-sad-car padding-right-10 padding-vertical-10 display-inline-block" />
            Sorry Sold Out
          </span>
        </div>
      );
    }
    return null;
  }

  onBookNow() {
    this.trackEvent(BOOK_NOW_CLICK);
  }

  onSortChange(sort) {
    this.props.changeSort({ sort });
  }

  trackEvent(event) {
    const { trackEvent, selectedLocation } = this.props;
    trackEvent({
      ...event,
      properties: {
        locationID: selectedLocation.id,
      },
    });
  }

  onDetailsVisibilityChange(isVisible) {
    if (isVisible) {
      this.trackEvent(LOCATION_DETAILS_CONTENT_IMPRESSION);
    }
  }

  onGalleryVisibilityChange(isVisible) {
    if (isVisible) {
      this.trackEvent(LOCATION_GALLERY_CONTENT_IMPRESSION);
    }
  }

  onShuttleVisibilityChange(isVisible) {
    if (isVisible) {
      this.trackEvent(LOCATION_SHUTTLE_CONTENT_IMPRESSION);
    }
  }

  onAmenitiesVisibilityChange(isVisible) {
    if (isVisible) {
      this.trackEvent(LOCATION_AMENITIES_CONTENT_IMPRESSION);
    }
  }

  onMapVisibilityChange(isVisible) {
    if (isVisible) {
      this.trackEvent(LOCATION_MAP_CONTENT_IMPRESSION);
    }
  }

  onInstructionsVisibilityChange(isVisible) {
    if (isVisible) {
      this.trackEvent(LOCATION_NEED_TO_KNOW_CONTENT_IMPRESSION);
    }
  }

  onValidationVisibilityChange(isVisible) {
    if (isVisible) {
      this.trackEvent(LOCATION_HOW_TO_PARK_CONTENT_IMPRESSION);
    }
  }

  onBookNowVisibilityChange(isVisible) {
    if (isVisible) {
      this.trackEvent(BOOK_NOW_IMPRESSION);
    }
  }

  onMapViewVisibilityChange(isVisible) {
    if (isVisible && this.props.appContext === MOBILE) {
      this.trackEvent(SEARCH_MAP_TOGGLE_IMPRESSION);
    }
  }

  onSeeAllVisibilityChange(isVisible) {
    if (isVisible) {
      this.trackEvent(LOCATION_DETAILS_ALL_LOCATIONS_IMPRESSION);
    }
  }

  onDetailsClick() {
    this.trackEvent(LOCATION_DETAILS_CONTENT_CLICK);
  }

  onGalleryClick() {
    this.trackEvent(LOCATION_GALLERY_CONTENT_CLICK);
  }

  onShuttleClick() {
    this.trackEvent(LOCATION_SHUTTLE_CONTENT_CLICK);
  }

  onAmenitiesClick() {
    this.trackEvent(LOCATION_AMENITIES_CONTENT_CLICK);
  }

  onMapClick() {
    this.trackEvent(LOCATION_MAP_CONTENT_CLICK);
  }

  onInstructionsClick() {
    this.trackEvent(LOCATION_NEED_TO_KNOW_CONTENT_CLICK);
  }

  onValidationClick() {
    this.trackEvent(LOCATION_HOW_TO_PARK_CONTENT_CLICK);
  }

  toggleMap(e) {
    e.preventDefault();
    e.stopPropagation();

    window.scrollTo(0, 0);
    this.props.toggleMap();
    this.trackEvent(SEARCH_MAP_TOGGLE_CLICK);
  }

  renderImageGallery() {
    const {
      appContext,
      selectedLocation,
      trackEvent,
      currentSearch,
    } = this.props;

    const sellerId = selectedLocation.sellerId ? selectedLocation.sellerId.toString() : '';
    const seller = this.props.sellers.get(sellerId);

    if (appContext === MOBILE) {
      return (
        <LocationImageGallery
          location={selectedLocation}
          maxHeight={200}
          width={320}
          seller={seller}
          currentSearch={currentSearch}
          trackEvent={trackEvent}
        />
      );
    }

    return (
      <div className="margin-top-20">
        <LocationImageGallery
          location={selectedLocation}
          maxHeight={455}
          width={742}
          trackEvent={trackEvent}
          app="Location Details"
          currentSearch={currentSearch}
          seller={seller}
        />
      </div>
    );
  }

  renderRating(selectedLocation) {
    let rating = null;
    if (selectedLocation.hasRatings()) {
      rating = (
        <div className="margin-top-20 text-size-18">
          <span className="isvg loaded">
            <RatingsCar />
          </span>
          <span className="text-weight-black margin-right-5">{selectedLocation.ratingSummary.averageRating.toFixed(1)}</span>
          out of 5
        </div>
      );
    }
    return (
      <div className="row">
        <div className="col-sm-8">
          <div className="padding-top-40 text-size-sm-24 text-size-xs-18 text-color-dark-slate text-weight-bold">
            About This Facility
          </div>
          <div className="text-color-dark-slate text-weight-book">
            {rating}
            <div className="margin-top-10 text-size-sm-16 text-size-xs-12">
              {selectedLocation.description}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderShuttleInformation() {
    const { selectedQuote } = this.props;

    if (!(get(selectedQuote, 'shuttleTimesToVenue.length', 0) > 0 || get(selectedQuote, 'shuttleTimesFromVenue.length', 0) > 0)) return null;

    return (
      <VisibilitySensor onChange={this.onShuttleVisibilityChange} partialVisibility>
        <div onClick={this.onShuttleClick}>
          <ShuttleTimeInformation
            headerClassName="text-color-dark-slate text-size-xs-16 text-size-md-24 text-weight-bold"
            rowClassName="row margin-top-10 text-size-sm-16 text-size-xs-14 text-weight-book text-color-dark-slate"
            shuttleTimesFromVenue={selectedQuote.shuttleTimesFromVenue}
            shuttleTimesToVenue={selectedQuote.shuttleTimesToVenue}
            subheaderClassName="text-color-dark-slate text-size-xs-16 text-size-md-20 text-weight-bold margin-top-10"
            wrapperClassName="col-sm-8 margin-top-40 margin-bottom-10"
          />
          <div className="row">
            <div className="col-sm-8">
              <hr className="margin-top-sm-40 margin-top-xs-20" />
            </div>
          </div>
        </div>
      </VisibilitySensor>
    );
  }

  renderTiers(mobile) {
    const {
      currentSearch,
      selectQuote,
      selectedLocation,
      selectedQuote,
      trackEvent,
    } = this.props;

    if (get(selectedLocation, 'quotes.size', 0) <= 1) return null;

    const className = cx('border-style-solid border-color-light-grey', {
      'background-color-white': !mobile,
      'border-side-all': !mobile,
      'border-side-top': mobile,
      'container': mobile,
      'col-sm-9': !mobile,
      'hidden-xs': !mobile,
      'margin-bottom-40': !mobile,
      'padding-30': mobile,
      'padding-horizontal-sm-40': !mobile,
      'padding-vertical-sm-30': !mobile,
      'text-color-dark-slate': !mobile,
    });

    const tierSelection = (
      <div className={className}>
        <TierSelection
          app="Location Details"
          currentSearch={currentSearch}
          location={selectedLocation}
          selectQuote={selectQuote}
          selectedQuote={selectedQuote}
          trackEvent={trackEvent}
        />
      </div>
    );

    if (mobile) {
      return (<div className="visible-xs row">{tierSelection}</div>);
    }

    return tierSelection;
  }

  render() {
    const {
      selectedLocation,
      currentSearch,
      venue,
      selectedQuote,
      brand,
    } = this.props;

    const formattedPrice = selectedQuote && selectedQuote.price ? selectedQuote.price.toString().split('.') : null;
    const formattedDailyPrice = selectedQuote && selectedQuote.dailyPrice ? selectedQuote.dailyPrice.toString().split('.') : null;
    const isSoldOut = (selectedQuote == null);
    const { validAmenities } = selectedQuote || {};

    return (
      <div>
        <div className="visible-xs">
          <LocationNav />
        </div>
        <div className="row">
          <div className="container">
            <div className="col-xs-4 col-xs-push-8 position-relative">
              <div className="margin-top-sm-40 margin-top-xs-20 margin-bottom-10 text-align-center background-white position-fixed">
                <div className="border-style-sm-solid border-side-sm-all border-color-light-grey">
                  <div className="padding-sm-30 padding-xs-5">
                    <div className="hidden-xs text-size-sm-24 text-size-xs-18 text-color-dark-slate text-weight-bold">
                      { selectedLocation.name }
                    </div>

                    {this.renderDailyPrice(formattedDailyPrice)}

                    <VisibilitySensor onChange={this.onMapViewVisibilityChange} partialVisibility>
                      <a className="visible-xs float-right margin-top-10" onClick={this.toggleMap}>
                        <span className="text-size-10 text-weight-light btn-style-minimal-outline btn-color-link-blue">
                          VIEW IN MAP
                        </span>
                      </a>
                    </VisibilitySensor>

                    <div className="hidden-xs padding-top-10">
                      {this.renderTotalPrice(formattedPrice)}
                    </div>

                    <div className="hidden-xs padding-top-5 text-size-sm-20 text-weight-book">
                      TOTAL
                    </div>

                    {this.shouldRenderTransientText &&
                      <div className="hidden-xs padding-top-10 text-size-sm-12 text-weight-book">
                        *All taxes & fees included
                      </div>
                    }

                    <hr className="hidden-xs" />

                    <div className="hidden-xs text-size-14 text-weight-book margin-top-10">
                      Continue to confirm your booking.
                    </div>

                    <div className="hidden-xs">
                      {this.renderBookNowButton(isSoldOut)}
                    </div>

                    {this.shouldRenderTransientText &&
                      <div className="hidden-xs text-size-14 text-weight-book margin-top-10">
                        Easy Cancellation
                      </div>
                    }

                  </div>
                </div>
              </div>

            </div>
            <VisibilitySensor onChange={this.onDetailsVisibilityChange} partialVisibility>
              <div className="col-xs-8 col-xs-pull-4 text-weight-book" onClick={this.onDetailsClick}>
                <div className="hidden-xs margin-top-40">
                  <VisibilitySensor onChange={this.onSeeAllVisibilityChange} partialVisibility>
                    <Link
                      className="text-size-16 text-color-blue text-weight-medium"
                      onClick={this.backToSearch}
                      onTouchEnd={this.backToSearch}
                      to={{
                        pathname: currentSearch.merge({
                          selectedLocationId: null,
                          selectedQuoteId: null,
                        }).route(null, false, null, { routingStyle: brand.routingStyle }),
                        state: {
                          app: { name: 'Search' },
                          search: {
                            currentSearch: result(currentSearch.merge({
                              selectedLocationId: null,
                              selectedQuoteId: null,
                            }), 'toJSON', {}),
                            selectedLocation: null,
                            selectedQuote: null,
                            venue: JSON.stringify(result(venue, 'toJSON', {})),
                            locationId: null,
                            quoteId: null,
                            displaymap: false,
                          },
                        },
                      }}
                    >
                      <span className="iconified-font margin-right-10 iconified-left-arrow" />
                      SEE ALL <span className="capitalize" dangerouslySetInnerHTML={{ __html: sanitizeHTML(get(venue, 'name', '')).toUpperCase() }} /> PARKING
                    </Link>
                  </VisibilitySensor>
                </div>

                <h1
                  className="margin-top-sm-30 margin-top-xs-20 margin-bottom-0 text-size-sm-42 text-size-xs-18 text-color-dark-slate text-weight-bold line-height-extra"
                  dangerouslySetInnerHTML={{ __html: sanitizeHTML(selectedLocation.name) }}
                />

                <div className="text-size-sm-20 text-color-grey">
                  {selectedLocation.fullAddress}
                </div>
                <div className="row margin-vertical-5">
                  <div className="col-sm-5 col-xs-12 text-size-10">
                    <TierAmenities amenities={selectedLocation.tierAmenities} />
                  </div>
                </div>
                <div className="margin-vertical-10 text-size-14">
                  <ShuttleFrequencyLabel shuttleFrequencies={selectedLocation.shuttleFrequencyBounds} />
                </div>
                { this.renderTiers(false) }
              </div>
            </VisibilitySensor>
          </div>
        </div>
        { this.renderTiers(true) }
        <div className="visible-xs row">
          <div className="container border-style-solid border-color-light-grey border-side-top">
            <div className="col-xs-4 col-xs-push-8 text-align-right padding-vertical-20">
              {this.renderTotalPrice(formattedPrice)}
            </div>
            <div className="col-xs-8 col-xs-pull-4 padding-vertical-20">
              <div className="text-size-18 text-color-dark-slate text-weight-book">
                Parking total:
              </div>
              {this.shouldRenderTransientText &&
                <div className="text-size-12 text-color-dark-slate text-weight-book margin-top-10">
                  *All taxes & fees included
                </div>
              }
            </div>
          </div>
        </div>
        <div className="visible-xs row">
          <div className="container  border-style-solid border-color-light-grey border-side-top padding-horizontal-40 padding-vertical-10 text-align-center">
            {this.renderBookNowButton()}
          </div>
        </div>

        <div className="background-color-light-blue padding-bottom-60">
          <div className="container">
            {
              selectedQuote &&
              <MonthlyFeeDetails selectedQuote={selectedQuote} />
            }
            <div className="row">
              <div className="col-sm-8 col-gutter-sm-15 col-gutter-xs-0">
                <div className="hidden-xs padding-top-40 text-size-sm-24 text-size-xs-18 text-color-dark-slate text-weight-bold">
                  Location Overview
                </div>
                <VisibilitySensor onChange={this.onGalleryVisibilityChange} partialVisibility>
                  <div onClick={this.onGalleryClick}>
                    { this.renderImageGallery() }
                  </div>
                </VisibilitySensor>
              </div>
            </div>
            { this.renderShuttleInformation() }
            <div className="row">
              <div className="col-sm-8">
                <div className="padding-top-40 text-size-sm-24 text-size-xs-18 text-color-dark-slate text-weight-bold">
                  Amenities
                </div>
                <div className="margin-top-20" onClick={this.onAmenitiesClick}>
                  <VisibilitySensor onChange={this.onAmenitiesVisibilityChange} partialVisibility>
                    <AmenityList
                      amenities={validAmenities}
                      amenityClassName="col-xs-3 text-size-sm-14 text-size-xs-12 text-weight-book padding-bottom-15"
                    />
                  </VisibilitySensor>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-8 col-gutter-sm-15 col-gutter-xs-0" onClick={this.onMapClick}>
                <VisibilitySensor onChange={this.onMapVisibilityChange} partialVisibility>
                  <MiniMap
                    mapSize="740x460"
                    location={selectedLocation.entrances.first()}
                    destination={{ lat: currentSearch.anchorLat, lng: currentSearch.anchorLng }}
                    className="padding-top-40"
                  />
                </VisibilitySensor>
              </div>
            </div>

            <VisibilitySensor onChange={this.onInstructionsVisibilityChange} partialVisibility>
              <div onClick={this.onInstructionsClick}>
                {
                  selectedQuote &&
                  <AirportLocationInstructions
                    quote={selectedQuote}
                  />
                }
                { this.renderRating(selectedLocation) }
              </div>
            </VisibilitySensor>

            {
              selectedQuote &&
              <VisibilitySensor onChange={this.onValidationVisibilityChange} partialVisibility>
                <HowToPark selectedQuote={selectedQuote} onValidationClick={this.onValidationClick} />
              </VisibilitySensor>
            }
          </div>
        </div>
      </div>
    );
  }
}

AirportLocation.propTypes = propTypes;
AirportLocation.defaultProps = defaultProps;

export default AirportLocation;
