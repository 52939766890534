import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { canUseDOM } from 'exenv';
import result from 'lodash/result';

let MapboxMap;
let MapboxMarker;
if (canUseDOM) {
  // eslint-disable-next-line global-require
  const mapboxgl = require('mapbox-gl');
  ({
    Map: MapboxMap,
    Marker: MapboxMarker,
  } = mapboxgl);
}

const propTypes = {
  position: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  map: PropTypes.instanceOf(MapboxMap),
  zIndex: PropTypes.number.isRequired,
};

const defaultProps = {
  map: null,
};

class MarkerWrapper extends Component {
  componentDidMount() {
    this.updateMarker();
  }

  componentDidUpdate() {
    this.updateMarker();
  }

  componentWillUnmount() {
    this.removeMarker();
  }

  get coordinates() {
    const { position } = this.props;
    const { lng, lat } = position;

    if (!lng || !lat) {
      return null;
    }

    return [lng, lat];
  }

  get shouldRender() {
    const { map } = this.props;
    const { coordinates } = this;

    return Boolean(map && coordinates);
  }

  updateMarker() {
    const { map } = this.props;
    const { coordinates, shouldRender } = this;

    if (!shouldRender) {
      this.removeMarker();
      this.marker = null;
      return;
    }

    if (!this.marker) {
      this.marker = new MapboxMarker(this.markerDiv, {
        anchor: 'bottom',
      });
    }
    this.marker.setLngLat(coordinates);
    this.marker.addTo(map);
  }

  removeMarker() {
    result(this, 'marker.remove');
  }


  render() {
    const { children, zIndex } = this.props;
    const renderedChildren = this.shouldRender ? children : null;

    return (<div>
      <div
        ref={(markerDiv) => { this.markerDiv = markerDiv; }}
        style={{ zIndex }}
      >
        {renderedChildren}
      </div>
    </div>);
  }
}

MarkerWrapper.propTypes = propTypes;
MarkerWrapper.defaultProps = defaultProps;
export default MarkerWrapper;
